import styled from 'styled-components/macro';
import * as uik from '../../../utils/third-party/@uik';

const { UikContainerVertical } = uik;

const TrafficReportsContainer = styled(UikContainerVertical)`
  position: relative;  
  
  .grid {
    gap: 30px;
  }

  .wrapper.content {
    margin: 15px;
  }

  tr td:first-child {
    /* max-width: 200px; */
    word-break: break-all;
  }

  .line-td {
    /* border-right: 1px solid #EAEDF3; */
    padding-right: 0px !important; 
    padding-left: 0px !important;
    width: 130px;
  
    &.text {
      padding-right: 20px !important; 
      text-align: right;
    }
  }

  


  .uik-tab__container {
    padding: 0px;

    .uik-tab__item {
      margin: 0px;
      margin-right: 30px;
      height: auto;
      padding-bottom: 5px;
    }
  }

  
  .single-select-tab {
    border-bottom: 2px solid transparent;
    p {
      margin-top: 0px;
    }
    h1 {
      font-weight: 400;
    }

    p,h1 {
      color: #9EA0A5;
    }

    &:hover {
      border-bottom: 2px solid rgba(36,132,137,0.3);
    }

    &.active {
      border-bottom: 2px solid #248489;

      h1 {
        color: black;
      }
      p {
        color: #248489;
        font-weight: bold;
      }
    }
  }

  /* #traffic-page-views th:nth-last-child(2) {
    text-align: right !important;
  } */


  @media screen and (max-width: 1150px) {
    .single-select-tab {
      p {
        font-size: 0.8rem;
      }
      h1 {
        font-size: 1.85rem;
      }
    }
  }

  @media screen and (max-width: 1050px) {
    .single-select-tab {
      p {
        font-size: 0.7rem;
      }
      h1 {
        font-size: 1.75rem;
      }
    }
  }

`

export default TrafficReportsContainer;