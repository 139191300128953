import styled from "styled-components";

import * as uik from '../../utils/third-party/@uik';

const {
  UikTopBar
} = uik;

const TopBarContainer = styled(UikTopBar)`
  position: relative;

  background-color: transparent;
  margin: 0 30px;
  margin-top: 24px;

  .uik-top-bar-image__wrapper {
    display: flex;
    width: 24px;
    height: 24px;
  }
  .uik-top-bar-title__wrapper {
    margin-top: 0px;
    margin-left: 18px;
    font-size: 24px;
    font-weight: 500;
    color: #3E3F42;
  }

`

export default TopBarContainer;