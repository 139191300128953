import styled from 'styled-components/macro';

const ModalInfoSimpleContainer = styled.div`
  position: absolute;
  bottom: 0px;
  width: calc(100% - 269px);
  background: linear-gradient(0deg, rgba(18, 18, 18, 0.49) 0%, rgba(196, 196, 196, 0) 100%);
  max-height: 250px;
  height: 100%;
  z-index: 1001;

  padding: 15px 20px 20px 20px;
  display: flex;
  align-items: flex-end;
  opacity: 1;

  transition: opacity 0.3s ease;

  /* &:hover {
    opacity: 0.3;
  } */

  .missing-info-container {
    position: relative;
    width: 100%;
    padding: 15px 20px 20px 20px;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    overflow: hidden;

    h3 {
      margin-bottom: 30px;
    }

    p {
      font-size: 14px;
      line-height: 18px;
      text-align: justify;
    }

    ul {
      margin-left: 20px;
      color: #9EA0A5;
    }

    li {
      display: flex;
      align-items: center;

      img {
        margin-left: 10px;
        width: 15px;
      }
    }
  }
 
`

export default ModalInfoSimpleContainer;