import React from 'react';

// Type
import { ICardInfoSimpleThree } from './CardInfoSimpleThree-type';

// Style
import CardInfoSimpleThreeContainer from './CardInfoSimpleThree-style';
import { images } from '../../assets/images';



const CardInfoSimpleThree: React.FC<ICardInfoSimpleThree> = (props: ICardInfoSimpleThree) => {
  const { value, footerText, handleAction, height, extraData } = props;


  const getImageFromStatus = (status: string) => {
    if (status == 'down')
      return images.IconTrendingDownSmall;

    if (status == 'up')
      return images.IconTrendingUpSmall;

    if (status == 'equal' || !status)
      return images.IconTrendingEqualSmall;
  }

  return (
    <CardInfoSimpleThreeContainer height={height}>
      <div className='card-simple-three-value'>
        <h2>{value}</h2>
      </div>

      { 
        (extraData) ?
          (
            <div className='card-simple-three-header'>
              <div className='ranking-row'>
                <p><b>Ranking Global:</b> {extraData.ranking_town.global.position}</p>
                <img src={getImageFromStatus(extraData.ranking_town.global.status)} alt='Global estatus'/>
              </div>
              <div className='ranking-row'> 
                <p><b>Ranking Comunidad:</b> {extraData.ranking_community.global.position}</p>
                <img src={getImageFromStatus(extraData.ranking_community.global.status)} alt='Comunidad estatus'/>
              </div>
              <div className='ranking-row'> 
                <p><b>Ranking Provincia:</b> {extraData.ranking_province.global.position}</p>
                <img src={getImageFromStatus(extraData.ranking_province.global.status)} alt='Provincia estatus'/>
              </div>
            </div>
          ) 
        :
          (
            <></>
          )
      }
     
      
      <div className='card-simple-three-footer'>
        <h5 onClick={handleAction}>{footerText}</h5>
      </div>
    </CardInfoSimpleThreeContainer>
  )
}

export default CardInfoSimpleThree