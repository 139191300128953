import React, { useState, useContext, useEffect } from "react";

import GeneralContext from "../../../context/general.context";
import { iSettingsUser, iSettingsPassword, iSettingsGeneric } from "../../../models/users.models";
import { RouteComponentProps, withRouter } from "react-router";

import { iModal } from "../../../models/general.model";
import InfoModal from "../../../components/InfoModal/InfoModal";
import Form from "../../../components/Form/Form";

// Style
import { editCustomer, getCurrentCustomer } from "../../../services/customer.services";
import { TOOLTIPS_TEXT } from "../../../constants/general";
import MultifileUpload from "../../../components/MultifileUpload/MultifileUpload";
import { SERVICES_URL } from "../../../services/url";
import CardInfo from "../../../components/CardInfo/CardInfo";
import { useExtraInfo } from "../hooks/extra-info/Extra-info-hook";

interface RouteParams {
  id: string;
}

interface iSettingsProps extends RouteComponentProps<RouteParams> {}

const SettingsExtraInfo: React.FC<iSettingsProps> = ({ history, match }) => {
  const general = useContext(GeneralContext);

  // Información práctica (hacer el mismo hook para los otros de abajo)
  const { practicalInfo, setPracticalInfo, handlePractInfoDescp } = useExtraInfo();

  // De aqui para abajo, refact como arriba. (como siempre, el tiempo)
  const [town, setTown] = useState<iSettingsGeneric>({
    history: {
      id: "history-extra-perfil",
      value: "",
      error: false,
      errorCode: "",
      required: true,
      type: "text",
      placeholder: `Cuéntanos la historia de tu municipio y lo más importante que ver en él. Por ejemplo, cuál es el origen de la localidad, personajes de interés, fechas relevantes, etc. 

      Además, dinos aquello que más representa a tu localidad. Por ejemplo, monumentos y espacios destacados (iglesias, parques, castillos…), yacimientos y museos, gastronomía, naturaleza (parques naturales, rutas de senderismos…), playas, fiestas y tradiciones, etc. 

      Si tienes links que aporten información sobre lo anterior copia aquí la url. En caso de que sean documentos lo que quieras mandarnos puedes hacerlo a través de este email: linx@espanafascinante.com

      `,
      render: "textarea",
      className: "col-12",
      rows: 11,
      maxLength: 25000,
    },
  });

  const [interestPlace, setInterestPlace] = useState<iSettingsGeneric>({
    place: {
      id: "places-extra-perfil",
      value: [""],
      error: false,
      errorCode: "",
      required: true,
      type: "array",
      placeholder: "Lugar de interés 01",
      render: "array",
      className: "col-12 no-margin-down",
    },
  });

  const [visitPlaces, setVisitPlaces] = useState<iSettingsGeneric>({
    place: {
      value: [""],
      error: false,
      errorCode: "",
      required: true,
      type: "array",
      placeholder: "Qué visitar 01",
      render: "array",
      className: "col-12 no-margin-down",
    },
  });

  const [modal, setModal] = useState<iModal>({
    code: "",
    text: "",
    button: "",
    handleAccept: () => {},
    show: false,
    alert: "",
    title: "",
    className: "",
  });

  const handleTownDescp = async (dataForm: iSettingsUser) => {
    setTown({ ...dataForm });
    const data = {
      history: dataForm.history.value,
    };

    try {
      await editCustomer({
        id: localStorage.getItem("idUser"),
        data: data,
        loader: true,
        setLoading: general.setLoading,
      });

      setModal({
        code: "ok",
        text: "Se ha actualizado la descripción del municipio.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Info",
        alert: "",
        className: "",
      });
      refreshCurrentCustomer();
    } catch (err) {
      setModal({
        code: err.response && err.response.status ? err.response.status : "err-local",
        text:
          err.response && err.response.description
            ? err.response.description
            : "Error inesperado, inténtelo más tarde.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Error",
        alert: "",
        className: "warning",
      });
    }
  };

  const submitPractInfoDescp = async (dataForm: iSettingsUser) => {
    try {
      // llamada al hook
      await handlePractInfoDescp(dataForm);

      setModal({
        code: "ok",
        text: "Se ha actualizado la descripción del municipio.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Info",
        alert: "",
        className: "",
      });
    } catch (err) {
      setModal({
        code: err.response && err.response.status ? err.response.status : "err-local",
        text:
          err.response && err.response.description
            ? err.response.description
            : "Error inesperado, inténtelo más tarde.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Error",
        alert: "",
        className: "warning",
      });
    }
  };

  const handleInteresPlace = async (dataForm: any) => {
    setInterestPlace({ ...dataForm });

    const data = {
      places: dataForm.place.value,
    };

    try {
      await editCustomer({
        id: localStorage.getItem("idUser"),
        data: data,
        loader: true,
        setLoading: general.setLoading,
      });

      setModal({
        code: "ok",
        text: "Se han actualizado los lugares de interés.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Info",
        alert: "",
        className: "",
      });
      refreshCurrentCustomer();
    } catch (err) {
      setModal({
        code: err.response && err.response.status ? err.response.status : "err-local",
        text:
          err.response && err.response.description
            ? err.response.description
            : "Error inesperado, inténtelo más tarde.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Error",
        alert: "",
        className: "warning",
      });
    }
  };

  const handleVisitePlace = async (dataForm: any) => {
    setVisitPlaces({ ...dataForm });

    const data = {
      visits: dataForm.place.value,
    };

    try {
      await editCustomer({
        id: localStorage.getItem("idUser"),
        data: data,
        loader: true,
        setLoading: general.setLoading,
      });

      setModal({
        code: "ok",
        text: "Se han actualizado los lugares que visitar.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Info",
        alert: "",
        className: "",
      });
      refreshCurrentCustomer();
    } catch (err) {
      setModal({
        code: err.response && err.response.status ? err.response.status : "err-local",
        text:
          err.response && err.response.description
            ? err.response.description
            : "Error inesperado, inténtelo más tarde.",
        show: true,
        button: "",
        handleAccept: () => {},
        title: "Error",
        alert: "",
        className: "warning",
      });
    }
  };

  const closeModal = () => {
    setModal({
      code: "",
      button: "",
      handleAccept: () => {},
      text: "",
      show: false,
      title: "",
      alert: "",
      className: "",
    });
  };

  const setModalWarning = () => {
    setModal({
      code: "error",
      text: "Se supera el límite de 10 imágenes o el límite de 5mb de espacio",
      show: true,
      button: "",
      handleAccept: () => {},
      title: "Error",
      alert: "",
      className: "",
    });
    refreshCurrentCustomer();
  };

  const refreshCurrentCustomer = async () => {
    const currentCustomer = await getCurrentCustomer(true);
    general.setCurrentCustomer(currentCustomer);
  };

  useEffect(() => {
    if (general.currentCustomer.username !== "") {
      //Set history
      town.history.value = general.currentCustomer.history;
      setTown({ ...town });

      //Set practical info
      practicalInfo.description.value = general.currentCustomer.description;
      setPracticalInfo({ ...practicalInfo });

      //Set interest places
      interestPlace.place.value = general.currentCustomer.places;
      setInterestPlace({ ...interestPlace });

      //Set visit places
      visitPlaces.place.value = general.currentCustomer.visits;
      setVisitPlaces({ ...visitPlaces });
    }
  }, [general.currentCustomer]);

  useEffect(() => {
    refreshCurrentCustomer();
  }, []);

  return (
    <div>
      <Form
        titleForm="Tú historia"
        buttonText="Guardar cambios"
        handleAccept={handleTownDescp}
        dataForm={town}
        tooltipText={TOOLTIPS_TEXT["descripcion-municipio"]}
      />

      <Form
        titleForm="Lugares para visitar"
        buttonText="Guardar cambios"
        handleAccept={handleInteresPlace}
        dataForm={interestPlace}
        tooltipText={TOOLTIPS_TEXT["lugares-de-interes"]}
      />

      {
        <Form
          titleForm="Links de interés"
          buttonText="Guardar cambios"
          handleAccept={handleVisitePlace}
          dataForm={visitPlaces}
          tooltipText={TOOLTIPS_TEXT["links-interes"]}
          showDisableScreen={general.currentCustomer.subscription ? false : true}
          showDisableScreenText="Esta sección está deshabilitada hasta que te suscribas a un plan."
          disableScreenClass="custom-deactive-class"
        />
      }

      <Form
        titleForm="Información Práctica"
        buttonText="Guardar cambios"
        handleAccept={submitPractInfoDescp}
        dataForm={practicalInfo}
        tooltipText={TOOLTIPS_TEXT["informacion-practica"]}
      />
      <div>
        <CardInfo
          headerTitle="Multimedia"
          expand={true}
          tooltipText={TOOLTIPS_TEXT["subida-multiimagen"]}
          contentClass="display-block"
        >
          <MultifileUpload
            className="multifileupload-custom-style"
            backendUrl={"customers/" + general.currentCustomer.id + "/multimedia"}
            endpointUrl={SERVICES_URL}
            onWarning={setModalWarning}
          />
        </CardInfo>
      </div>

      <InfoModal
        className={modal.className}
        onClickAccept={modal.handleAccept}
        mainText={modal.text}
        titleText={modal.title}
        alertText={modal.alert}
        buttonText={modal.button}
        active={modal.show}
        close={() => closeModal()}
      />
    </div>
  );
};

export default withRouter(SettingsExtraInfo);
