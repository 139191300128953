import styled from "styled-components";

export const NavigationContainer = styled.div`
  position: relative;

 
  a:visited,
  a:active, 
  a {
    color: #6B6C6F;
    text-decoration: none;
  }
  
  .uik-nav-user__wrapper {
    padding-bottom: 15px;
    position: relative;
  }

  .uik-nav-user__avatarWrapper {
    cursor: pointer;
  }

  .uik-nav-link-two-container__wrapper {
    font-weight: 500;
    padding-bottom: 15px;
    .active {
      color: #248489;
      box-shadow: none;
   
      .uikon {
        color: #248489;
      }
    }

    & > div:last-child {
      border-bottom: 1px solid #EAEDF3;
      padding-bottom: 10px;
    }
  }

  #create-report{
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.green > span {
      color: #248489;
    }
  }

  span.underline {
    text-decoration: underline;
  }

  .uik-nav-link__wrapper:hover {
    color: #6B6C6F;
  }

  .uik-nav-link-2__wrapper{
    border-bottom: 0px;
  }

  .uik-scroll__wrapper {
    display: flex;
    flex-flow: column;
  }

  .uik-nav-section__wrapper:last-child {
    margin-bottom: 20px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
}

  .uik-nav-link-2__wrapper {
    .uik-nav-link-2__text {
      margin-left: 10px;
    }
  }

  .uik-nav-link-2__wrapper:hover {
    .uik-nav-link-2__text,
    .uik-nav-link-2__icon {
      color: #248489 !important;
    }
  }

  .uik-nav-link-2__wrapper {
    &.active {
      background: rgba(36,132,137, 0.2);
      font-weight: bold;
    }
  }


  .uik-nav-link-secondary__wrapper {
    padding: 0 30px;
    color: #6b6c6f !important;
    &.active {
      background: rgba(36,132,137, 0.2);
      font-weight: bold;
    }

    &:hover {
      color: #6b6c6f !important;
    }
  }

  #reports-secondary-menu {
    display: none;

    &.show {
      display: block;
    }
  }

  #profile-image-caption {
    text-align: center;
    margin-bottom: 24px;
    line-height: 24px;
  }

  #profile-gear-option {
    position: absolute;
    right: 75px;
    bottom: 10px;
    cursor: pointer;
  }
`;