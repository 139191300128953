import styled, { css } from 'styled-components/macro';

const CardInfoContainer = styled.div<{ height: string | undefined, addSpace: boolean | undefined, backgroundColor: string | undefined, hardStroke: boolean | undefined, flexFlow: string | undefined, showPadding: boolean }>`
  position: relative;
  
  ${(props) => props.showPadding ? (css`
    padding: 40px 30px 20px 30px;
  `) : 
  (css`
    padding: 20px 30px 20px 30px;
  `)
  }

  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  overflow: visible;
  display: flex;
  flex-flow: column;

  ${(props) => props.backgroundColor ? (css`
    background-color: ${props.backgroundColor}
  `) : 
    'background-color: white;'
  }
  
  .uik-btn__base.uik-select__valueRendered {
    height: 28px !important;
  }

  &.expand {
    justify-content: space-between;
  }

  &.no-expand {
    justify-content: flex-start;
  }

  ${(props) => props.height ? (css`
  min-height: ${props.height}px
  `) : ''}

  .card-info-screen-disabled {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    overflow: hidden;
    left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    background-color: rgba(250,250,250,0.7);

    & > div {
      text-align: center;
    }
  }

  small {
    position: absolute;
    top: 15px;
    left: 30px;
    right: 30px;
    color: #248489;
  }

  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 15px;

    ${(props) => props.hardStroke ? (css`
      border-bottom: 1px solid #ABABAC;
    `) : 'border-bottom: 1px solid #EAEDF3;'}
    

    .card-title {
      display: flex;
      justify-items: center;
      align-items: center;



      img {
        margin-top: 0;
        margin-left: 10px;
        cursor: pointer;
      }

    }
  }
  
  .card-main-content {
    display: flex;
    
    ${(props) => props.flexFlow == 'column' ? (css`
      flex-flow: column;
      align-items: center;
    `) :       
      'justify-content: space-between;'
    }

    ${(props) => props.addSpace ? (css`
      margin-top: 25px;
    `) : ''}    

    & > form > .uik-widget-content__wrapper:first-child {
      padding-top: 0px !important;
    }
  }

  .card-options {
    display: flex;
    align-items: center;

    .card-select-options {
      display: flex;
      align-items: center;
    
    }

    .uik-select__wrapper {
      margin-top: 0px;
      margin-left: 10px;
    }
    
  }

  .card-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
  }


  @media screen and (max-width: 1350px) {
    .card-header {
      align-items: center;
    }
  }

  @media screen and (max-width: 1200px) {
    ${(props) => props.showPadding ? (css`
      padding: 40px 30px 20px 30px;
    `) : 
    (css`
      padding: 20px 30px 20px 30px;
    `)
    }
  } 
`

export default CardInfoContainer;