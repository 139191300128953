import React from 'react';

// Type
import { IInactiveAlert } from './InactiveAlert-type';

// Style
import InactiveAlertContainer from './InactiveAlert-style';



const InactiveAlert: React.FC<IInactiveAlert> = (props: IInactiveAlert) => {

  return (
    <InactiveAlertContainer>
      <p>
        Tu cuenta se encuentra <span>pendiente de cancelación.</span>Si cambias de opinión solo tienes que <a href='mailto:linx@espanafascinante.com'>Reactivarla</a>
      </p>
    </InactiveAlertContainer>
  )
  
}

export default InactiveAlert