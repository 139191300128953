import styled from 'styled-components/macro';
import * as uik from '../../utils/third-party/@uik';
import { images } from '../../assets/images';

const {  UikContainerVertical } = uik;

const SettingsContainer = styled(UikContainerVertical)`
  position: relative; 


  #settings-heroblock {
    width: 100%;
    height: 500px;
    background-color: grey;

    background-image: url(${images.ImageSettingHeroblock});
    background-size: cover;
    background-repeat: no-repeat;

    background-position: center;
    
  }

  button {
    width: 100%;
    margin-top: 20px;
  }

  .uik-layout-main__wrapper {
    margin-top: -150px;
    overflow: visible;

    #forms-container {
      & > div > * {
        margin-bottom: 25px;
      }

      .simple-card-texts > *,
      .simple-card-actions > * {
        margin-bottom: 0px;
      }
    }
    
  }

  .card-circle-info {
    & > * {
      margin: 0 auto;
    }
  }

  #settings-aside-menu {
    margin-top: 25px;
  }

  .add-new-item {
    position: absolute;
    bottom: 35px;
    right: 30px
  }
  
  .no-margin-down > div {
    margin: 0px;
    margin-bottom: 10px;
  }

  .delete-single-item {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 38px;


    .delete-single-icon {
      background: #DE4B4B;
      border-radius: 4px;
      width: 38px;
      height: 38px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px;

      margin-left: 15px;
      cursor: pointer;

      img {
        position: relative; 
      }
    }
  }

  .custom-deactive-class {
    padding: 15px;

    & > div {
      padding: 15px 30px;
      background-color: rgba(255, 0, 0, 0.3);
      color: #242424;
    }
  }

  .display-block {
    display: block !important;

    &.disabled {
      margin-top: 25px;
      text-align: center;

      p {
        color: #DE4B4B !important; 
      }
    }
  }

  .multifileupload-custom-style {
    margin-top: 25px;
  }

  .filepond--root {
    /* min-height: 250px; */
    border: 1px solid #EAEDF3;;
    border-radius: 5px;
  }

  .filepond--panel-root {
    background-color: transparent !important;
  }

  .filepond--drop-label {
    background-color: transparent;
    color: inherit;
  }

  .last-change-container {
    margin-top: 20px;
    padding: 15px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    background-color: #E5E5E5
  }

  @media screen and (max-width: 1150px) {
    .delete-single-item {
      .delete-single-icon {
        margin-left: 5px;
      }
    } 
  }

`

export default SettingsContainer;