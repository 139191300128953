import styled, { css } from 'styled-components/macro';

const CardCircleInfoContainer = styled.div<{ size: string | undefined, image: string | undefined }>`
  position: relative;
  padding: 20px 30px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 50%;
  overflow: hidden;

  ${(props) => props.image ? (css`
    background-image: url(${props.image});
    background-size: cover;
    background-repeat: 'not-repeat';
  `) : ''}
  

  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;

  cursor: pointer;

  ${(props) => props.size ? (css`
    width: ${props.size};
    height: ${props.size};
  `) : 'width: 100%;'}
  
  p {
    margin-top: 30px;
  }

  & > div > p {
    margin-top: 75px;
    color: #248489;
    text-decoration: underline;
  }

  div {
    text-align: center;
  }

  .hoverContent {
    opacity: 0;
    background-color: transparent;
    width: 300px;
    border-radius: 50%;
    cursor: pointer;
    padding: 40px;

    &:hover {
      opacity: 1;
      background-color: rgba(255,255,255, 0.8) !important;
    }
  }

  .custom-file-input {
    position: absolute;
    width: 250px;
    height: 250px;
    top: 0px;
    right: 0px;
    left: 0px;
    margin: auto;
    bottom: 0px;
    background-color: transparent;

    & > #canvas {
      background-color: transparent !important;
    }
  }


`

export default CardCircleInfoContainer;