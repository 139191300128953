import React from 'react';

// Type
import { ICardInfoSimpleTwo } from './CardInfoSimpleTwo-type';

// Style
import CardInfoSimpleTwoContainer from './CardInfoSimpleTwo-style';



const CardInfoSimpleTwo: React.FC<ICardInfoSimpleTwo> = (props: ICardInfoSimpleTwo) => {
  const { dateText, value, footText, smallValue, image } = props;

  return (
    <CardInfoSimpleTwoContainer>
      <div className='card-simple-header'>
        <div className='image-container'>
          {image == '' ? 
              (
                <div>--</div>
              )
            :
              (
                <img src={image} alt='trending icon'/>
              )
          }
          
        </div>
        <p>{dateText}</p>
      </div>

      <div className='card-simple-value'>
        <h1>{value}</h1>
        <p>{smallValue}</p>
      </div>
      
      <div className='card-simple-footer'>
        <h5>{footText}</h5>
      </div>
    </CardInfoSimpleTwoContainer>
  )
  
}

export default CardInfoSimpleTwo