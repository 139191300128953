import styled from 'styled-components';

const SimpleAlertContainer = styled.div`

  border: 1px solid #D8DCE6;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .simple-card-button {
    color: #DE4B4B;
    border: 1px solid #DE4B4B;
    padding: 10px 30px;
    border-radius: 5px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.08);
    cursor: pointer
  }

  .simple-card-texts,
  .simple-card-actions {
    margin-bottom: 0px !important;
  }


  @media screen and (max-width: 1300px) {
    flex-flow: column;
    align-items: flex-start;

    .simple-card-actions {
      width: 100%;
    }

    .simple-card-button {
      margin-top: 30px;
      width: 100%;
      text-align: center;
    }
  }

`;


export default SimpleAlertContainer