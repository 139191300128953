import React from 'react';

import { CardContainer, DataContainer } from './card-rate-style';

import * as uik from '../../utils/third-party/@uik';
import { images } from '../../assets/images';

interface iCardsProps {
  title: string;
  text: string;
  price: any;
  active: boolean;
  data: any[];
  handleClick?: Function;
}

const CardRate: React.FC<iCardsProps> = ({ title, text, price, active, handleClick, data }) => {
  const { UikButton, Uikon, UikDivider } = uik;
  return (
    <>
      <CardContainer className={active ? 'active' : ''}>
        <div className="card-header">
          <h3>{title}</h3>
        </div>
        <div className="card-divider">
          <UikDivider />
        </div>
        <div className="card-body">
          <div className="card-body-text">
            <p>{text}</p>
          </div>
          <div className="card-body-price">
            {price}
          </div>
          <UikButton success={!active} onClick={() => handleClick && handleClick()}>
            {active ? 'Plan activo' : 'Actualizar'}
          </UikButton>
        </div>
      </CardContainer>
      <DataContainer>
        {data.map((item: any, index: number) => (
          <div key={index} className={`container-data ${item.active ? 'active' : ''}`}>

            <p>
              {item.active && (
                <img src={images.IconCheck} alt='Check icon'></img>
              )}
              {item.value}
            </p>
          </div>
        ))}
      </DataContainer>
    </>
  );
}

export default CardRate;
