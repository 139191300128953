import React from 'react';

// Type
import { ISimpleAlert } from './SimpleAlert-type';

// Style
import SimpleAlertContainer from './SimpleAlert-style';



const SimpleAlert: React.FC<ISimpleAlert> = (props: ISimpleAlert) => {
  const { title, description, textButton, handleClick } = props;

  return (
    <SimpleAlertContainer>

      <div className='simple-card-texts'>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>

      <div className='simple-card-actions'>
        <div className='simple-card-button' onClick={handleClick}> 
          {textButton}
        </div>
      </div>

    </SimpleAlertContainer>
  )
  
}

export default SimpleAlert