import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router';


// Types
import { iTrafficReportsProps } from './Traffic-type';
import CardInfo from '../../../components/CardInfo/CardInfo';

// Styles
import TrafficReportsContainer from './Traffic-style';

// Components
import TopBar from '../../../components/TopBar/TopBar';
import Table from '../../../components/Table/Table';
import { iRow } from '../../../models/general.model';
import { ICardHeaderSelect, ICardSelectOptions, ICardSelectInput } from '../../../components/CardInfo/CardInfo-type';
import { Line } from 'react-chartjs-2';
import { images } from '../../../assets/images';
import { TOOLTIPS_TEXT, ANALYTICS_TOKEN, CHART_COLORS, SELECT_PERIODICITY, SELECT_OPT_PERIODICITY } from '../../../constants/general';
import { getTrafficData, getPageViewData, getTrafficSessionsData } from '../../../services/reports.services';
import GeneralContext from '../../../context/general.context';

// External library (Look how to inyect)
import * as uik from '../../../utils/third-party/@uik';
import moment from 'moment';
import { numberFormat } from '../../../utils/text';
import Pagination from '../../../components/Pagination/Pagination';
import { formatDateToHHMMSS } from '../../../utils/time';


const TrafficReports: React.FC<iTrafficReportsProps> = ({ history }) => {
  const general = useContext(GeneralContext);





  




  ////////////////////////////////////////////////////////////////
  ////////////////////// TO SHOW LOADER //////////////////////////
  ////////////////////////////////////////////////////////////////
  const [chartWasLoaded1, setChartWasLoaded1] = useState(false);
  const [chartWasLoaded2, setChartWasLoaded2] = useState(false);
  const [chartWasLoaded3, setChartWasLoaded3] = useState(false);

  useEffect( () => {
    general.setLoading(true);
  }, []);

  useEffect( () => {
    
    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3) 
      general.setLoading(false);
  }, [
    chartWasLoaded1,
    chartWasLoaded2,
    chartWasLoaded3
  ]);
  ////////////////////////////////////////////////////////////////
  //////////////////// END TO SHOW LOADER ////////////////////////
  ////////////////////////////////////////////////////////////////  























  ////////////////////////////////////////////////////////////////
  ///////////////////////// MULTICHART ///////////////////////////
  ////////////////////////////////////////////////////////////////
  // ¡¡¡OBVIAMENTE NECESITA REFACT!!!
  interface IMultiChartLimitDates {
    start_date: string;
    end_date: string;
    type: string;
  }

  interface IMultiChartTownDates {
    avg_session: string;
    date: string;
    page_views: string;
    users: string;

    [key: string]: string;
  }

  interface IMultiChartTownTotals {
    avg_session: string;
    page_views: string;
    users: string;

    [key: string]: string;
  }

  interface IMultiChartTown {
    dates: IMultiChartTownDates[];
    total: IMultiChartTownTotals;
  }

  interface IMultiChartOject {
    [key: string]: IMultiChartTown;
  }

  interface IMultiChart {
    [key: number]: IMultiChartOject
  }

  const initMultiChartDefaultValue = (): IMultiChart => {
    return [
      {
        [general.currentCustomer.town.name]: {
          dates: [],
          total: {
            avg_session: "--",
            page_views: "--",
            users: "--"
          }
        }
      }
    ]
  }

  const initMultiChartDefaultLimits = (): IMultiChartLimitDates => {
    return {
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      type: ''
    }
  }

  const [ labelCards, setLabelCards] = useState('Datos semanales *');

  const [multiChartData, setMultiChartData] = useState<IMultiChart>(initMultiChartDefaultValue);
  const [multiChartOption, setMultiChartOption] = useState<string>('users');
  const [multiChartLimitDates, setMultiChartLimitDates] = useState<IMultiChartLimitDates>(initMultiChartDefaultLimits);

  const getMultiChartSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value[multiChartOption])) : [];

    return resp;
  }

  const getMultiChartSelectedDate = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => value.date) : [];

    return resp;
  }

  const getMultiChartSelectedSets = () => {
    return (
      [{
        label: 'Mi pueblo',
        fill: false,
        lineTension: 0.1,
        backgroundColor: CHART_COLORS['total-users'],
        borderColor: CHART_COLORS['total-users'],
        borderWidth: 2,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: CHART_COLORS['total-users'],
        pointBackgroundColor: CHART_COLORS['total-users'],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: CHART_COLORS['total-users'],
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        yAxisID: 'first-y-axis',

        data: getMultiChartSelectedData(),
      }]
    )
  }

  const dataLine = {
    labels: getMultiChartSelectedDate(),
    datasets: getMultiChartSelectedSets(),
  }

  const optionsLine = {
    scales: {
      yAxes: [{
        id: 'first-y-axis',
        type: 'linear',
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          callback: (label: any) => {
            if (multiChartOption == 'avg_session') 
              return formatDateToHHMMSS(label);

            return numberFormat(label);
          }
        },
        gridLines: {
          drawBorder: false
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },


    legend: {
      display: false,
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },


    tooltips: {
      label: 'reportTrafficMultiLineChart',
      callbacks: {
        label: (tooltipItem: any, data: any) => {
            if (multiChartOption == 'avg_session') 
              return formatDateToHHMMSS(tooltipItem.value);

            return numberFormat(tooltipItem.value);
        }
      },
    },
    
    
    pointDot: false
  }

  const lineChartHeaderSelectOptions: ICardSelectOptions = {
    defaultValue: '',
    values: [
      {
        label: SELECT_OPT_PERIODICITY['daily'],
        value: ''
      },
      {
        label: SELECT_OPT_PERIODICITY['weekly'],
        value: 'week'
      },
      {
        label: SELECT_OPT_PERIODICITY['monthly'],
        value: 'month'
      }
    ]
  }

  const lineChartCardHeaderOptions: ICardHeaderSelect = {
    options: lineChartHeaderSelectOptions,
    leftText: 'Periodicidad',
    callback: (data: ICardSelectInput) => {
      formatAndSetNewTime(data);
    }
  }


  const formatAndSetNewTime = (data: ICardSelectInput) => {
    let resp;
    
    switch (data.value) {
      case 'week':
        resp = {
          start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'week'
        }
        break;
      case 'month':
        resp = {
          start_date: moment().subtract(1, 'years').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'month'
        }
        break;
      default:
        resp = {
          start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: ''
        }
        break;
    }
    setLabelCards(SELECT_PERIODICITY[data.value as string]);
    setMultiChartLimitDates(resp);
  }

  //Load first chart multiple data
  const loadTrafficData = async () => {
    
    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=' + multiChartLimitDates.start_date,
      'endDate=' + multiChartLimitDates.end_date,
      'type=' + multiChartLimitDates.type
    ];

    const res = await getTrafficData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !chartWasLoaded1 ? () => {} : general.setLoading,
      params: params
    });

    setMultiChartData(res);
    setChartWasLoaded1(true);
  }

  const returnLoadTrafficData = (data: IMultiChartOject, key: string, resp?: any) => {
    if (data[general.currentCustomer.town.name]) {
      let resp = data[general.currentCustomer.town.name].total[key];

      if (key === 'avg_session') {
        return  formatDateToHHMMSS(resp);
      }

      return numberFormat(Number(resp));
    }

    if (resp)
      return resp;

    return '--';
  }

  // This is a function to load different data
  const loadDataToTrafficMainChart = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const tabActiveElem = document.querySelectorAll('.single-select-tab.active');
    const currentTarget = event.currentTarget;

    if (tabActiveElem.length > 0)
      tabActiveElem[0].classList.remove('active');

    if (currentTarget.parentElement)
      currentTarget.parentElement.classList.add('active');

    //Load other data here
    setMultiChartOption(currentTarget.getAttribute('data-to-chart') as string);
  }

  useEffect(() => {
    if (general.currentCustomer.town.name)
      loadTrafficData();
  }, [
    // For multichart data loading
    general.currentCustomer,
    multiChartLimitDates
  ])
  ////////////////////////////////////////////////////////////////
  ///////////////////////// END MULTICHART ///////////////////////
  ////////////////////////////////////////////////////////////////













  ////////////////////////////////////////////////////////////////
  ///////////////////////// PAGE VIEW ////////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IPageViewData {
    entrance_rate: string;
    path: string;
    views: string;
    view_percentage: string;
  }

  interface IPageViewPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface IPageViewResp {
    data: IPageViewData[];
    pagination: IPageViewPagination;
  }


  interface IPageViewOject {
    [key: string]: IPageViewResp;
  }

  interface IPageView {
    [key: number]: IPageViewOject
  }

  const initPageViewDefaultValue = (): IPageView => {
    return (
      [{
        [general.currentCustomer.town.name]: {
          data: [],
          pagination: {
            next_page_token: '0',
            total: 1,
            total_pages: 1
          }
        }
      }]
    )
  }

  const [pageViewsData, setPageViewsData] = useState<IPageView>(initPageViewDefaultValue());

  // for pagination
  const [pagePageViewsData, setPagePageViewsData] = useState<number>(1);
  const [totalPagePageViewsData, setTotalPagePageViewsData] = useState<number>(1);
  const [reloadItemsPageViews, setReloadItemsPageViews] = useState<boolean>(true);

  const changePagePageViews = (page: number) => {
    setPagePageViewsData(page);
    setReloadItemsPageViews(true);
  }

  
  const getPageViewsDate = (): iRow[] => {
    if (!pageViewsData[0])
      return [];
    const currentTownData = pageViewsData[0][general.currentCustomer.town.name] as any;

    let pageRows: iRow[] = [];

    if (currentTownData && currentTownData.events) {
      if (currentTownData.events.length == 0) {
        pageRows.push({
          links: {
            value: '--',
            type: 'text'
          },
          clicks: {
            value: '--',
            type: 'text',
            extraClass: 'line-td text'
          }
        })
      }
      else {
        currentTownData.events.map((value: any) => {

          pageRows.push({
            links: {
              value: value.link,
              type: 'text'
            },
            clicks: {
              value: numberFormat(Number(value.clicks)),
              type: 'text',
              extraClass: 'line-td text'
            }
          })
        });
      }
    }

    return pageRows;
  }

  const columns = ['Links', 'Nº de Clicks'];
  
  const keyRows = ['links', 'clicks', ];


  //Utility
  const getPaintedArrayBlueprint = (number: number): boolean[] => {
    const part = 100 / 5;
    let response: boolean[] = [];
    if (number > part * 4) {
      response = [true, true, true, true, true];

      return response;
    }

    if (number > part * 3) {
      response = [true, true, true, true, false];
      return response;
    }

    if (number > part * 2) {
      response = [true, true, true, false, false];
      return response;
    }

    if (number > part) {
      response = [true, true, false, false, false];
      return response;
    }

    response = [true, false, false, false, false];
    return response;

  }

  const loadPageViewData = async () => {
    
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res: IPageView = await getPageViewData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !chartWasLoaded2 ? () => {} : general.setLoading,
      params: params
    });

    setPageViewsData(res);
    setChartWasLoaded2(true);
    
    if (res[0] && res[0][general.currentCustomer.town.name] && res[0][general.currentCustomer.town.name].pagination)
      setTotalPagePageViewsData(res[0][general.currentCustomer.town.name].pagination.total_pages);
  }


  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsPageViews) {
      loadPageViewData();
      setReloadItemsPageViews(false);
    }

  }, [ general.currentCustomer, reloadItemsPageViews])

  ////////////////////////////////////////////////////////////////
  ///////////////////////// END PAGE VIEW ////////////////////////
  ////////////////////////////////////////////////////////////////


















  ////////////////////////////////////////////////////////////////
  ///////////////////// COHORTES TABLE ///////////////////////////
  ////////////////////////////////////////////////////////////////
  // interface ICohortsDaySpecData {
  //   day_number: string;
  //   percentage: string;
  // }
  
  // interface ICohortsDayData {
  //   data: ICohortsDaySpecData[];
  //   total_users: number;
  // }
  
  // interface ICohortsObjectData {
  //   [key: string]: ICohortsDayData;
  // }
  
  // interface ICohortsObjData  {
  //   data: ICohortsObjectData;
  //   total_users: number;
  // }

  // interface ICohortsObj {
  //   [key: string]: ICohortsObjData;
  // }

  // interface ICohortsData {
  //   [key: number]: ICohortsObj
  // }

  // const initCohortsDataDefaultValue = (): ICohortsData => {
  //   return [{
  //       [general.currentCustomer.town.name] : {
  //         data: {},
  //         total_users: 0
  //       }
  //   }]
  // }

  // const [cohortsData, setCohortsData] = useState<ICohortsData>(initCohortsDataDefaultValue());

  // const getCohortsData = () => {
  //   if (cohortsData[0] && cohortsData[0][general.currentCustomer.town.name])
  //     return cohortsData[0][general.currentCustomer.town.name].data;
    
  //   return [];
  // }
  // const getCohortsTotal = () => {
  //   if (cohortsData[0] && cohortsData[0][general.currentCustomer.town.name])
  //     return cohortsData[0][general.currentCustomer.town.name].total_users;
    
  //   return 0;
  // }

  // const loadCohortsData = async () => {
  //   


  //   const res: ICohortsData = await getTrafficCohortsData({
  //     loader: true,
  //     //setLoading: general.setLoading,
  // setLoading: () => {},
  //     params: [
  //       "towns="+general.currentCustomer.town.name
  //     ]
  //   });
  //   
  //   setCohortsData(res);
  // }

  // useEffect(() => {
  //   if (general.currentCustomer.town.name)
  //     loadCohortsData();

  // }, [ general.currentCustomer])

  ////////////////////////////////////////////////////////////////
  ///////////////////// END COHORTES TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////















  ////////////////////////////////////////////////////////////////
  ////////////////// SESSIONS RANGES TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////
  interface ISegmentBuyRespData {
    pageviews: string;
    sessions: string;
    sessionsRange: string;
  }

  interface ISegmentBuyPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ISegmentBuyResp {
    data: ISegmentBuyRespData[];
    pagination: ISegmentBuyPagination;
  }

  interface ISegmentBuyTableObject {
    [key: string]: ISegmentBuyResp
  }

  interface ISegmentBuyTable {
    [key: number]: ISegmentBuyTableObject
  }

  const initSegmentsBuyDefaultValue = (): ISegmentBuyTable => {
    return (
      [{
        [general.currentCustomer.town.name]: {
          data: [],
          pagination: {
            next_page_token: '0',
            total: 1,
            total_pages: 1
          }
        }
      }]
    )
  }

  const [segmentBuyData, setSegmentyBuyData] = useState<ISegmentBuyTable>(initSegmentsBuyDefaultValue());

  // for pagination
  const [pageTrafficSessionData, setPageTrafficSessionData] = useState<number>(1);
  const [totalPageTrafficSessionData, setTotalPageTrafficSessionData] = useState<number>(1);
  const [reloadItemsTrafficSession, setReloadItemsTrafficSession] = useState<boolean>(true);

  const changePageTrafficSession = (page: number) => {
    setPageTrafficSessionData(page);
    setReloadItemsTrafficSession(true);
  }

  const getTrafficSessionRows =  (): iRow[] => {
    
    if (!segmentBuyData[0])
      return [];
    const currentTownData = segmentBuyData[0][general.currentCustomer.town.name];
    let pageViewsRows: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: ISegmentBuyRespData) => {
 
        pageViewsRows.push({
          sessionsRange: {
            value: value.sessionsRange,
            type: 'text',
          },
          sessions: {
            value: numberFormat(Number(value.sessions)),
            type: 'text',
          },
          pageviews: {
            value:  numberFormat(Number(value.pageviews)),
            type: 'text',
          }
        });
      });
    }

    return pageViewsRows
  }
  
  const sessionsColumns = ['Rango de la sesión','Sesiones','Páginas Vistas'];

  const sessionskeyRows = ['sessionsRange', 'sessions', 'pageviews'];
  

  //Load chart data
  const loadSegmentsBuyTableData = async () => {
    
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res = await getTrafficSessionsData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: () => {},
      params: params
    });

    setSegmentyBuyData(res);
    //setTotalPageTrafficSessionData(res[0][general.currentCustomer.town.name].pagination.total_pages);
    setChartWasLoaded3(true);
  }

  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsTrafficSession) {      
      loadSegmentsBuyTableData();
      setReloadItemsTrafficSession(false);
    }
  }, [general.currentCustomer, reloadItemsTrafficSession])
  ////////////////////////////////////////////////////////////////
  ////////////////// END SESSIONS RANGES TABLE ///////////////////
  ////////////////////////////////////////////////////////////////














  // Detect if show or not "No data" screen on cardInfo
  const detectIfMultichartChartHaveData = (): boolean => {
    if (multiChartData[0] && multiChartData[0][general.currentCustomer.town.name])
      return multiChartData[0][general.currentCustomer.town.name].dates.length == 0 ? true : false

    return false;
  }

  // End detect











  // Get third party components
  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikLayoutMain,

    UikTabContainer,
    UikTabItem
  } = uik;




  return (
    <TrafficReportsContainer className='page'>

      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportTraffic} alt="Icono de reporte de tráfico" />
          </div>
          <UikTopBarTitle>
            Tráfico
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>

      <UikLayoutMain className={'wrapper content'}>

        <div className='grid'>


          <div id='report-line-chart' className='col-12'>
            <CardInfo
              headerCustom={
                (
                  <UikTabContainer id='traffic-line-tabs-container'>

                    <UikTabItem className='single-select-tab' active>
                      <div data-to-chart='users' onClick={loadDataToTrafficMainChart}>
                        <h1>{returnLoadTrafficData(multiChartData[0], 'users')}</h1>
                        <p>Usuarios</p>
                      </div>
                    </UikTabItem>
                    <UikTabItem className='single-select-tab'>
                      <div data-to-chart='page_views' onClick={loadDataToTrafficMainChart}>
                        <h1>{returnLoadTrafficData(multiChartData[0], 'page_views')}</h1>
                        <p>Páginas Vistas </p>
                      </div>
                    </UikTabItem>

                    <UikTabItem className='single-select-tab'>
                      <div data-to-chart='avg_session' onClick={loadDataToTrafficMainChart}>
                        <h1>{returnLoadTrafficData(multiChartData[0], 'avg_session')}</h1>
                        <p>Duración media de la sesión</p>
                      </div>
                    </UikTabItem>
                  </UikTabContainer>
                )
              }
              headerSelect={lineChartCardHeaderOptions}
              height='400'
              expand={true}
              statusHeaderText={labelCards}
              contentClass='card-content-line-chart'
              showDisableScreen={detectIfMultichartChartHaveData()}
              showDisableScreenText={'No hay datos suficientes para cargar la gráfica.'}
              disableScreenClass={'middle-screen'}
            >
              <Line height={100} width={500} data={dataLine} options={optionsLine}></Line>
            </CardInfo>
          </div>

          {/* <div id='traffic-analytics-tables' className='col-12'>
            <CardInfo
              headerTitle='Análisis de cohortes'
              height='500'
              tooltipText={TOOLTIPS_TEXT['analisis-de-cohorte']}
            >
              <TableAnalytic
                data={getCohortsData()}
                total={getCohortsTotal()}
                header={['b']}
                columns={['c']}
              >

              </TableAnalytic>


            </CardInfo>
          </div> */}


          <div id='traffic-session-table' className='col-12'>
            <CardInfo
              headerTitle='Rangos de sesiones'
              height='400'
              tooltipText={TOOLTIPS_TEXT['rango-sesiones-data']}
              flexFlow='column'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
            >
              <>
                <Table
                  rows={getTrafficSessionRows()}
                  columns={sessionsColumns}
                  keyRows={sessionskeyRows}
                  handleOrder={() => { }}
                  showLoadingText={true}
                />

                {/* <div className="container-pagination">
                  <Pagination page={pageTrafficSessionData} limit={totalPageTrafficSessionData} callback={(page: number) => changePageTrafficSession(page)} />
                </div> */}
              </>
            </CardInfo>
          </div>


          <div id='traffic-page-views' className='col-12'>
            <CardInfo
              headerTitle='Url que han sido clickeadas'
              tooltipText={TOOLTIPS_TEXT['url-been-clicked']}
              flexFlow='column'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
            >
              <>
                <Table
                  rows={getPageViewsDate()}
                  columns={columns}
                  keyRows={keyRows}
                  handleOrder={() => { }}
                  showLoadingText={true}
                />

                <div className="container-pagination">
                  <Pagination page={pagePageViewsData} limit={totalPagePageViewsData} callback={(page: number) => changePagePageViews(page)} />
                </div>
              </>

            </CardInfo>
          </div>           


        </div>
      </UikLayoutMain>

    </TrafficReportsContainer>
  );
}


export default withRouter(TrafficReports);