import * as dateFns from 'date-fns';
import es from 'date-fns/locale/es';
import en from 'date-fns/locale/en-US';
import ca from 'date-fns/locale/ca';
import moment from 'moment';

const locales: any = { es, en, ca };

export const getDateFormat = (time: any, format: any, lang = 'es') =>
  dateFns.format(time, format, { locale: locales[lang] });

export const formatDateToHHMMSS = (date: string) => {
  return moment().startOf('day')
          .seconds(Number(date))
          .format('HH:mm:ss');
}