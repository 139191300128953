import { post } from "./general.services";

export const login = async (args: any) => {
  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "auth/login"
    });
    
    if (res.data.type === 'staff')
      throw new Error('Solo pueden iniciar sesión custumers, no usuarios de staff');

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const resetPassword = async (args: any) => {
  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "auth/password/reset"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const forgotPassword = async (args: any) => {
  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "auth/password/forgot"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}
