import styled from 'styled-components/macro';
import * as uik from '../../../utils/third-party/@uik';

const { UikContainerVertical } = uik;

const InterestsReportsContainer = styled(UikContainerVertical)`
  position: relative;  
  

  .grid {

    gap: 30px;

    &.general-interest-grid {
      /* grid-template-areas:  "linechart circlechart"
                            "worldmap  languagetable"; */



      @media screen and (max-width: 1350px) {
        grid-template-columns: none;
        grid-template-areas:  "principalcategory principalcategory"
                              "principalchannels principalchannels"
                              "pagesearchs pagesearchs"
                              "segmentstobuy segmentstobuy";

        #interests-principal-channels {
          grid-area: principalchannels;
        }
        
        #interests-principal-category {
          grid-area: principalcategory;
        }

        #interests-page-search {
          grid-area: pagesearchs;
        }
        
        #interests-segment-to-buy {
          grid-area: segmentstobuy;
        }
      
      }

    }    
  }

  .interest-pie-chart-class {
    justify-content: center !important;
  }

  .wrapper.content {
    margin: 15px;
  }

  .two-side-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  tr td:first-child {
    /* max-width: 200px; */
    word-break: break-all;
  }

  .line-td {
    /* border-right: 1px solid #EAEDF3; */
    padding-right: 0px !important; 
    padding-left: 0px !important;
    width: 130px;
  
    &.text {
      padding-right: 20px !important; 
      text-align: right;
    }
  }

  .cateogry-main-table {
    width: 100%;
  }

  #report-circle-chart-content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    position: relative;
  }

  .container-pagination {
    display: flex;
    justify-content: flex-end;
  }

  @media screen and (max-width: 1350px) {
    .two-side-box{
      flex-flow: column-reverse;
      align-items: center;

      & > div  {
        width: 100%;
      }
    }

    #report-circle-chart-content {
      display: flex;
      margin-top: 30px;
    }
  }

`

export default InterestsReportsContainer;