import { get } from "./general.services";

export const getBills = async (args: any) => {
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `customers/${args.id}/invoices${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getOneBills = async (args: any) => {

  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `invoices/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const getOneBillsDownload = async (args: any) => {

  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `invoices/download/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}