import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import classnames from 'classnames';

import './utils/third-party/@uik/styles.css';
import './App.css';
import { GeneralProvider } from './context/general.context';
import Login from './containers/Login/Login';
import * as uik from './utils/third-party/@uik';

import Navigation from './components/Navigation/Navigation';
import Subscriptions from './containers/Subscriptions/Subscriptions';
import Billing from './containers/Billing/Billing';
import ForgotPassword from './containers/Login/ForgotPassword';
import ResetPassword from './containers/Login/ResetPassword';
import Settings from './containers/Configuration/Settings';
import Loader from './components/Loader/Loader';
import Reports from './containers/Reports/Reports';
import NotFound from './components/NotFound/NotFound';
import SetPassword from './containers/Login/SetPassword';
import { getCurrentCustomer } from './services/customer.services';
import { iCustomers, customerInitValue } from './models/customers.models';
import ModalMissingInfo from './components/ModalMissingInfo/ModalMissingInfo';
import InactiveAlert from './components/InactiveAlert/InactiveAlert';

const App: React.FC = () => {

  const [showInactiveAlert, setShowInactiveAlert] = useState(false);
  const [showMissingModal, setShowMissingModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [currentCustomer, setCurrentCustomer] = useState<iCustomers>(customerInitValue());
  
  const [reloadItem, setReloadItem] = useState<boolean>(true);
  const [isLogged, setIsLogged] = useState((localStorage.accessToken && localStorage.idUser) ?
    true : false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { UikContainerHorizontal, UikContainerVertical } = uik;

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.has("accessToken") ? urlParams.get("accessToken") : 'none';
  const setPassToken = urlParams.has("setPassToken") ? urlParams.get("setPassToken") : false;

  
  useEffect( () => {
    if (isLogged)
      getCustomerDetails();
    
    
  }, [isLogged]);

  useEffect( () => {
    if (currentCustomer.status)
      currentCustomer.status == 'active' ? setShowInactiveAlert(false) : setShowInactiveAlert(true);
    else setShowInactiveAlert(false);

    checkIfPersonalInfoIsMissing();
  }, [currentCustomer])

  const getCustomerDetails = async () => {
    const customer = await getCurrentCustomer();
    setCurrentCustomer(customer);
  }

  const checkIfPersonalInfoIsMissing = () => {
    
    if (!currentCustomer.history||
        !currentCustomer.description||
        !currentCustomer.history||
        currentCustomer.places.length ==  0)
          setShowMissingModal(true);
    else 
      setShowMissingModal(false);
  }

  return (
    <Router>
      <GeneralProvider value={{
        loading,
        isLogged,
        currentCustomer,
        isMenuOpen,
        setLoading,
        setCurrentCustomer,
        setIsLogged,
        setIsMenuOpen
      }}>
        {isLogged ? (
          <UikContainerHorizontal id='main-app-content'>
            <Navigation className={classnames("menuAnimate", { ["menuOpen"]: isMenuOpen, }, "main-header-aside")} />
            <UikContainerVertical id="main-container" className={classnames("menuAnimate", { ["menuOpen"]: isMenuOpen })}>

                  {
                    (showInactiveAlert) ?
                      <InactiveAlert />
                    :
                      <div></div>
                    }

              <Switch>
                <Route exact path="/" component={Reports} />
                <Route path="/settings" component={Settings} />
                <Route path="/reports" component={Reports} />
                <Route exact path="/subscriptions" component={Subscriptions} />
                <Route exact path="/billing" component={Billing} />
                <Route render={() => <Redirect to="/reports/general" />} />
                {/* 
                
                <Route exact path="/subscriptions/edit/:id" component={SubscriptionForm} />
                <Route exact path="/customers/create" component={CustomerForm} />
                <Route exact path="/customers/edit/:id" component={CustomerForm} /> 
                <Route path="/customers/detail/:id" component={CustomerDetailContainer} />
                <Route path="/" render={() => <div>NOT FOUND</div>} />
                
                */}
              </Switch>

              {
              (showMissingModal) ?
                (
                  <ModalMissingInfo
                    title='Ayúdanos a mejorar tu página'
                    customer={currentCustomer}
                    description='' // Esto cambió, y como siempre, por los tiempos, se puso dentro del componente
                  />
                )
              :
                (
                  <></>
                )
              }

            </UikContainerVertical>
          </UikContainerHorizontal>
        ) : (
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              
              {setPassToken  && (
                <Route exact path="/set-password" render={() => <SetPassword token={setPassToken} />} />
              )}
              
              {setPassToken  && (
                <Route exact path="/recover-password" render={() => <ResetPassword token={setPassToken} />} />
              )}
              <Route path="/not-found" component={NotFound} />
              <Route render={() => <Redirect to="/not-found" />} />
            </Switch>
          )}
        {loading && <Loader />}
      </GeneralProvider>
    </Router >
  );
}

export default App;
