import React from 'react';

// Type
import { ISimpleSideMenu, ISimpleSideMenuOption } from './SimpleSideMenu-type';

// Style
import SimpleSideMenuContainer from './SimpleSideMenu-style';
import { NavLink } from 'react-router-dom';



const SimpleSideMenu: React.FC<ISimpleSideMenu> = (props: ISimpleSideMenu) => {
  const { options } = props;

  return (
    <SimpleSideMenuContainer>

      {options.map( (opt: ISimpleSideMenuOption, index: number) => {

        return (
          <NavLink to={opt.to} className='simple-sidemenu-option' activeClassName='active' key={index}>
            <div>
              <p>{opt.name}</p>
            </div>
          </NavLink>
        )
      })}
      
    </SimpleSideMenuContainer>
  )
  
}

export default SimpleSideMenu