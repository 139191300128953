import React, { useEffect, useState, useContext, ReactElement } from 'react'
import { withRouter } from 'react-router';
import { images } from '../../../assets/images';
import GeneralReportsContainer from './General-style';
import { getUsersDataByCountry, getLanguagesChartData, getWorldMapData, getTrafficData } from '../../../services/reports.services';

import { Line, Pie } from 'react-chartjs-2';

// Types
import { iGeneralReportsProps } from './General-type';
import {
  ICountriesBackResp,
  ICountryInfo
} from '../../../services/data-test/demographic';
import TopBar from '../../../components/TopBar/TopBar';

// Components
import CardInfo from '../../../components/CardInfo/CardInfo';

// External library (Look how to inyect)
import * as uik from '../../../utils/third-party/@uik';
import { ICardSelectOptions, ICardHeaderSelect, ICardFooterSelect, ICardFooterRadio, ICardSelectInput } from '../../../components/CardInfo/CardInfo-type';
import CardInfoSimple from '../../../components/CardInfoSimple/CardInfoSimple';
import Table from '../../../components/Table/Table';
import { iRow } from '../../../models/general.model';
import MapBarIndicator from '../../../components/MapBarIndicator/MapBarIndicator';
import { TOOLTIPS_TEXT, ANALYTICS_TOKEN, CHART_COLORS, SELECT_PERIODICITY, SELECT_OPT_PERIODICITY } from '../../../constants/general';
import GeneralContext from '../../../context/general.context';
import Pagination from '../../../components/Pagination/Pagination';
import moment from 'moment';
import { numberFormat } from '../../../utils/text';
import ReactDOM from 'react-dom';
import ReactTooltip from 'react-tooltip';
const Snap = require('snapsvg-cjs');


const GeneralReports: React.FC<iGeneralReportsProps> = ({ history }) => {
  const general = useContext(GeneralContext);




  ////////////////////////////////////////////////////////////////
  ////////////////////// TO SHOW LOADER //////////////////////////
  ////////////////////////////////////////////////////////////////
  const [chartWasLoaded1, setChartWasLoaded1] = useState(false);
  const [chartWasLoaded2, setChartWasLoaded2] = useState(false);
  const [chartWasLoaded3, setChartWasLoaded3] = useState(false);

  useEffect( () => {
    general.setLoading(true);
  }, []);

  useEffect( () => {
    
    
    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3) 
      general.setLoading(false);
  }, [
    chartWasLoaded1,
    chartWasLoaded2,
    chartWasLoaded3
  ]);
  ////////////////////////////////////////////////////////////////
  //////////////////// END TO SHOW LOADER ////////////////////////
  ////////////////////////////////////////////////////////////////  















  ////////////////////////////////////////////////////////////////
  ////////////////////// TOP CARTS INFO //////////////////////////
  ////////////////////////////////////////////////////////////////
  const [ labelCards, setLabelCards] = useState('Datos semanales *'); 
  const getCardChartsTotalUsers = () => {
    if (!multiChartData[0]) {
      return '0';
    }
    const total_users = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].total.users : '0';


    return numberFormat(Number(total_users));
  }


  const getCardChartsNewUsers = () => {
    if (!multiChartData[0]) {
      return '0';
    }
    const new_users = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].total.new_users : '0';


    return numberFormat(Number(new_users));
  }


  const getCardChartsReturningUsers = () => {
    if (!multiChartData[0]) {
      return '0';
    }
    const returning_users = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].total.returning_users : '0';


    return numberFormat(Number(returning_users));
  }


  ////////////////////////////////////////////////////////////////
  //////////////////// END TOP CARTS INFO ////////////////////////
  ////////////////////////////////////////////////////////////////  




  ////////////////////////////////////////////////////////////////
  ///////////////////////// MULTICHART ///////////////////////////
  ////////////////////////////////////////////////////////////////
  // ¡¡¡OBVIAMENTE NECESITA REFACT!!!
  interface IMultiChartLimitDates {
    start_date: string;
    end_date: string;
    type: string;
  }

  interface IMultiChartTownDates {
    avg_session: string;
    date: string;
    page_views: string;
    users: string;

    [key: string]: string;
  }

  interface IMultiChartTownTotals {
    avg_session: string;
    page_views: string;
    users: string;

    [key: string]: string;
  }

  interface IMultiChartTown {
    dates: IMultiChartTownDates[];
    total: IMultiChartTownTotals;
  }

  interface IMultiChartOject {
    [key: string]: IMultiChartTown;
  }

  interface IMultiChart {
    [key: number]: IMultiChartOject
  }

  const initMultiChartDefaultValue = (): IMultiChart => {
    return [{
        [general.currentCustomer.town.name]: {
          dates: [],
          total: {
            avg_session: "--",
            page_views: "--",
            users: "--",
            new_users: "--",
            returning_users: '--'
          }
        }
      }]
  }

  const initMultiChartDefaultLimits = (): IMultiChartLimitDates => {
    return {
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      type: ''
    }
  }

  const [multiChartData, setMultiChartData] = useState<IMultiChart>(initMultiChartDefaultValue);
  const [multiChartOption, setMultiChartOption] = useState<string>('users');
  const [multiChartLimitDates, setMultiChartLimitDates] = useState<IMultiChartLimitDates>(initMultiChartDefaultLimits);

  const getMultiChartSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value[multiChartOption])) : [];

    return resp;
  }

  const getMultiChartNewSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value['new_users'])) : [];

    return resp;
  }  

  const getMultiChartReturningSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value['returning_users'])) : [];

    return resp;
  }    

  const getMultiChartSelectedDate = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => value.date) : [];

    return resp;
  }

  const getMultiChartSelectedSets = () => {
    return (
      [{
        label: 'Usuarios totales',
        fill: false,
        lineTension: 0.1,
        backgroundColor: CHART_COLORS['total-users'],
        borderColor: CHART_COLORS['total-users'],
        borderWidth: 2,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: CHART_COLORS['total-users'],
        pointBackgroundColor: CHART_COLORS['total-users'],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: CHART_COLORS['total-users'],
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        yAxisID: 'first-y-axis',

        data: getMultiChartSelectedData(),
      },
      {
        label: 'Nuevos usuarios',
        fill: false,
        lineTension: 0.1,
        backgroundColor: CHART_COLORS['new-users'],
        borderColor: CHART_COLORS['new-users'],
        borderWidth: 2,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: CHART_COLORS['new-users'],
        pointBackgroundColor: CHART_COLORS['new-users'],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: CHART_COLORS['new-users'],
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        yAxisID: 'first-y-axis',

        data: getMultiChartNewSelectedData(),
      },
      {
        label: 'Usuarios Recurrentes',
        fill: false,
        lineTension: 0.1,
        backgroundColor: CHART_COLORS['returned-users'],
        borderColor: CHART_COLORS['returned-users'],
        borderWidth: 2,
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: CHART_COLORS['returned-users'],
        pointBackgroundColor: CHART_COLORS['returned-users'],
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: CHART_COLORS['returned-users'],
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 5,
        pointHitRadius: 10,
        yAxisID: 'first-y-axis',

        data: getMultiChartReturningSelectedData(),
      }]
    )
  }

  const dataLine = {
    labels: getMultiChartSelectedDate(),
    datasets: getMultiChartSelectedSets(),
  }

  const optionsLine = {
    scales: {
      yAxes: [{
        id: 'first-y-axis',
        type: 'linear',
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          callback: (label: any) => {
            return numberFormat(label);
          }
        },
        gridLines: {
          drawBorder: false
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },
    legend: {
      display: true,
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },

    tooltips: {
      label: 'generalTrafficUsersLineChart',
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          return numberFormat(tooltipItem.value);
        }
      },
    },

    pointDot: false
  }
  
  const lineChartHeaderSelectOptions: ICardSelectOptions = {
    defaultValue: '',
    values: [
      {
        label: SELECT_OPT_PERIODICITY['daily'],
        value: ''
      },
      {
        label: SELECT_OPT_PERIODICITY['weekly'],
        value: 'week'
      },
      {
        label: SELECT_OPT_PERIODICITY['monthly'],
        value: 'month'
      }
    ]
  }

  const lineChartCardHeaderOptions: ICardHeaderSelect = {
    options: lineChartHeaderSelectOptions,
    leftText: 'Periodicidad',
    callback: (data: ICardSelectInput) => {
      formatAndSetNewTime(data);
    }
  }


  const formatAndSetNewTime = (data: ICardSelectInput) => {
    let resp;
    switch (data.value) {
      case 'week':
        resp = {
          start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'week'
        }
        break;
      case 'month':
        resp = {
          start_date: moment().subtract(1, 'years').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'month'
        }
        break;
      default:
        resp = {
          start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: ''
        }
        break;
    }
    setMultiChartLimitDates(resp);
    setLabelCards(SELECT_PERIODICITY[data.value as string]);
  }

  //Load first chart multiple data
  const loadTrafficData = async () => {
    
    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=' + multiChartLimitDates.start_date,
      'endDate=' + multiChartLimitDates.end_date,
      'type=' + multiChartLimitDates.type
    ];
    
    
    const res = await getTrafficData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded1 ? () => {} : general.setLoading,
      params: params
    });
    
    setMultiChartData(res);
    setChartWasLoaded1(true);
  }


  useEffect(() => {
    if (general.currentCustomer.town.name)
      loadTrafficData();
  }, [
    // For multichart data loading
    general.currentCustomer,
    multiChartLimitDates
  ])
  ////////////////////////////////////////////////////////////////
  ///////////////////////// END MULTICHART ///////////////////////
  ////////////////////////////////////////////////////////////////






























  ////////////////////////////////////////////////////////////////
  ////////////////////// USERS PIE CHART /////////////////////////
  ////////////////////////////////////////////////////////////////
  const [usersPieData, setUsersPieData] = useState();

  const dataPie = {
    labels: [
      'Nuevo',
      'Recurrente'
    ],
    datasets: [{
      data: [getCardChartsNewUsers(), getCardChartsReturningUsers()],
      backgroundColor: [
        CHART_COLORS['total-users'],
        CHART_COLORS['new-users']
      ],
      hoverBackgroundColor: [
        CHART_COLORS['total-users'],
        CHART_COLORS['new-users']
      ]
    }]
  };

  const optionsPie = {
    scales: {
      yAxes: [{
        angleLines: {
          display: false
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }],
      xAxes: [{
        angleLines: {
          display: false
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }]
    },
    legend: {
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        padding: 30,
        fontSize: 14
      }
    },
    pointDot: false
  }

  ////////////////////////////////////////////////////////////////
  ////////////////////// USERS PIE CHART /////////////////////////
  ////////////////////////////////////////////////////////////////


  








































  ////////////////////////////////////////////////////////////////
  ///////////////////////// WOLRD MAP ////////////////////////////
  ////////////////////////////////////////////////////////////////
  const [worldMap, setWorldMap] = useState();
  const [tableWorldMap, setTableWorldMap] = useState();
  const [showWorldTable, setShowWorldTable] = useState(true);


  // for pagination
  const [pageWorldMapData, setPageWorldMapData] = useState<number>(1);
  const [totalPageWorldMapData, setTotalPageWorldMapData] = useState<number>(1);
  const [reloadItemsWorldMap, setReloadItemsWorldMap] = useState<boolean>(true);
  
  const changePageWolrdMapTable = (page: number) => {
    setPageWorldMapData(page);
    setReloadItemsWorldMap(true);
  }

  const populateMapData = (countries: ICountryInfo[]) => {

    const fillMapCountry = (countrie: ICountryInfo) => {
      const countryElem = document.getElementById(countrie.country);
    
      if (!countryElem) return;


      let percentage = countrie.users.percentage / 100;

      if (percentage < 0.1)
        percentage = 0.1

      countryElem.style.fill = CHART_COLORS['total-users']; // Move to const
      countryElem.style.fillOpacity = String(percentage);
      //countryElem.style.fill = getColor(countrie.users.percentage / 100);
    }
    
    const generateTooltip = (countrie: ICountryInfo) => {
      
      const tooltip: ReactElement = (
        <ReactTooltip id='Brazil-B' className='whiteClass margin-left' effect='solid'>
          <span>Usuarios: 1000</span>
        </ReactTooltip>
      )

      if (countrie.country == 'Brazil')
        ReactDOM.render(tooltip, document.getElementById(countrie.country));
       
    }
    
    countries.map((countrie: ICountryInfo, index: number) => {
      fillMapCountry(countrie);
      //generateTooltip(countrie);        
    })

  }

  const mapCardRadioOptions: ICardFooterRadio = {
    label: 'Mapa',
    name: 'map-select',
    selected: true,
    callback: (ev: any) => {setShowWorldTable(ev); setPageWorldMapData(1); setReloadItemsWorldMap(true); }
  }

  const getWolrdTableRows = ():iRow[]  => {
    let rowsWorld: iRow[] = [];
    if (tableWorldMap)
      tableWorldMap.map( (value: ICountryInfo) => {
        rowsWorld.push({
          country: {
            type: 'text',
            value: value.country
          },
          users: {
            type: 'text',
            value: value.users.count
          }
        })
      })


    return rowsWorld;

  }
  const columnsWorldTable = ['País', 'Número de Usuarios'];
  const keyRowsWorldTable = ['country', 'users'];


  const initWorldMapData = async () => {
    try{
      const mapContainer = Snap('#map-container');
     

      if(mapContainer)
        await Snap.load(images.World, (snap: Snap.Element) => {
          setWorldMap(snap);
          mapContainer.append(snap);
        });    
      
      
      const params = [
        'towns='+general.currentCustomer.town.name,
        'startDate=2019-11-01',
        'endDate='+moment().format('YYYY-MM-DD')
      ];
    
      // This is for change number of elements when get, bewteen world map and table 
      if (showWorldTable) 
        params.push('totalPages=300');
      else {
        let nexTokenNumber = (pageWorldMapData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico
        
        params.push('nextPage=' + nexTokenNumber);
      }

      const res = await getWorldMapData({
        loader: true,
        //setLoading: general.setLoading,
        setLoading: !setChartWasLoaded2 ? () => {} : general.setLoading,
        params: params    
      });  

      const data:ICountriesBackResp = res[0][general.currentCustomer.town.name];

      setTotalPageWorldMapData(data.pagination.total_pages);
      setTableWorldMap(data.countries);
      populateMapData(data.countries);
      
      setChartWasLoaded2(true);
      
      
    } catch (e) {
      
    }

  }

  
  useEffect(
    () => {
      if (general.currentCustomer.town.name && reloadItemsWorldMap) {
        initWorldMapData();
        setReloadItemsWorldMap(false);
      }
    }, [general.currentCustomer, showWorldTable, reloadItemsWorldMap]
  )
  ////////////////////////////////////////////////////////////////
  /////////////////////// END WOLRD MAP //////////////////////////
  ////////////////////////////////////////////////////////////////













  

  



  ////////////////////////////////////////////////////////////////
  //////////////////////// LANGUAGE CHART ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface ILanguageChartDataHuman {
    count: string;
    language: string;
  }

  interface ICategoriesChartPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ILanguageChartData {
    languages: ILanguageChartDataHuman[];
    pagination: ICategoriesChartPagination;
  }

  interface ILanguageDataChartObject {
    [key: string] : ILanguageChartData;
  }

  interface ILanguageDataChart {
    [key: number]: ILanguageDataChartObject
  }

  const initLanguageDataDefaultValue = (): ILanguageDataChart => {
    return [
      {[general.currentCustomer.town.name] : {
        languages: [],
        pagination: {
          next_page_token: "",
          total: 0,
          total_pages: 0
        }
      }}
    ]
  }
    
  const [languageTableData, setLanguageTableData] = useState<ILanguageDataChart>(initLanguageDataDefaultValue());

  // for pagination
  const [pageLanguageTableData, setPageLanguageTableData] = useState<number>(1);
  const [totalPageLanguageTableData, setTotalPageLanguageTableData] = useState<number>(1);
  const [reloadItemsLanguageTable, setReloadItemsLanguageTable] = useState<boolean>(true);
  
  const changePageLanguageTable = (page: number) => {
    setPageLanguageTableData(page);
    setReloadItemsLanguageTable(true);
  }

  
  const getLanguageRows = ():iRow[] => {
    
    if (!languageTableData[0])
      return [];

    if (!languageTableData[0][general.currentCustomer.town.name]) 
      return [];

    const currentTownData = languageTableData[0][general.currentCustomer.town.name];
    let categoriesRow: iRow[] = [];
    
    currentTownData.languages.map( (value: ILanguageChartDataHuman) => {
      categoriesRow.push({
        language: {
          value: value.language,
          type: 'text'
        },
        views: {
          value: numberFormat(Number(value.count)),
          type: 'text'
        }
      })
    })

    return categoriesRow;
  }

  const columns = ['Idioma', 'Visitas'];

  const keyRows = ['language', 'views'];

  //Load chart data
  const loadLanguageTableData = async () => {    
    let nexTokenNumber = (pageLanguageTableData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns='+general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate='+moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res = await getLanguagesChartData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded3 ? () => {} : general.setLoading,
      params: params    
    });

    setLanguageTableData(res);
    setTotalPageLanguageTableData(res[0] ? res[0][general.currentCustomer.town.name].pagination.total_pages : '1');
    setChartWasLoaded3(true);
    
  }


  useEffect( () => {
    if (general.currentCustomer.town.name && reloadItemsLanguageTable) {
      loadLanguageTableData();
      setReloadItemsLanguageTable(false);
    }
  }, [general.currentCustomer, reloadItemsLanguageTable])  


  ////////////////////////////////////////////////////////////////
  ///////////////////// END LANGUAGE CHART ///////////////////////
  ////////////////////////////////////////////////////////////////













  // Detect if show or not "No data" screen on cardInfo
  const detectIfMultichartChartHaveData = (): boolean => {
    if (multiChartData[0] && multiChartData[0][general.currentCustomer.town.name])
      return multiChartData[0][general.currentCustomer.town.name].dates.length == 0 ? true : false

    return false;
  }

  // End detect










  


  // Get third party components
  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikLayoutMain,
  } = uik;


  return (
    <GeneralReportsContainer className='page'>

      <TopBar 
        textButton="Nueva campaña" 
        handleButton={() => window.open("https://espaafascinante.typeform.com/to/pUZ6h5JG")}
      >
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportGeneral} alt="Icono de Reporte general" />
          </div>
          <UikTopBarTitle>
            Visión General
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>

      <UikLayoutMain className={'wrapper content'}>
        {/* 

        <div id='report-circle-chart'>
        
        </div>

        <div id='map-container'></div> */}
        <div className='grid general-report-grid'>

          <div className='col-6' id='total-users'>
            <CardInfoSimple
              title='USUARIOS TOTALES'
              value={getCardChartsTotalUsers()}
              footText=''
              image={images.IconCardReportDecoartive}
              statusHeaderText={labelCards}
              imagePosition='bottom'
            ></CardInfoSimple>
          </div>
          <div className='col-3' id='new-users'>
            <CardInfoSimple
              title='NUEVOS USUARIOS'
              value={getCardChartsNewUsers()}
              footText=''
              image={images.IconCardReportDecoartive}
              statusHeaderText={labelCards}
            ></CardInfoSimple>
          </div>
          <div className='col-3' id='re-users'>
            <CardInfoSimple
              title='USUARIOS RECURRENTES'
              value={getCardChartsReturningUsers()}
              footText=''
              image={images.IconCardReportDecoartive}
              statusHeaderText={labelCards}
            ></CardInfoSimple>
          </div>
          

          <div id='report-line-chart' className='col-9'>
            <CardInfo
              headerTitle='Tráfico de Usuarios'
              headerSelect={lineChartCardHeaderOptions}
              statusHeaderText={labelCards}
              
              // footerSelect={lineChartCardFooterOptions}
              height='365'
              expand={true}
              tooltipText={TOOLTIPS_TEXT['trafico-de-usuarios']}
              contentClass='card-content-line-chart general'
              showDisableScreen={detectIfMultichartChartHaveData()}
              showDisableScreenText={'No hay datos suficientes para cargar la gráfica.'}
              disableScreenClass={'middle-screen'}
            >
              <Line height={80} data={dataLine} options={optionsLine}></Line>
            </CardInfo>
          </div>

          <div id='report-circle-chart' className='col-3'>
            <CardInfo
              headerTitle='Tipo de usuario'
              height='350'
              contentClass={'justifyc-content-flex'}
              tooltipText={TOOLTIPS_TEXT['tipo-de-usuario']}
              showDisableScreen={detectIfMultichartChartHaveData()}
              showDisableScreenText={'No hay datos suficientes para cargar la gráfica.'}
              disableScreenClass={'middle-screen'}
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
            >
              <div id='report-circle-chart-content'>
                <Pie width={250} height={250} data={dataPie} options={optionsPie}></Pie>
              </div>
            </CardInfo>
          </div>
          
          <div id='worldmap-card-info' className='col-9'>
            <CardInfo
              headerTitle='Desde dónde te visitan'
              // headerSelect={mapCardHeaderOptions}
              // footerSelect={mapCardFooterOptions}
              footerRadio={mapCardRadioOptions}
              height='546'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              expand={showWorldTable}
              contentClass={'justifyc-content-flex'}
              tooltipText={TOOLTIPS_TEXT['desde-donde-te-visitan']}
              flexFlow='column'
            >
              {
                (!showWorldTable) ?
                  (
                    <>
                      <Table
                        rows={getWolrdTableRows()}
                        columns={columnsWorldTable}
                        keyRows={keyRowsWorldTable}
                        handleOrder={() => { }}
                      />
                      <div className="container-pagination">
                        <Pagination page={pageWorldMapData} limit={totalPageWorldMapData} callback={(page: number) => changePageWolrdMapTable(page)} />
                      </div>
                    </>
                  )
                :
                  (
                    <>
                      <div id='map-container'></div>
                      <div id='map-indicator-bar-container'>
                        <MapBarIndicator color='#124526'/>
                      </div>
                    </>
                  )
              }

            </CardInfo>
          </div>
          
          <div id='report-language-table' className='col-3'>
            <CardInfo
                headerTitle='Idioma'
                height='546'
                tooltipText={TOOLTIPS_TEXT['grafico-idioma']}
                flexFlow='column'
                statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              >
              <>
                <Table
                  rows={getLanguageRows()}
                  columns={columns}
                  keyRows={keyRows}
                  handleOrder={() => { }}
                />
                <div className="container-pagination">
                  <Pagination page={pageLanguageTableData} limit={totalPageLanguageTableData} callback={(page: number) => changePageLanguageTable(page)} />
                </div>
              </>
            </CardInfo>
          </div>

        </div>

      </UikLayoutMain>

    </GeneralReportsContainer>
  );
}


export default withRouter(GeneralReports);
