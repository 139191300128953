import React, { useState, useContext, useEffect } from 'react'

import GeneralContext from '../../../context/general.context'
import { iSettingsUser, iSettingsPassword, iSettingsGeneric } from '../../../models/users.models';
import { RouteComponentProps, withRouter } from 'react-router';
import { editPassword } from '../../../services/user.services';

import { iModal } from '../../../models/general.model';
import InfoModal from '../../../components/InfoModal/InfoModal';
import Form from '../../../components/Form/Form';


// Style
import { editCustomer, getCurrentCustomer, unsubscribeCustomer } from '../../../services/customer.services';
import { TOOLTIPS_TEXT } from '../../../constants/general';
import SimpleAlert from '../../../components/SimpleAlert/SimpleAlert';

interface RouteParams {
  id: string
}

interface iSettingsProps extends RouteComponentProps<RouteParams> { }

const SettingsBasicInfo: React.FC<iSettingsProps> = ({ history, match }) => {
  const general = useContext(GeneralContext);

  const initUserValue = (): iSettingsUser => ({
    username: {
      value: general.currentCustomer.username,
      error: false,
      errorCode: '',
      required: true,
      type: 'email',
      placeholder: 'Nombre de usuario',
      render: 'input',
      className: 'col-12',
      disabled: true
    }
  })

  const initDescriptionValue = () => ({
    value: '',
    error: false,
    errorCode: '',
    required: true,
    type: 'text',
    placeholder: 'Indicanos el nuevo valor que deseas utilizar',
    render: 'textarea',
    className: 'col-12',
    handleChange: (ev: any) => {handleDescriptionInputChange(ev)}
  })

  const initRadioValue = () => (
    {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      options:[
        {name: 'townRadio', value: 'townName', label: 'Nombre del municipio'},
        {name: 'townRadio', value: 'townCode', label: 'Comunidad Autónoma'},
        {name: 'townRadio', value: 'townProvince', label: 'Provincia'},
        {name: 'townRadio', value: 'nif', label: 'CIF del municipio'},
        {name: 'townRadio', value: 'townMinPoblation', label: 'Minimo de población'},
        {name: 'townRadio', value: 'townMaxPoblation', label: 'Máximo de población'},
        {name: 'townRadio', value: 'contactPostalCode', label: 'Código Postal'},
        {name: 'townRadio', value: 'street', label: 'Calle'}
      ],
      placeholder: 'Indicanos el nuevo valor que deseas utilizar',
      render: 'radio',
      className: 'col-12',
      //handleChange: (ev: any) => {setDescriotionInput({...descriptionInput, value: ev})}
      handleChange: (ev: any) => {handleRadioChange(ev.target.value as string) }
    }
  )

  const handleRadioChange = (value:string) => {
    radiosInput['value'] = value;
    setRadiosInput({...radiosInput})
  }

  const handleDescriptionInputChange = (value:string) => {
    descriptionInput['value'] = value;
    setDescriotionInput({...descriptionInput})
  }

  const [user, setUser] = useState<iSettingsUser>(initUserValue());

  const [town, setTown] = useState<iSettingsGeneric>({
    name: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'Nombre del municipio',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
    community: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'Comunidad',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
    province: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'Provincia',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
    nif:{
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'CIF',
      render: 'input',
      className: 'col-6',
      disabled: true      
    },
    minPopulation: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'Mínimo de población',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
    maxPopulation: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'Máximo de población',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
    postalCode: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      placeholder: 'Código Postal',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
    street: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      placeholder: 'Calle',
      render: 'input',
      className: 'col-6',
      disabled: true
    },
  });

  const [contactPerson, setContactPerson] = useState<iSettingsGeneric>({
    name: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      placeholder: 'Nombre',
      render: 'input',
      className: 'col-12'
    },
    phone: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'number',
      placeholder: 'Teléfono',
      render: 'input',
      className: 'col-12'
    }
  });

  const [reportEmails, setreportEmails] = useState<iSettingsGeneric>({
    firstEmail: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      disabled: true,
      type: 'text',
      placeholder: 'Email 01',
      render: 'input',
      className: 'col-12'
    },
    secondEmail: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      placeholder: 'Email 02',
      render: 'input',
      className: 'col-12'
    },
    lastEmail: {
      value: '',
      error: false,
      errorCode: '',
      required: false,
      type: 'text',
      placeholder: 'Email 03',
      render: 'input',
      className: 'col-12'
    }
  });

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    inputs: [],
    title: '',
    className: ''
  });

  const [descriptionInput, setDescriotionInput] = useState(initDescriptionValue());
  const [radiosInput, setRadiosInput] = useState(initRadioValue());


  const openModalUpdateTown = () => {
    setModal({
      code: '',
      text: 'Puede solicitar la modificación de cualquier de los siguientes datos al administrador',
      button: 'Enviar solicitud',
      handleAccept: () => {requestUpdateTown()},
      show: true,
      alert: '',
      inputs: [radiosInput, descriptionInput],
      title: 'Solicitud de Modificación',
      className: 'success'
    });
  }

  const openModalUpdateUser = () => {
    setModal({
      code: '',
      text: 'Por favor indícanos cual quieres que sea tu nuevo email',
      button: 'Enviar solicitud',
      handleAccept: requestUpdateUser,
      show: true,
      alert: '',
      inputs: [descriptionInput],
      title: 'Solicitud de Modificación',
      className: 'success'
    });
  }

  const requestUpdateTown = async () => {
    const data:any = {};
    data[radiosInput.value] = descriptionInput.value;

    try {
      await editCustomer({
        data: data,
        id: localStorage.getItem('idUser'),
        loader: true,
        setLoading: general.setLoading,
      });
      setModal({
        code: 'ok',
        text: 'Su solicitud se ha enviado correctamente',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        inputs: [],
        className: 'success-icon'
      });
    } catch (err) {
      setModal({
        code: 'ok',
        text: 'Se ha procedido a realizar la solicitud.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        inputs: [],
        className: ''
      });
    }
  }

  const updateContactPerson = async (dataForm: any) => {
    try {

      await editCustomer({
        id: localStorage.getItem('idUser'), 
        data: {
          "contactName": dataForm.name.value,
          "contactPhoneNumber": dataForm.phone.value
        }, 
        loader: true, 
        setLoading: general.setLoading 
      });

      setModal({
        code: '',
        text: 'Se ha editado la información de la persona de contacto exitosamente',
        handleAccept: refreshCurrentCustomer,
        show: true,
        alert: '',
        inputs: [],
        title: 'Modificación exitosa',
        className: 'success'
      });
      
      refreshCurrentCustomer();
    } catch (e) {
      
      setModal({
        code: '',
        text: 'Ocurrió un error al tratar de actualizar la persona de contacto',
        handleAccept: () => {},
        show: true,
        alert: '',
        inputs: [],
        title: 'Error al editar',
        className: 'error'
      });
    }
  }

  const requestUpdateUser = async () => {

    const data = {
      username: descriptionInput.value
    }
    try {
      await editCustomer({
        data,
        id: localStorage.getItem('idUser'),
        loader: true,
        setLoading: general.setLoading,
      });
      setModal({
        code: 'ok',
        text: 'Su solicitud se ha enviado correctamente',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        inputs: [],
        className: 'success-icon'
      });
    } catch (err) {
      setModal({
        code: 'ok',
        text: 'Se ha procedido a realizar la solicitud.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        inputs: [],
        className: ''
      });
    }
  }



  const refreshCurrentCustomer = async () => {
    const currentCustomer = await getCurrentCustomer(true);
    general.setCurrentCustomer(currentCustomer);
  }

  const handleSaveReportsEmails = async () => {
    const data: string[] = [];

    if (reportEmails.firstEmail.value) {
      data.push(reportEmails.firstEmail.value);
    }

    if (reportEmails.secondEmail.value) {
      data.push(reportEmails.secondEmail.value);
    }

    if (reportEmails.lastEmail.value) {
      data.push(reportEmails.lastEmail.value);
    }

    try {

      await editCustomer({
        id: localStorage.getItem('idUser'), 
        data: {
          "reportMails": data
        }, 
        loader: true, 
        setLoading: general.setLoading 
      });

      setModal({
        code: '200',
        text: 'Email de reporte guardado con éxito',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Envío exitoso',
        alert: '',
        inputs: [],
        className: 'success'
      });
      refreshCurrentCustomer();
    } catch (err) {

      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        inputs: [],
        className: 'warning'
      });

    }
  }

  const handleCancelSubscription = () => {
    setModal({
      handleClose: closeModal,
      code: '',
      button: 'Si, cancelar',
      handleAccept: cancelCustomerSubscription,
      text: 'Al cancelar su suscripción, luego de que terminé su actual período, dejará de disfrtuar de nuestros beneficios. ¿Deseas continuar?',
      show: true,
      title: 'Solicitar la baja de suscripción',
      alert: '',
      className: 'success'
    });
  }

  const cancelCustomerSubscription = async() => {
    try {
      await unsubscribeCustomer({
        id: localStorage.getItem('idUser'),
        loader: true, 
        setLoading: general.setLoading       
      });

      setModal({
        handleClose: closeModal,
        code: 'ok',
        text: 'Se ha enviado su solicitud correctamente',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        className: 'success'
      });
      refreshCurrentCustomer();      
    } catch (err) {
      setModal({
        handleClose: closeModal,
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'warning'
      });
    }
  }

  const closeModal = () => {
    // Clean inputs
    handleRadioChange('');
    handleDescriptionInputChange('');
    
    // Set Modal
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      inputs: [],
      className: ''
    })
  }

  useEffect( () => {
    if (general.currentCustomer.username !== "") {
      

      // Basic customer data set
      user.username.value = general.currentCustomer.username;
      // user.email.value = general.currentCustomer.username;
      setUser({...user});

      if (general.currentCustomer.town) {
        // Basic town data set (here we have an error)
        town.name.value = general.currentCustomer.town.name;
        town.province.value = general.currentCustomer.town.province;
        town.community.value = general.currentCustomer.town.community;
        town.nif.value = general.currentCustomer.nif;
        //town.code.value = general.currentCustomer.town.postalCode;
        
        town.street.value = general.currentCustomer.street;
        town.minPopulation.value = general.currentCustomer.town.minPoblation;
        town.maxPopulation.value = general.currentCustomer.town.maxPoblation;
        setTown({...town});
      }

      if (general.currentCustomer.contact) {
        // Contact person set
        contactPerson.name.value = general.currentCustomer.contact.name;
        contactPerson.phone.value = general.currentCustomer.contact.phoneNumber;
        //contactPerson.postalCode.value = general.currentCustomer.contact.postalCode;
        town.postalCode.value = general.currentCustomer.contact.postalCode;
        setContactPerson({...contactPerson});
      }

      // Reports emails
      reportEmails.firstEmail.value = general.currentCustomer.username;
      reportEmails.secondEmail.value = general.currentCustomer.reportMails[1] ? general.currentCustomer.reportMails[1] : ''
      reportEmails.lastEmail.value = general.currentCustomer.reportMails[2] ? general.currentCustomer.reportMails[2] : ''
      setreportEmails({...reportEmails});
    }

  },[general.currentCustomer]);

  return (
    <>
      <div>
        <Form
          titleForm='Correo electrónico'
          buttonText='Solicitar cambios'
          handleAccept={openModalUpdateUser}
          dataForm={user}
          tooltipText={TOOLTIPS_TEXT['informacion-basica']}
        />

        <Form
          titleForm='Municipio'
          buttonText='Solicitar cambios'
          handleAccept={openModalUpdateTown}
          dataForm={town}
          tooltipText={TOOLTIPS_TEXT['informacion-minicipio']}
        />

        <Form
          titleForm='Persona de contacto'
          buttonText='Guardar cambios'
          handleAccept={updateContactPerson}
          dataForm={contactPerson}
          tooltipText={TOOLTIPS_TEXT['informacion-contacto']}
        />


        <Form
          titleForm='Envío de informes'
          buttonText='Guardar emails'
          handleAccept={handleSaveReportsEmails}
          dataForm={reportEmails}
          tooltipText={TOOLTIPS_TEXT['envio-informes']}
        />


        {/* <Form
            titleForm="Cambio de contraseña"
            buttonText="Guardar"
            handleAccept={handleAcceptPassword}
            dataForm={changePassword}
          /> */}
        <InfoModal
          className={modal.className}
          onClickAccept={() => {modal.handleAccept()}}
          mainText={modal.text}
          titleText={modal.title}
          alertText={modal.alert}
          buttonText={modal.button}
          active={modal.show}
          inputs={modal.inputs}
          close={() => closeModal()}
        />
      </div>

      <SimpleAlert
        title='Dar de baja la suscripción'
        description='España Fascinante dará de baja la suscripción que tiene contratada'
        textButton='Dar de baja'
        handleClick={handleCancelSubscription}
      />
    </>
  );
}

export default withRouter(SettingsBasicInfo);

