import styled, { css } from 'styled-components/macro';

const CardInfoSimpleThreeContainer = styled.div<{height: string | undefined}>`
  position: relative;
  padding: 15px 20px 20px 20px;
  background-color: #F5EDDA;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  ${(props) => props.height ? (css`
    min-height: ${props.height}px
  `) : 
    ''
  }

  p, h2, h5 {
    text-align: center;
  }

  .card-simple-three-header {
    p {
      color: #3E3F42;
    }
  }

  .card-simple-three-value {
    
    h2 {
      color: #248489;
      font-weight: bold;
      font-size: 1.4rem;
    }
  }

  .card-simple-three-footer {

    h5 {
      cursor: pointer;
      color: #DE4B4B;
      text-decoration: underline;
    }
  }

  .ranking-row {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin: 0;
      width: 17px;
      margin-left: 10px;
    }
  }

`

export default CardInfoSimpleThreeContainer;