import React, { useState, useContext, SyntheticEvent } from 'react'

import * as uik from '../../utils/third-party/@uik';

import { iDataSetPassword } from '../../models/login.model';
import { setPassword } from '../../services/customer.services';

import GeneralContext from '../../context/general.context'
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import { withRouter, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import InfoModal from '../../components/InfoModal/InfoModal';
import { images } from '../../assets/images';
import IconComponent from '../../components/Icon/Icon';

export interface iForgotPasswordProps extends RouteComponentProps {
  token: string;
}

const SetPassword: React.FC<iForgotPasswordProps> = ({ history, token }) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState<boolean>(false);

  const [data, setData] = useState<iDataSetPassword>({
    password: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      placeholder:'Contraseña',
      type: 'password'
    },
    repeatPassword: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      placeholder:'Repite tu contraseña',
      type: 'password'      
    }
  });
  
  const [modal, setModal] = useState<boolean>(false);

  const general = useContext(GeneralContext)


  const validateForm = (): boolean => {
    let isOk = true;

    Object.keys(data).forEach((key: string) => {
      if (data[key].required && data[key].value === '') {
        data[key].error = true;
        data[key].errorCode = 'required';
        isOk = false;
      } else if (data.password.value !== data.repeatPassword.value) {
        data[key].errorCode = 'repeat-password';
        data[key].error = true;
        isOk = false;
      } else {
        data[key].errorCode = '';
        data[key].error = false;
      }
    })

    setData({ ...data });
    return isOk;
  }

  const handleSetPassword = async () => {
    const isOk = validateForm();
    if (isOk) {
      const dataSend = {
        newPassword: data.password.value,
        verifyPassword: data.repeatPassword.value,
        token: token
      }
      try {
        const res = await setPassword({
          loader: true,
          setLoading: general.setLoading,
          data: dataSend,
        })
        setModal(true);
      } catch (err) {
        data.password.error = true;
        data.password.errorCode = 'set-password-error';
        setData({ ...data });
      }

    }
  }

  const handleCloseModal = () => {
    setModal(false);
    history.push('/');
  }

  const handleChange = (e: SyntheticEvent) => {

    const target = e.target as HTMLInputElement;
    data[target.name].value = target.value;
    setData({ ...data });
  }

  const { UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton,
    Uikon
  } = uik;

  return (
    <div className="pageWrapper">
      <UikWidget
        className="widgetWrapper"
      >
        <div className="content">
          <UikWidgetContent className="left">
            <UikHeadline>
              Hora de configurar tu contraseña
          </UikHeadline>
            <UikHeadlineDesc>
              Por favor introduce una contraseña que te sea fácil de recordar
          </UikHeadlineDesc>
            <form className="login" onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
              <UikFormInputGroup>
                <UikInput
                  label="Nueva contraseña"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="******"
                  errorMessage={ERROR_MESSAGES[data.password.errorCode]}
                  value={data.password.value}
                  onChange={handleChange}
                  icon={(
                    <IconComponent
                      name="IconEyeGray"
                      onClick={() => setShowPassword(!showPasswordRepeat)}
                      className={[showPassword ? 'active-icon' : 'inactive-icon', 'pointer'].join(' ')}
                    />
                  )}
                  iconPosition="right"
                />
                <UikInput
                  label="Repite tu contraseña"
                  name="repeatPassword"
                  type={showPasswordRepeat ? "text" : "password"}
                  placeholder="******"
                  errorMessage={ERROR_MESSAGES[data.repeatPassword.errorCode]}
                  value={data.repeatPassword.value}
                  onChange={handleChange}
                  icon={(
                      <IconComponent
                        name="IconEyeGray"
                        onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                        className={[showPasswordRepeat ? 'active-icon' : '', 'pointer'].join(' ')}
                      />
                    )}
                  iconPosition="right"
                />                
              </UikFormInputGroup>
              <UikDivider margin />
              <UikButton
                className="btnAction"
                type="submit"
                success
                onClick={handleSetPassword}
              >
                Configurar contraseña
          </UikButton>
            </form>
            <div className="login-footer">
              <NavLink to="/">
                Iniciar sesión
            </NavLink>
            </div>
          </UikWidgetContent>
          <div className="right">
            <div className='text-image-container'>
              <img src={images.IconWorldLine} alt="Global network"/>
              <h3 className="headline">
                Panel de Gestión
              </h3>
            </div>
            <p className="desc">
              España Fascinante
            </p>
          </div>
        </div>
      </UikWidget>
      <p>
        Patrocinadores
      </p>
      <div className='sponsors-container'>
        <img src={images.ImagePatrocinante} alt='Lynx Patrocinante'></img>
      </div>

      <InfoModal
        className={"success"}
        titleText={"Contraseña configurada"}
        // alertText={"Esta acción no se puede deshacer."}
        mainText={"Ya puedes ingresar en la plataforma y empezar a disfrutar los servicios"}
        active={modal}
        close={handleCloseModal}
      />
    </div>
  )
}

export default withRouter(SetPassword);

