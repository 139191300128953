import { post, get, put, deleteReq, postFormData, putFormData } from "./general.services";


export const createCustomer = async (args: any) => {
  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "customers"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const profileCustomer = async (args: any) => {
  try {
    const res = await postFormData({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/profile-image`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editCustomer = async (args: any) => {

  try {
    const res = await put({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editCustomerSubscription = async (args: any) => {

  try {
    const res = await put({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/subscription`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}


export const getCustomers = async (args: any) => {

  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `customers${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export const getOneCustomer = async (args: any) => {

  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const deleteCustomer = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `customers/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getCurrentCustomer = async (reload?: boolean) => {
  try {
    //const currentCustomer = await localStorage.getItem('currentCustomer');
    // if (!currentCustomer || reload) {
      const currentCustomerId = await localStorage.getItem('idUser');
      const args = {
        setLoading: () => {},
        data: {},
        id: currentCustomerId
      }
      
      const currentCustomer = await getOneCustomer(args);
      await localStorage.removeItem('currentCustomer');
      await localStorage.setItem('currentCustomer', JSON.stringify(currentCustomer));
      

      return currentCustomer;
    // }
  
    return JSON.parse(currentCustomer);
  }
  catch (err) {
    throw err;
  }
}

export const unsubscribeCustomer = async (args: any) => {

  try {
    const res = await put({
      loader: true,
      setLoading: args.setLoading,
      data: {
        "subscriptionId": null
      },
      endpoint: `customers/${args.id}/subscription`
    });

    return res;
  }
  catch (err) {
    throw err;
  }
}

export const uploadImageCustomer = async (args: any) => {

  try {
    const res = await putFormData({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/profile-image`
    });

    return res;
  }
  catch (err) {
    throw err;
  }
}

export const uploadMultimediaCustomer = async (args: any) => {
  try {
    const currentCustomer = await localStorage.getItem('idUser');

    const res = await putFormData({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${currentCustomer}/multimedia`
    });

    return res;
  }
  catch (err) {
    throw err;
  }
}

export const deleteMultimediaCustomer = async (args: any) => {
  try {
    const currentCustomer = await localStorage.getItem('idUser');

    const res = await putFormData({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${currentCustomer}/multimedia/${args.key}`
    });

    return res;
  }
  catch (err) {
    throw err;
  }
}

export const setPassword = async (args: any) => {

  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "customers/password"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getCurrentRanking = async (args: any) => {
  
  try {
    const currentCustomerId = await localStorage.getItem('idUser');
    
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${currentCustomerId}/ranking`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}
