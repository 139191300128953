import styled from 'styled-components/macro';

const CardInfoSimpleTwoContainer = styled.div`
  position: relative;
  padding: 15px 20px 20px 20px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  overflow: hidden;

  .card-simple-header {
    display: flex;
    justify-content: space-between;
  }

  p {
    font-size: 12px;
  }

  .card-simple-value {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-bottom: 10px;
    width: 100%;

    h1 {
      font-weight: 400;
      font-size: 48px;
      line-height: 1em;
      margin-top: 10px;
    }

    p {
      margin-top: 0px;
    }
  }

  .card-simple-footer {
    h5 {
      text-align: center;
      color: #248489;
      font-weight: bold;
      text-decoration: underline;
    }
  }  

  .image-container {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 1150px) {
    .card-simple-value {
      h1 {
        font-size: 38px;
      }
    }
  }
`

export default CardInfoSimpleTwoContainer;