import React from 'react';
import * as uik from '../../utils/third-party/@uik';

// Style
import {NotFoundStyle} from './NotFound-style';
import { images } from '../../assets/images';
import { RouteComponentProps, withRouter } from 'react-router-dom';


const NotFound: React.FC<RouteComponentProps> = ({history}) => {

  const { UikButton } = uik;


  const goToLogin = () => {
    history.push("/");
  }

  return (
    <NotFoundStyle>

        <div className="error" id="not-found-content">
          <div className="error__image">
            <img src={images.ImageNotFound} alt="" />
          </div>

          <div className="error__info">
            <div className="error__info__title">
              <p>Ups!</p>
            </div>
            <div className="error__info__text">
              <p>
                Las personas emigran y a veces las páginas también. Lo que estás buscando ya no se encuentra en esta
                página.
              </p>
            </div>
            <div className="error__info__button">
            <UikButton

            style={{ 'marginTop': '0px', 'marginBottom': '0px', 'borderRadius': '63px' }}
            onClick={() => goToLogin()}
            success
            >
              Volver
          </UikButton>
            </div>
          </div>
          
        </div>
     

    </NotFoundStyle>
  )
  
}

export default withRouter(NotFound);