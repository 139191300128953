import React from 'react';

// Type
import { ICardComparative } from './CardComparative-type';

// Style
import CardComparativeContainer from './CardComparative-style';


const CardComparative: React.FC<ICardComparative> = (props: ICardComparative) => {
  const { text, value, backgroundColor } = props;

  return (
    <CardComparativeContainer backgroundColor={backgroundColor}>
      <div className='card-comparative-left-text'>
        <p>{text}</p>
      </div>

      <div className='card-comparative-right-text'>
        <h2>{value}</h2>
      </div>
    </CardComparativeContainer>
  )
  
}

export default CardComparative