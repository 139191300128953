import styled, { css } from 'styled-components/macro';

const CardInfoRankingContainer = styled.div<{height: string | undefined}>`
  position: relative;
  padding: 35px 20px 20px 20px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${(props) => props.height ? (css`
    height: ${props.height}px
  `) : 
    ''
  }

  .card-ranking-backImage {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    margin: 0 auto;

    img {
      position: absolute;
      left: 25%;
    }
  }

  .card-ranking-value {
    margin-top: 15px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0px;
    }
  }

  .card-ranking-footer {
    display: flex;
    justify-content: flex-end;
  }

  h1 {
    font-weight: 400;
    font-size: 48px;
    line-height: 1em;
    margin-top: 10px;
  }

  p {
    font-size: 12px;
  }

  h5 {
    text-align: center;
    color: #248489;
    font-weight: bold;
    text-decoration: underline;
  }

`

export default CardInfoRankingContainer;