let SERVICES_URL = '';

switch (process.env.REACT_APP_ENV) {
  case 'production':
    SERVICES_URL = 'https://api.eslinx.com/';
    break;
  case 'staging':
    SERVICES_URL = 'https://backend-ef-staging.aluxion.dev/';
    break;
  default:
    SERVICES_URL = 'https://backend-ef.aluxion.dev/';
}
export {
  SERVICES_URL,
};


//export const SERVICES_URL = 'http://68.183.73.87:81/'