import styled from 'styled-components/macro';
import * as uik from '../../../utils/third-party/@uik';

const { UikContainerVertical } = uik;

const DemographicReportsContainer = styled(UikContainerVertical)`
  position: relative;  
  
  .grid {
    gap: 30px;
    
    &.demographic-grid {
      /* grid-template-areas:  "linechart circlechart"
                            "worldmap  languagetable"; */



      @media screen and (max-width: 1350px) {
        /* grid-template-columns: none; */
        grid-template-areas:  "linechart linechart"
                              "circlechart  languagetable"
                              "worldmap worldmap";

        #report-line-chart {
          grid-area: linechart;
        }

        #report-circle-chart {
          grid-area: circlechart;
        }

        #worldmap-card-info {
          grid-area: worldmap;
        }

        #report-language-table {
          grid-area: languagetable;
        }

        #report-language-table > div:first-child {
          min-height: 210px;
        }
      
      }

    }


  }

  .wrapper.content {
    margin: 15px;
  }

  #report-line-chart,
  #map-container {
    position: relative;
  }

  #map-container {
    svg {
      width: 100%;
      height: auto;
      /* max-width: 800px;
      width: 800px; */
    }
  }

  #report-line-chart {
    canvas {

    }
  }

  #worldmap-card-info {
    margin-bottom: 20px;
  }

  #report-circle-chart {
    #report-circle-chart-content {
      margin-top: 17px;
    }
  }

  #map-indicator-bar-container {
    position: absolute;
    bottom: 80px;
    left: 60px;
  }


`

export default DemographicReportsContainer;