import React from 'react';

// Type
import { ICardInfo } from './CardInfo-type';

// Style
import CardInfoContainer from './CardInfo-style';

// Constants
import { images } from '../../assets/images';

// Third Party components
import * as uik from '../../utils/third-party/@uik';
import ReactTooltip from 'react-tooltip';

const CardInfo: React.FC<ICardInfo> = (props: ICardInfo) => {
  const {
    headerTitle,
    headerCustom,
    headerSelect, 
    children, 
    footerRadio, 
    footerSelect,
    height,
    expand,
    addSpace,
    backgroundColor,
    hardStroke,
    flexFlow,
    contentClass,
    tooltipText,
    showDisableScreen,
    showDisableScreenText,
    disableScreenClass,
    statusHeaderText
  } = props;

  // Get third party components
  const { 
    UikSelect,
    UikToggle
  } = uik;


  return (
    <CardInfoContainer 
      flexFlow={flexFlow}
      height={height} 
      hardStroke={hardStroke} 
      addSpace={addSpace} 
      backgroundColor={backgroundColor} 
      className={expand ? 'expand' : 'no-expand'}
      showPadding={statusHeaderText ? true: false}
    >

      {
        (showDisableScreen) ?
          (
            <div className={'card-info-screen-disabled ' + disableScreenClass}>

              <div>{showDisableScreenText}</div>

            </div>
          )
        :
          (
            <></>
          )
      }
      

      {
        (headerSelect ||
          headerTitle ||
          headerCustom) ?

          (
            <>
            <small>{statusHeaderText}</small>
            <div className='card-header'>

              {
                (headerTitle) ?
                  (

                      <div className='card-title'>
                        <h2>{headerTitle}</h2>

                        {
                          (tooltipText) ?
                            (
                              <>
                                <img data-tip data-for={'help-icon-' + headerTitle} src={images.IconInfoGray} alt='info icon' />
                                <ReactTooltip id={'help-icon-' + headerTitle} className='whiteClass' effect='solid'>
                                  <span>{tooltipText}</span>
                                </ReactTooltip>
                              </>
                            )
                          :
                            (
                              <></>
                            )
                        }
                      </div>
                  )
                :
                  (
                    <></>
                  )
              }
      
      
              {
                (headerCustom) ?
                (
                  <div className='card-custom'>
                    {headerCustom}
                  </div>
                )
              :
                (
                  <></>
                )
              }
      
              <div className='card-options'>
                {
                  (headerSelect) ?
                    (
                      <div className='card-select-options'>
                        <p>{headerSelect.leftText}</p>
                        <UikSelect
                          className={"select"}
                          onChange={headerSelect.callback}
                          defaultValue={[headerSelect ? headerSelect.options.defaultValue: '']}
                          options={headerSelect ? headerSelect.options.values : []}
                          placeholder={headerSelect ? headerSelect.options.placeholder : ''}
                        />
                      </div>
                    )
                  :
                    (
                      <></>
                    )
                }
              </div>

              {
                (footerRadio) ?
                  (<div className='card-options'>
                    <UikToggle
                      defaultChecked={footerRadio.selected}
                      label={footerRadio.label}
                      name={footerRadio.name}
                      onChange={(ev: any)=>{footerRadio.callback(ev.target.checked)}}
                    />
                  </div>)
                :
                  (<></>)
              }
      
            </div>
            </>
          ) :

          (
            <></>
          )

      }
    
      <div className={'card-main-content ' + contentClass}> 
        {children}
      </div>
      <div className='card-footer'>
        {/* {
          (footerRadio) ?
            (<div className='card-options'>
              <UikToggle
                defaultChecked={footerRadio.selected}
                label={footerRadio.label}
                name={footerRadio.name}
                onChange={(ev: any)=>{footerRadio.callback(ev.target.checked)}}
              />
            </div>)
          :
            (<></>)
        } */}
        {
        (footerSelect) ?
          ( 
            <div className='card-options'>
              <UikSelect
                className={"select"}
                onChange={footerSelect.callback}
                defaultValue={[footerSelect ? footerSelect.options.defaultValue: '']}
                options={footerSelect ? footerSelect.options.values : []}
                placeholder={footerSelect ? footerSelect.options.placeholder : ''}
              />
            </div>
          )
        :
          (
            <></>
          )
        }

        </div>
        
    </CardInfoContainer>
  )
}

export default CardInfo