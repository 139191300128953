import React, { useContext } from 'react'
import classnames from 'classnames';

import * as uik from '../../utils/third-party/@uik';

import GeneralContext from '../../context/general.context'
import { NavLink } from 'react-router-dom';
import TopBarContainer from './TopBar-style';

// @flow
interface iTopBarProps {
  children?: JSX.Element;
  className?: boolean;
  rightEl?: JSX.Element;
  handleButton?: Function;
  textButton?: string;
  handleSearch?: Function;
  tabs?: any[];
}

const TopBar: React.FC<iTopBarProps> = ({ children, className, rightEl,
  handleButton, textButton, handleSearch, tabs }) => {

  const general = useContext(GeneralContext);
  const {
    UikTopBar,
    UikTopBarSection,
    Uikon,
    UikInput,
    UikDivider,
    UikButton,
    UikNavBurger } = uik;

  return (
    <TopBarContainer
      className={classnames("wrapper", className)}
    >
      <UikButton
        className={"menuButton"}
        onClick={() => general.setIsMenuOpen(!general.isMenuOpen)}
        transparent
      >
        <UikNavBurger isOpen={general.isMenuOpen} />
      </UikButton>
      {children}
      <UikTopBarSection className={"actions"}>
        {tabs && (
          <div className="tabs">
            {tabs.map((tab: any) => (
              <NavLink to={tab.to} exact={tab.exact ? true : false}>
                {tab.text}
              </NavLink>
            ))}
          </div>
        )}
        {handleSearch && (
          rightEl || (
            <UikInput
              icon={(
                <Uikon>
                  search_left
            </Uikon>
              )}
              placeholder="Buscar..."
            />
          )
        )}
        {textButton && handleButton && handleSearch && (
          <UikDivider
            className={"actionMobileHidden"}
            direction="vertical"
            margin
          />
        )}
        {textButton && handleButton && (
          <UikButton
            className="btnAction"
            // Component={NavLink}
            success
            onClick={handleButton}
          // to="/buildings"
          >
            {textButton}
          </UikButton>
        )}
      </UikTopBarSection>
    </TopBarContainer>
  )
}
export default TopBar;
