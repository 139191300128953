import React, { useState } from 'react';

import * as uik from '../../utils/third-party/@uik';

import { CardContainer } from './CardBig-style';
import { iSubscriptions } from '../../models/subscriptions.models';

interface iCardBigProps {
  title: string;
  textRight: string;
  textLeft: string;
  handleClick: Function;
  data: iSubscriptions[];
}

const CardBig: React.FC<iCardBigProps> = ({textRight, textLeft, handleClick, data }) => {
  const { UikInput, UikButton } = uik;

  const [bestPlan, setBestPlan] = useState('');
  const [inputPlan, setInputPlan] = useState('');

  const showBestPlan = (ev: any) => {
    setBestPlan(inputPlan);
  }

  const resetBestPlan = () => {
    setBestPlan('');
  }



  const calculateBestPlan = (userInput: string) => {
    const number = parseInt(userInput);
    if (!number) 
      return 'Dato incorrecto';
    
    const minValue = Math.min(...data.map((value:iSubscriptions) => value.minPoblation));
    const maxValue = Math.max(...data.map((value:iSubscriptions) => value.maxPoblation));

    if (number < minValue) 
      return (<small className='extra-text'>'Su municipio tiene un número menor de habitantes a los mínimos para la tarifa Small. Póngase en contacto con nosotros en <a href='mailto:linx@espanafascinante.com'>linx@espanafascinante.com</a>  para que ayudarle y ofrecerle el mejor servicio adaptado a sus necesidades'</small>);

    if (number > maxValue) 
      return (<small className='extra-text'>'Su municipio tiene un número mayor de habitantes a los máximos para la tarifa Extra Large. Póngase en contacto con nosotros en <a href='mailto:linx@espanafascinante.com'>linx@espanafascinante.com</a> para que ayudarle y ofrecerle el mejor servicio adaptado a sus necesidades'</small>);

    for (let index = 0; index < data.length; index++) {
      const value: iSubscriptions = data[index];

      if (number <= Number(value.maxPoblation))
        return value.name;
    }

    for (let index = 0; index < data.length; index++) {
      const value: iSubscriptions = data[index];

      if (number <= Number(value.maxPoblation))
        return value.name;
    }

    return data[data.length - 1].name;

  }

  return (
    <CardContainer>
      {/* <div className="card-header">
        <p>{title}</p>
      </div> */}
      
      <div className="card-body">

        <div className="card-left">
          <p>{textLeft}</p>
        </div>
        <div className="card-right" onClick={() => handleClick()}>
          <p>{textRight}</p>

          {
            bestPlan ? 
              (
                <div className="card-inline result">
                  <h2>
                    {calculateBestPlan(bestPlan)}
                  </h2>

                  <small onClick={resetBestPlan}>
                    Volver a calcular
                  </small>
                </div>
              )  
            :
              (
                <div className="card-inline">
                  <UikInput
                    // label="Correo"
                    name="username"
                    placeholder="Nº de habitantes"
                  // errorMessage={ERROR_MESSAGES[data.username.errorCode]}
                  // value={data.username.value}
                      onChange={(ev: any) => {setInputPlan(ev.target.value)}}
                  />
                  <UikButton success onClick={showBestPlan}>
                    Calcular
                  </UikButton>
                </div>
              )
          }

        </div>

      </div>

    </CardContainer>
  );
}

export default CardBig;
