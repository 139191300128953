import styled from 'styled-components/macro';
import * as uik from '../../../utils/third-party/@uik';

const { UikContainerVertical } = uik;

const RankingReportsContainer = styled(UikContainerVertical)`
  position: relative;  
  
  .grid {
    gap: 30px;
  }

  .wrapper.content {
    margin: 15px;
  }

  .ranking-tab-container {
    position: absolute;
    right: 0px;
    top: 0px;
    margin: 0px;

    .single-select-tab {
      margin: 0 5px; 
    }

    .uik-tab__item:hover {
      border-bottom-color: transparent !important;
    }
    .uik-tab__item.active {
      border-bottom-color: transparent;
    }
  }

  .multi-tables-container {
    width: 100%;
    & > * {
      margin-bottom: 20px;
    }

    tr > th:first-child {
      width: 380px;
    }
  }

  #map-indicator-bar-container {
    position: absolute;
    bottom: 80px;
  }

  #ranking-line-chart {
    margin-bottom: 30px;
  }

  .comparative-charts-cards {
    & > *:first-child {
      margin-bottom: 20px;
      min-width: 220px;
    }
  }

  .comparative-chart-line-container {
    max-width: 900px;
    position: relative;
    width: calc(100% - 220px);
  }

  .card-content-line-ranking-chart  {
    align-items: center;
  }

  .uik-select__wrapper.absolute {
    margin-top: 0px;
    position: absolute;
    right: 10px;
  }

  .uik-widget-table__wrapper tr td:first-child {
    max-width: 120px;
    word-break: break-all;
  }

  @media screen and (max-width: 1150px) {
    .comparative-chart-line-container {
      width: 100%;
    }

    /* #white-ranking-card {
      display: none;
    } */
    
    .col-lg-rr-12 {
      grid-column: auto/span 12;
    }
    
    .grid.inner-grid-ranking {
      grid-template-columns: none;
      grid-template-areas:  "tableranking0 tableranking0"
                            "smallcard1 tableranking1"
                            "smallcard2 tableranking2"
                            "smallcard3  tableranking3";
      
      #small-card-1 {
        grid-area: smallcard1;
      }
      #small-card-2 {
        grid-area: smallcard2;
      }
      #small-card-3 {
        grid-area: smallcard3;
      }
      #table-ranking-0 {
        grid-area: tableranking0;
      }
      #table-ranking-1 {
        grid-area: tableranking1;
      }
      #table-ranking-2 {
        grid-area: tableranking2;
      }
      #table-ranking-3 {
        grid-area: tableranking3;
      }
    }

    .card-content-line-ranking-chart {
      flex-flow: column-reverse;
      align-items: center;
    }

    .comparative-charts-cards {
      margin-top: 30px;
      width: 50%;
      & > *:first-child {
        min-width: none;
      }
    }
  }

`

export default RankingReportsContainer;