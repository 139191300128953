import styled from 'styled-components/macro';

const FormCustomContainer = styled.div`

  form {
    width: 100%;
    max-width: none;
  }

  .uik-widget-content__wrapper {
    padding: 15px 0px;
    border: 0px;
  }

  .float-right {
    float: right;
  }

`

export default FormCustomContainer;