//import { post, get, put, deleteReq } from "./general.services";

import {countries} from './data-test/demographic';
import { post, get } from './general.services';

export const getCustomerReports = async (args: any) => {
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `customers/${args.id}/reports${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getUsersDataByCountry = async () => {
  try {
    await new Promise(resolve => setTimeout(resolve, 3000));
    
    const res = {
      data: countries 
    }

    return res.data;
  }
  catch (err) {
    throw err;
  }
}


export const sendTestReports = async (args: any) => {
  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `reports/send`
    });

    return res;
  }
  catch (err) {
    console.log(err);
    throw err;
  }
}


//Traffic services
export const getTrafficData = async (args: any) => {
  console.log(`reports/traffic/users${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/traffic/users${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getPageViewData = async (args: any) => {
  console.log(`reports/interest/links${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/interest/links${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getTrafficCohortsData = async (args: any) => {
  console.log(`reports/traffic/cohorts${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/traffic/cohorts${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getTrafficSessionsData = async (args: any) => {
  console.log(`reports/traffic/sessions${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/traffic/sessions${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}


//Demographic services
export const getWorldMapData = async (args: any) => {
  console.log(`reports/demographic/countries${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/countries${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getAgeChartData = async (args: any) => {
  console.log(`reports/demographic/age${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/age${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getGenderChartData = async (args: any) => {
  console.log(`reports/demographic/gender${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/gender${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getLanguagesChartData = async (args: any) => {
  console.log(`reports/demographic/languages${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/demographic/languages${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}


//Interests services
export const getInterestCategories = async (args: any) => {
  console.log(`reports/interest/categories${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/interest/categories${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getInterestChannels = async (args: any) => {
  console.log(`reports/interest/channels${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/interest/channels${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getInterestPageViews = async (args: any) => {
  console.log(`reports/interest/pages${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/interest/pages${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getInterestSegmentsViews = async (args: any) => {
  console.log(`reports/interest/segments${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/interest/segments${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}



//Custom reports
export const getReportsCustomRanking = async (args: any) => {
  console.log(`reports/custom/ranking${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getRankingTrafficTable = async (args: any) => {
  console.log(`reports/custom/ranking/traffic${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/traffic${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}


export const getRankingSessionTable = async (args: any) => {
  console.log(`reports/custom/ranking/session${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/session${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getRankingPageViewsnTable = async (args: any) => {
  console.log(`reports/custom/ranking/pageviews${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/pageviews${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}

export const getRankingGlobalTable = async (args: any) => {
  console.log(`reports/custom/ranking/global${args.params ? '?' + args.params.join("&") : ''}`);
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `reports/custom/ranking/global${args.params ? '?' + args.params.join("&") : ''}`,
    });
    
    return res.data;
  }
  catch (err) {
    console.log("err", err);
    return [];
  }
}


export const getRankingByTownId = async (args: any) => {
  
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `customers/${args.id}/ranking`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}