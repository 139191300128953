import styled from 'styled-components/macro';
import * as uik from '../../utils/third-party/@uik';

const { UikContainerVertical } = uik;

const SubscriptionsContainer = styled(UikContainerVertical)`
  position: relative;  
  
  .main-container {
    /* margin-bottom: 120px; */
  }
`

export default SubscriptionsContainer;