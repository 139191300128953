import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { getBills, getOneBillsDownload } from '../../services/bills.services';

import GeneralContext from '../../context/general.context'

import TopBar from '../../components/TopBar/TopBar';
import Table from '../../components/Table/Table';
import InfoModal from '../../components/InfoModal/InfoModal';

import * as uik from '../../utils/third-party/@uik';

import { iModal } from '../../models/general.model';
import { iBills, iBillingDataForm } from '../../models/bills.models';

import BillingContainer from './Billing-style';
import CardInfo from '../../components/CardInfo/CardInfo';
import { currencyFormat } from '../../utils/text';
import { images } from '../../assets/images';
import Pagination from '../../components/Pagination/Pagination';

import {TOTAL_ITEMS} from '../../constants/general';
import { unsubscribeCustomer, getCurrentCustomer } from '../../services/customer.services';


export interface iBillsProps extends RouteComponentProps { }

const Billing: React.FC<iBillsProps> = ({ history }) => {

  const general = useContext(GeneralContext);

  const [items, setItems] = useState<iBills[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [billingData, setBillingData] = useState<iBillingDataForm>({
    name: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      className: "col-12",
      label: '',
      placeholder: "Nombre completo",
      handleChange: (value: string) => handleChange(value, 'name'),
      render: 'input',
    },
    direction: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      className: "col-12",
      label: '',
      placeholder: "Dirección",
      handleChange: (value: string) => handleChange(value, 'direction'),
      render: 'input',
    },
    province: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      className: "col-6",
      label: '',
      placeholder: "Provincia",
      handleChange: (value: string) => handleChange(value, 'province'),
      render: 'select',
      options: [
        { value: 'Prague', label: 'Prague' },
        { value: 'option1', label: 'Option 1' }
      ]
    },
    city: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      className: "col-6",
      label: '',
      placeholder: "Localidad",
      handleChange: (value: string) => handleChange(value, 'city'),
      render: 'select',
      options: [
        { value: 'option1', label: 'Option 1', }
      ]
    },
    country: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      className: "col-6",
      label: '',
      placeholder: "País",
      handleChange: (value: string) => handleChange(value, 'country'),
      render: 'select',
      options: [
        { value: 'option1', label: 'Option 1', }
      ]
    },
    codePostal: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      className: "col-6",
      label: '',
      placeholder: "Código Postal",
      handleChange: (value: string) => handleChange(value, 'codePostal'),
      render: 'input',
    },
  });

  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: '', key: '' });
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });


  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const params: string[] = [];
    const offset = TOTAL_ITEMS * (page - 1);
    
    // Set page to get 
    params.push('limit='+TOTAL_ITEMS);
    params.push('offset='+offset);
    
    const items = await getBills({id: localStorage.getItem('idUser'), params: params, loader: true, setLoading: general.setLoading });

    setTotalPage(items.pages);
    setItems(items.results);
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  }
  
  const handleChange = (value: string, key: string) => {
    billingData[key].value = value;
    setBillingData({ ...billingData });
  }

  const handleStatus = async (item: any, state: string) => {
    try {

    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Cambiar estado factura',
        text: 'Intente cambiar estado de la factura más tarde.',
        className: 'error'
      });
    }
  }

  const handleDownloadItem = async (item: any) => {
    try {
      const res = await getOneBillsDownload({
        id: encodeURIComponent(item.link),
        loader: true,
        setLoading: general.setLoading
      });
      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = `data:application/pdf;base64,${res.file}`;
      anchor.download = `${item.customerUser}-factura.pdf`;//'facturas.pdf'; //file.name
      anchor.click();
      closeModal();
      anchor.remove();

    } catch (err) {
      console.log(err); 
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error'
      });
    }
  }

  const getIcon = (status: string, id: string) => {
    switch (status) {
      case 'pagada':
        return (
          <div className="icon-custom-container billing-padding transparent">
            <li data-tip data-for='pagada' className="oi icon-check"></li>
            <ReactTooltip id='pagada' className='whiteClass margin-left' effect='solid'>
             <span>Pagado</span>
            </ReactTooltip>
          </div>

        );
      case 'cancelada':
        return (
          <div className="icon-custom-container billing-padding transparent">
            <img src={images.IconCross} data-tip data-for='cancelada' alt="cross" />
            <ReactTooltip id='cancelada' className='whiteClass margin-left' effect='solid'>
             <span>Cancelada</span>
            </ReactTooltip>
          </div>
        );
      case 'pendiente':
        return (
          <div className="icon-custom-container billing-padding transparent">
            <img src={images.IconExclamation} alt="cross" data-tip data-for='pendiente'/>
            <ReactTooltip id='pendiente' className='whiteClass margin-left' effect='solid'>
             <span>Pendiente</span>
            </ReactTooltip>
          </div>
        );
      case 'devuelta':
        return (
          <div className="icon-custom-container billing-padding transparent">
            <img src={images.IconMoney} alt="cross" data-tip data-for='abonada'/>
            <ReactTooltip id='abonada' className='whiteClass margin-left' effect='solid'>
             <span>Abonada</span>
            </ReactTooltip>
          </div>
        );
    }
  }

  const getRows = () => {
    let rows: any = [];
    
     items && items.map && items.map((item: any) => {
      const buttons = [];

      if (item.status === 'pagada') {
        buttons.push({
          label: "Abonar",
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Abonar',
              handleAccept: () => handleStatus(item, 'devuelta'),
              text: 'Se va a abonar la factura seleccionada',
              show: true,
              title: 'Abonar factura',
              alert: '',
              className: ''
            });
          }
        });

      }

      if (item.status === 'pendiente') {
        buttons.push({
          label: "Pagar",
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Pagar',
              handleAccept: () => handleStatus(item, 'pagada'),
              text: 'Se va a pagar la factura seleccionada',
              show: true,
              title: 'Pagar factura',
              alert: '',
              className: ''
            });
          }
        });

        buttons.push({
          label: "Editar",
          onClick: () => {
            history.push(`billing/edit/${item.id}`);;
          }
        });

        buttons.push({
          label: "Cancelar",
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Cancelar',
              handleAccept: () => handleStatus(item, 'pagada'),
              text: 'Se va a cancelar la factura seleccionada',
              show: true,
              title: 'Cancelar factura',
              alert: '',
              className: ''
            });
          }
        });

      }

      rows.push({
        state: {
          value: getIcon(item.status, item.id),
          type: 'icon'
        },
        town: {
          value: item.customer && item.customer.town ? item.customer.town.name : '-',
          type: 'text'
        },
        contact: {
          value: item.customer ? item.customer.username : '-',
          type: 'text'
        },
        date: {
          value: item.date,
          type: 'text'
        },
        description: {
          value: item.description,
          type: 'text'
        },
        amount: {
          value: currencyFormat(item.amount),
          type: 'text'
        },
        pdf: {
          value: (
            <div className="icon-custom-container transparent">
              <li className="oi icon-download"></li>,
            </div>
          ),
          type: 'icon',
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Aceptar',
              handleAccept: () => handleDownloadItem(item),
              text: 'Se va a descargar la factura seleccionada',
              show: true,
              title: 'Descargar factura',
              alert: '',
              className: 'success'
            });
          }
        },
        // buttons: {
        //   value: 'botones',
        //   actions: buttons,
        //   type: 'buttons',
        //   notShowMenu: (
        //     item.status === 'pendiente' ||
        //     item.status === 'pagada' ? false : true)
        // }
      });
    })

    return rows;
  }

  const columns = ['Estado', 'Fecha', 'Descripción', 'Importe', 'PDF'];
  const keyRows = ['state', 'date', 'description', 'amount', 'pdf'];

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: ''
    })
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  // const handleCancelSubscription = () => {
  //   setModal({
  //     code: '',
  //     button: 'Si, cancelar',
  //     handleAccept: cancelCustomerSubscription,
  //     text: 'Al cancelar su suscripción, luego de que terminé su actual período, dejará de disfrtuar de nuestros beneficios. ¿Deseas continuar?',
  //     show: true,
  //     title: 'Solicitar la baja de suscripción',
  //     alert: '',
  //     className: 'success'
  //   });
  // }

  const cancelCustomerSubscription = async() => {
    try {
      await unsubscribeCustomer({
        id: localStorage.getItem('idUser'),
        loader: true, 
        setLoading: general.setLoading       
      });

      setModal({
        code: 'ok',
        text: 'Se ha enviado su solicitud correctamente',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        className: 'success'
      });
      refreshCurrentCustomer();      
    } catch (err) {
      setModal({
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'warning'
      });

    }
    // PReguntar a diego si aquí va lo de la subscription = null
  }

  const refreshCurrentCustomer = () => {
    getCurrentCustomer(true);
  }

  const { UikTopBarSection, UikTopBarTitle,
    UikLayoutMain, UikWidget } = uik;


  return (
    <BillingContainer className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportBilling} alt="Icono de facturación" />
          </div>
          <UikTopBarTitle>
            Facturación
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        {/* <div className="flex-container">
          <Cards
            width={"50%"}
            title="Tu plan actual"
            texts={[general.currentCustomer.subscriptionFrequency]}
            textLeft={general.currentCustomer.subscription.name}
            textRight="Editar"
            handleClick={() => console.log("HOLA")}
          />
          <Cards
            width={"50%"}
            title="Información adicional"
            texts={["Una tarjeta"]}
            textLeft="Espacio disponible para información."
            textRight=""
            handleClick={() => setEditItem({ modal: true, id: '-' })}
          />
        </div> */}
        <UikWidget margin>
          <CardInfo
            headerTitle='Historial de pagos'
            addSpace={true}
            flexFlow='column'
          >
            <>
              <Table
                rows={getRows()}
                columns={columns}
                keyRows={keyRows}
                handleOrder={(order: any) => handleOrder(order)}
              />
              <div className="container-pagination">
                <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
              </div>
            </>
          </CardInfo>
        </UikWidget>
        {/* <div id="delete-user-account">
          <SimpleAlert
            title='Dar de baja la suscripción'
            description='España Fascinante dará de baja la suscripción que tiene contratada'
            textButton='Dar de baja'
            handleClick={handleCancelSubscription}
          />
        </div> */}
      </UikLayoutMain>

      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
      />
      
    </BillingContainer>
  );
}


export default withRouter(Billing);

