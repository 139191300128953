import styled, { css } from 'styled-components/macro';

const CardComparativeContainer = styled.div<{backgroundColor: string | undefined}>`
  position: relative;
  padding: 15px 20px 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  ${(props) => props.backgroundColor ? (css`
    background-color: ${props.backgroundColor}
  `) : 
    'background-color: #F5EDDA;'
  }

  .card-comparative-left-text {
    p {
      font-size: 14px;
      color: #868686;
    }
  }

  .card-comparative-right-text {
    padding-left: 30px;
    h2 {
      font-size: 24px;
      color: #4A4B4E;
    }
  }

`

export default CardComparativeContainer;