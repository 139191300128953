import React from 'react';
import { SingleButtonContainer } from './single-button-style';

interface SingleButtonProps {
  className?: String;
  drop?: boolean;
  icon?: JSX.Element;
  children?: any;
  text?: string;
  onClick?: () => void;
}
interface SingleButtonState {
  openDrop: boolean;
}
export default class SingleButton extends React.Component<
  SingleButtonProps,
  SingleButtonState
  > {
  state = {
    openDrop: false,
  };
  render() {
    const { drop, onClick, className, icon, children, text } = this.props;
    const { openDrop } = this.state;
    return (
      <SingleButtonContainer className={`${openDrop ? 'openDrop' : ''}  ${className}`}>
        <div
          className={"single-button-icon " + (children  ? '' : 'notShow')}
          onClick={() => (drop ?
            this.setState({ openDrop: true }) : onClick && onClick())
          }
        >
          {text}
          {icon && (icon)}
        </div>
        {/* each children <div className="single-button-drop__item"> */}
        {drop && (
          <div
            className="single-button-drop"
            onClick={() => this.setState({ openDrop: false })}
          >
            <div
              className="single-button-drop-veil"
              onClick={() => this.setState({ openDrop: false })}
            />
            {children}
          </div>
        )}
      </SingleButtonContainer>
    );
  }
}
