import React from 'react';

// Type
import { ICardCircleInfo } from './CardCircleInfo-type';

// Style
import CardCircleInfoContainer from './CardCircleInfo-style';

// Components
import AttachFileInput from '../AttachFile/AttachFile';

const CardCircleInfo: React.FC<ICardCircleInfo> = (props: ICardCircleInfo) => {
  const { footerText, actionText, size, image, handleFileImage } = props;

  const handleImageAdded = (files: Array<File>) => {
    handleFileImage(files[0]);
  }

  return ( 
    <CardCircleInfoContainer size={size} image={image}>
      <div className='hoverContent'>
        <AttachFileInput
          name='profileImage'
          type='normal'
          onFilesAdded={handleImageAdded}
          className='custom-file-input'
        />
        <p>
          {actionText}
        </p>

        <div>
          <p>
            {footerText.firstText}
          </p>
          <small>
            {footerText.lastText}
          </small>
        </div>

      </div>

    </CardCircleInfoContainer>
  )

}

export default CardCircleInfo