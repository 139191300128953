import styled from 'styled-components/macro';

const InactiveAlertContainer = styled.div`
background-color: #FAE4E4;
padding: 25px;
border: 1px solid #e27373;
z-index: 1000;

p {
  color: #4A4B4E;
}

p span,
p a {
  font-weight: bold;
}

p a {
  color: #248489;
  text-decoration: underline;
}
`

export default InactiveAlertContainer;


