import styled from "styled-components";
import arrow from "../../assets/images/arrow.svg";

import * as uik from '../../utils/third-party/@uik';

const { UikWidgetTable } = uik;

export const TableContainer = styled(UikWidgetTable)`
  position: relative;

  td {
    height: 40px !important;
    padding: 5px 15px !important;
  }
  
  td.highlighted {
    background-color:#D7EDEE;
    color: #248489;
    font-weight: bold;
  }


  td:last-child, 
  th:last-child {
    text-align: right !important;

    .icon-custom-container {
      float: right;
      margin-right: 15px;
      cursor: pointer;
    }
  }

  td.not-result-td {
    text-align: center !important;
    padding-top: 20px !important;
  } 

`


export const ThContainer = styled.th`
    background-color: #E6E6E6;
    text-transform: uppercase;
    padding: 18px 16px;
    
    &.pointer {
      cursor: pointer;
    }

    &.filter {
      cursor: pointer;
      
        padding-right: 30px;
        position: relative;
        &::after{
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 16px;
          height: 16px;
          background: url(${arrow}) no-repeat center;
          transition: 0.3s all ease-in-out; 
      }

      &.active {
        &::after {
          transform: translateY(-50%) rotate(-180deg);
        }
      
      }
    }


`;
