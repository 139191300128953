import styled from 'styled-components/macro';

const AttachInputContainer = styled.div`
  position: relative;
  width: 100%;
  height: 144px;


  &.error {
    #canvas {
      background-color: #FAEBF1;
  
      &:hover {
        background-color: #FAEBF1;
      }
      
      &:focus {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        outline: 0;
      }
  
      .icon-text {
        color: #E49CBB;
      }    
    }

    small {
      color: #E49CBB;
    }
  }

  &.disabled {
  }

  &.complete {
  }

  &.normal {

    #canvas {
      background-color: #F2F2F3;
  
      &:hover {
        background-color: #E2E2E2;
      }
      
      &:focus {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        outline: 0;
      }
    
      .icon-text {
        color: #B3B3B6;
      }
    }   
    
    small {
      color: #1A4CCD;
    }
  }

  label {
    padding-bottom: 10px;
    font-size: 12px;
    color: #B3B3B6;
    letter-spacing: 1px;
  }

  #canvas {
    position: relative;
    background-color: #F2F2F3;
    width:100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color ease 0.3s;

    &:hover {
      background-color: #E2E2E2;
    }
    
    &:focus {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
      outline: 0;
    }

    .icon-img-container {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
    }

    .icon-text {
      color: #B3B3B6;
      font-size: 14px;
    }

    input {
      display: none;
      opacity: 0;
    }

    img {
      width: 50px;
    }
  }

  small {
    font-size: 12px;
    font-weight: 200;
    margin-top: 40px;
  }
  
`;

export default AttachInputContainer; 