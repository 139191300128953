import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router';

import GeneralContext from '../../../context/general.context';

//types
import { iInterestsReportsProps } from './Interests-type';

// Components
import TopBar from '../../../components/TopBar/TopBar';
import CardInfo from '../../../components/CardInfo/CardInfo';
import Table from '../../../components/Table/Table';
import { iRow } from '../../../models/general.model';
import { Pie } from 'react-chartjs-2';
import { images } from '../../../assets/images';
import { TOOLTIPS_TEXT, ANALYTICS_TOKEN, CHANNELS_TYPES_CHART, CHART_COLORS } from '../../../constants/general';
import { getInterestChannels, getInterestCategories, getInterestPageViews, getInterestSegmentsViews } from '../../../services/reports.services';
import { numberFormat } from '../../../utils/text';

// Styles
import InterestsReportsContainer from './Interests-style';

// External library (Look how to inyect)
import * as uik from '../../../utils/third-party/@uik';
import moment from 'moment';
import Pagination from '../../../components/Pagination/Pagination';


const InterestsReports: React.FC<iInterestsReportsProps> = ({ history }) => {
  const general = useContext(GeneralContext);










  ////////////////////////////////////////////////////////////////
  ////////////////////// TO SHOW LOADER //////////////////////////
  ////////////////////////////////////////////////////////////////
  const [chartWasLoaded1, setChartWasLoaded1] = useState(false);
  const [chartWasLoaded2, setChartWasLoaded2] = useState(false);
  const [chartWasLoaded3, setChartWasLoaded3] = useState(false);
  const [chartWasLoaded4, setChartWasLoaded4] = useState(false);

  useEffect( () => {
    general.setLoading(true);
  }, []);

  useEffect( () => {
    
    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3 && chartWasLoaded4) 
      general.setLoading(false);
  }, [
    chartWasLoaded1,
    chartWasLoaded2,
    chartWasLoaded3,
    chartWasLoaded4
  ]);
  ////////////////////////////////////////////////////////////////
  //////////////////// END TO SHOW LOADER ////////////////////////
  ////////////////////////////////////////////////////////////////  



























  ////////////////////////////////////////////////////////////////
  //////////////////////// CHANNELS CHART ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IAgeGenderChartDataChannel {
    channel: string;
    count: string;
    percentage: string;
  }

  interface IAgeGenderChartData {
    channels: IAgeGenderChartDataChannel[]
  }

  interface IChannelsDataChartObject {
    [key: string]: IAgeGenderChartData;
  }

  interface IChannelsDataChart {
    [key: number]: IChannelsDataChartObject
  }

  const initChannelsDataDefaultValue = (): IChannelsDataChart => {
    return [
      {
        [general.currentCustomer.town.name]: {
          channels: []
        }
      }
    ]
  }

  const [channelsDataChart, setChannelsDataChart] = useState<IChannelsDataChart>(initChannelsDataDefaultValue());

  const formatAgeChartData = (data: IChannelsDataChart): [string[], number[]] => {
    let formatedData: [string[], number[]] = [[], []];
    if (!data[0]) {
      return [[], []];
    }

    if (data[0][general.currentCustomer.town.name])
      data[0][general.currentCustomer.town.name].channels.forEach(
        (value: IAgeGenderChartDataChannel, i: number) => {
          formatedData[0][i] = CHANNELS_TYPES_CHART[value.channel as string];
          formatedData[1][i] = Number(value.count);
        }
      );

    
    return formatedData;
  }

  const dataPie = () => {
    const formatedDataPie = formatAgeChartData(channelsDataChart);

    return ({
      labels: formatedDataPie[0],
      datasets: [{
        data: formatedDataPie[1],
        backgroundColor: [
          CHART_COLORS['total-users'],
          CHART_COLORS['new-users'],
          '#F5EDDA',
          '#868686',
          '#D8DCE6',
          '#947cb0'
        ],
        hoverBackgroundColor: [
          CHART_COLORS['total-users'],
          CHART_COLORS['new-users'],
          '#F5EDDA',
          '#868686',
          '#D8DCE6',
          '#947cb0'
        ]
      }]
    })
  };

  const optionsPie = {
    scales: {
      yAxes: [{
        angleLines: {
          display: false
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }],
      xAxes: [{
        angleLines: {
          display: false
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }]
    },
    legend: {
      display: true, //LO demas de abajo no sirve cuando esto esta en false
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        padding: 10,
        fontSize: 12
      }
    },

    tooltips: {
      label: 'reportsInterestChannelsChart',
      callbacks: {
        label: function(tooltipItem: any, data: any) {
          return data.labels[tooltipItem.index] + ': ' + numberFormat(data.datasets[0].data[tooltipItem.index]);
        }
      },
    },
    
    pointDot: false
  }

  //Load chart data
  const loadChannelsChartData = async () => {
    
    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD')
    ];

    const res = await getInterestChannels({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded1 ? () => {} : general.setLoading,
      params: params
    });

    setChannelsDataChart(res);
    setChartWasLoaded1(true);
  }

  useEffect(() => {
    if (general.currentCustomer.town.name)
      loadChannelsChartData();
  }, [general.currentCustomer])
  ////////////////////////////////////////////////////////////////
  ////////////////////// END GENDER CHART ////////////////////////
  ////////////////////////////////////////////////////////////////













  ////////////////////////////////////////////////////////////////
  //////////////////////// CATEGORY TABLE ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface ICategoriesChartDataSpec {
    count: string;
    percentage: string;
  }

  interface ICategoriesChartData {
    avg_sesion: ICategoriesChartDataSpec;
    category: string;
    sessions: ICategoriesChartDataSpec;
    users: ICategoriesChartDataSpec;
  }

  interface ICategoriesChartPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ICategoriesChartResp {
    data: ICategoriesChartData[];
    pagination: ICategoriesChartPagination;
  }

  interface ICategoriesDataChartObject {
    [key: string]: ICategoriesChartResp
  }

  interface ICategoriesDataChart {
    [key: number]: ICategoriesDataChartObject
  }

  const initCategoriesDataDefaultValue = (): ICategoriesDataChart => {
    return (
      [{
        [general.currentCustomer.town.name]: {
          data: [],
          pagination: {
            next_page_token: '0',
            total: 1,
            total_pages: 1
          }
        }
      }]
    )
  }

  const [categoriesDataChart, setCategoriesDataChart] = useState<ICategoriesDataChart>(initCategoriesDataDefaultValue());

  // for pagination
  const [pageCategoriesTableData, setPageCategoriesTableData] = useState<number>(1);
  const [totalPageCategoriesTableData, setTotalPageCategoriesTableData] = useState<number>(1);
  const [reloadItemsCategoriesTable, setReloadItemsCategoriesTable] = useState<boolean>(true);

  const changePageCategoriesTable = (page: number) => {
    setPageCategoriesTableData(page);
    setReloadItemsCategoriesTable(true);
  }

  const getCategoriesRows = (): iRow[] => {
    
    if (!categoriesDataChart[0])
      return [];
    const currentTownData = categoriesDataChart[0][general.currentCustomer.town.name];
    let categoriesRow: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: ICategoriesChartData) => {

        categoriesRow.push({
          category: {
            value: value.category,
            type: 'text'
          },
          users: {
            value: numberFormat(Number(value.users.count)),
            type: 'text'
          },
          sessions: {
            value: numberFormat(Number(value.sessions.count)),
            type: 'text'
          },
          time: {
            value: numberFormat(Number(Number(value.avg_sesion.count).toFixed(3))) + 's',
            type: 'text'
          }
        })

      });
    }
    return categoriesRow;
  }

  const columnsInfo = ['CATEGORÍA DE AFINIDAD', 'USUARIOS', 'SESIONES', 'Duración media de la sesión'];

  const keyRowsInfo = ['category', 'users', 'sessions', 'time'];


  //Load chart data
  const loadCategoriesChartData = async () => {
    
    let nexTokenNumber = (pageCategoriesTableData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res = await getInterestCategories({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded2 ? () => {} : general.setLoading,
      params: params
    });

    setCategoriesDataChart(res);
    setTotalPageCategoriesTableData(res[0][general.currentCustomer.town.name].pagination.total_pages);
    setChartWasLoaded2(true);

  }

  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsCategoriesTable) {
      loadCategoriesChartData();
      setReloadItemsCategoriesTable(false);
    }
  }, [general.currentCustomer, reloadItemsCategoriesTable])

  useEffect(() => {
    getCategoriesRows();
  }, [
    // For page view data loading
    categoriesDataChart
  ])
  ////////////////////////////////////////////////////////////////
  ///////////////////// END CATEGORY TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////













  ////////////////////////////////////////////////////////////////
  ///////////////////// PAGE SEARCH TABLE ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IPageViewsData {
    path: string;
    views: number;
    view_percentage: number;
  }

  interface IPageViewsPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface IPageViewsResp {
    data: IPageViewsData[];
    pagination: IPageViewsPagination;
  }

  interface IPageViewsTableObject {
    [key: string]: IPageViewsResp
  }

  interface IPageViewsTable {
    [key: number]: IPageViewsTableObject
  }

  const initPageViewDefaultValue = (): IPageViewsTable => {
    return (
      [{
        [general.currentCustomer.town.name]: {
          data: [],
          pagination: {
            next_page_token: '0',
            total: 1,
            total_pages: 1
          }
        }
      }]
    )
  }

  const [pageViewsData, setPageViewsData] = useState<IPageViewsTable>(initPageViewDefaultValue());

  // for pagination
  const [pagePageViewsData, setPagePageViewsData] = useState<number>(1);
  const [totalPagePageViewsData, setTotalPagePageViewsData] = useState<number>(1);
  const [reloadItemsPageViews, setReloadItemsPageViews] = useState<boolean>(true);

  const changePagePageViews = (page: number) => {
    setPagePageViewsData(page);
    setReloadItemsPageViews(true);
  }

  const getPageViewsRows =  (): iRow[] => {
    
    if (!pageViewsData[0])
      return [];
    const currentTownData = pageViewsData[0][general.currentCustomer.town.name];
    let pageViewsRows: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: IPageViewsData) => {
        const views_per = Number(value.view_percentage);

        const paintedArray = getPaintedArrayBlueprint(views_per);
  
        pageViewsRows.push({
          page: {
            value: value.path,
            type: 'text',
          },
          views: {
            value: numberFormat(Number(value.views)),
            type: 'text',
            extraClass: 'line-td text'
          },
          page_visits: {
            value: numberFormat(Number(value.view_percentage)) + '%',
            type: 'text',
            extraClass: 'line-td text'
          },          
          // data1: {
          //   value: (paintedArray[1]) ? (
              
          //     <div className='progress-bar'></div>
              
          //   )
          //   :
          //   (
          //     (paintedArray[0] ? 
          //       (
          //         <div className='progress-bar active' style={{ width: `${value.view_percentage}%` }}>
          //           <p>{value.view_percentage + '%'}</p>
          //         </div>
          //       ) :
          //       (<div></div>) 
          //     )
          //   ),
          //   type: 'text',
          //   extraClass: 'line-td'
          // },
          // data2: {
          //   value: (paintedArray[2]) ? (
              
          //     <div className='progress-bar'></div>
              
          //   )
          //   :
          //   (
          //     (paintedArray[1] ? 
          //       (
          //         <div className='progress-bar active' style={{ width: `${value.views}%` }}>
          //           <p>{value.views}</p>
          //         </div>
          //       ) :
          //       (<div></div>) 
          //     )
          //   ),
          //   type: 'text',
          //   extraClass: 'line-td'
          // },
          // data3: {
          //   value: (paintedArray[3]) ? (
              
          //     <div className='progress-bar'></div>
              
          //   )
          //   :
          //   (
          //     (paintedArray[2] ? 
          //       (
          //         <div className='progress-bar active' style={{ width: `${value.views}%` }}>
          //           <p>{value.views}</p>
          //         </div>
          //       ) :
          //       (<div></div>) 
          //     )
          //   ),
          //   type: 'text',
          //   extraClass: 'line-td'
          // },
          // data4: {
          //   value: (paintedArray[4]) ? (
              
          //     <div className='progress-bar'></div>
              
          //   )
          //   :
          //   (
          //     (paintedArray[3] ? 
          //       (
          //         <div className='progress-bar active' style={{ width: `${value.views}%` }}>
          //           <p>{value.views}</p>
          //         </div>
          //       ) :
          //       (<div></div>) 
          //     )
          //   )
          //   ,
          //   type: 'text',
          //   extraClass: 'line-td'
          // },
          // data5: {
          //   value: (paintedArray[4]) ? (<div className='progress-bar active' style={{ width: `${value.views}%` }}>
          //   <p>{value.views}</p>
          // </div>)
          //   :
          //     (<div></div>)
          //   ,
          //   type: 'text',
          //   extraClass: 'line-td'
          // }      
        })
      });
    }

    return pageViewsRows
  }
  
  

  const columns = ['Página','Vistas', '% de Visitas'];

  const keyRows = ['page', 'views', 'page_visits'];
  //const keyRows = ['page', 'views', 'data1', 'data2', 'data3', 'data4', 'data5'];
  
  //Utility
  const getPaintedArrayBlueprint = (number: number): boolean[] => { 
    const part = 100 / 5;
    let response: boolean[] = [];
    if (number > part * 4) {
      response = [true,true,true,true,true];

      return response;
    }

    if (number > part * 3) {
      response = [true,true,true,true,false];
      return response;
    }

    if (number > part * 2) {
      response = [true,true,true,false,false];
      return response;
    }

    if (number > part) {
      response = [true,true,false,false,false];
      return response;
    }

    response = [true,false,false,false,false];
    return response;

  }


  //Load chart data
  const loadPageViewsTableData = async () => {
    
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res = await getInterestPageViews({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded3 ? () => {} : general.setLoading,
      params: params
    });

    setPageViewsData(res);
    setTotalPagePageViewsData(res[0][general.currentCustomer.town.name].pagination.total_pages);
    
    setChartWasLoaded3(true);
  }

  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsPageViews) {      
      loadPageViewsTableData();
      setReloadItemsPageViews(false);
    }
  }, [general.currentCustomer, reloadItemsPageViews])
  ////////////////////////////////////////////////////////////////
  ////////////////// END PAGE SEARCH TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////










  ////////////////////////////////////////////////////////////////
  ////////////////// SEGMENTS TO BUY TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////
  interface ISegmentBuyRespDataSpec {
    count: string;
    percentage: string;
  }

  interface ISegmentBuyRespData {
    avg_sesion: ISegmentBuyRespDataSpec;
    category: string;
    sessions: ISegmentBuyRespDataSpec;
    users: ISegmentBuyRespDataSpec;
  }

  interface ISegmentBuyPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ISegmentBuyResp {
    data: ISegmentBuyRespData[];
    pagination: ISegmentBuyPagination;
  }

  interface ISegmentBuyTableObject {
    [key: string]: ISegmentBuyResp
  }

  interface ISegmentBuyTable {
    [key: number]: ISegmentBuyTableObject
  }

  const initSegmentsBuyDefaultValue = (): ISegmentBuyTable => {
    return (
      [{
        [general.currentCustomer.town.name]: {
          data: [],
          pagination: {
            next_page_token: '0',
            total: 1,
            total_pages: 1
          }
        }
      }]
    )
  }

  const [segmentBuyData, setSegmentyBuyData] = useState<ISegmentBuyTable>(initSegmentsBuyDefaultValue());

  // for pagination
  const [pageSegmentBuyData, setPageSementBuyData] = useState<number>(1);
  const [totalPageSegmentBuyData, setTotalPageSegmentBuyData] = useState<number>(1);
  const [reloadItemsSegmentsBuy, setReloadItemsSegmentsBuy] = useState<boolean>(true);

  const changePageSegmentsBuy = (page: number) => {
    setPageSementBuyData(page);
    setReloadItemsSegmentsBuy(true);
  }

  const getBuySegementsRows =  (): iRow[] => {
    
    if (!segmentBuyData[0])
      return [];
    const currentTownData = segmentBuyData[0][general.currentCustomer.town.name];
    let pageViewsRows: iRow[] = [];

    if (currentTownData) {
      currentTownData.data.map((value: ISegmentBuyRespData) => {
 
        pageViewsRows.push({
          category: {
            value: value.category,
            type: 'text',
          },
          users: {
            value: numberFormat(Number(value.users.count)),
            type: 'text',
          },
          sessions: {
            value: numberFormat(Number(value.sessions.count)),
            type: 'text',
          },
          sessions_avg: {
            value:  numberFormat(Number(Number(value.avg_sesion.count).toFixed(3))) + 's',
            type: 'text',
          },
        });
      });
    }

    return pageViewsRows
  }
  
  

  const segmentsColumns = ['Categoria','Usuarios', 'Sesiones', 'Tiempo de duración'];

  const segmentskeyRows = ['category', 'users', 'sessions', 'sessions_avg'];
  

  //Load chart data
  const loadSegmentsBuyTableData = async () => {
    
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res = await getInterestSegmentsViews({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded4 ? () => {} : general.setLoading,
      params: params
    });

    setSegmentyBuyData(res);
    setTotalPageSegmentBuyData(res[0][general.currentCustomer.town.name].pagination.total_pages);
    setChartWasLoaded4(true);
  }

  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsSegmentsBuy) {      
      loadSegmentsBuyTableData();
      setReloadItemsSegmentsBuy(false);
    }
  }, [general.currentCustomer, reloadItemsSegmentsBuy])
  ////////////////////////////////////////////////////////////////
  ////////////////// END PAGE SEARCH TABLE ///////////////////////
  ////////////////////////////////////////////////////////////////














  // Detect if show or not "No data" screen on cardInfo
  const detectIfChannelsChartHaveData = (): boolean => {
    if (channelsDataChart[0] && channelsDataChart[0][general.currentCustomer.town.name])
      return channelsDataChart[0][general.currentCustomer.town.name].channels.length == 0 ? true : false

    return false;
  }
  // End detect












  // Get third party components
  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikLayoutMain,
  } = uik;

  return (
    <InterestsReportsContainer className='page'>

      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportInterests} alt="Icono de Intereses" />
          </div>
          <UikTopBarTitle>
            Intereses
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>

      <UikLayoutMain className={'wrapper content'}>

        <div className='grid general-interest-grid'>

          <div id='interests-principal-channels' className='col-4'>
            <CardInfo
              headerTitle='Canales de Adquisición'
              height='400'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              tooltipText={TOOLTIPS_TEXT['canales-principales']}
              contentClass='interest-pie-chart-class'
              showDisableScreen={detectIfChannelsChartHaveData()}
              showDisableScreenText={'No hay datos suficientes para cargar la gráfica.'}
              disableScreenClass={'middle-screen'}
            >
              <div id='report-circle-chart-content'>
                <Pie width={350} height={250} data={dataPie} options={optionsPie}></Pie>
              </div>
            </CardInfo>
          </div>

          <div id='interests-principal-category' className='col-8'>
            <CardInfo
              headerTitle='Categorías de Afinidad'
              height='400'
              tooltipText={TOOLTIPS_TEXT['categorias-principales']}
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
            >
              <div className='cateogry-main-table'>
                <Table
                  rows={getCategoriesRows()}
                  columns={columnsInfo}
                  keyRows={keyRowsInfo}
                  handleOrder={() => { }}
                  showLoadingText={true}
                />

                <div className="container-pagination">
                  <Pagination page={pageCategoriesTableData} limit={totalPageCategoriesTableData} callback={(page: number) => changePageCategoriesTable(page)} />
                </div>
              </div>
            </CardInfo>
          </div>

          <div id='interests-page-search' className='col-12'>
            <CardInfo
              headerTitle='Página de búsqueda'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              height='400'
              tooltipText={TOOLTIPS_TEXT['paginas-de-busqueda']}
              flexFlow='column'
            >
              <>
                <Table
                  rows={getPageViewsRows()}
                  columns={columns}
                  columnsSpan={[1, 1, 5]}
                  keyRows={keyRows}
                  handleOrder={() => { }}
                  showLoadingText={true}
                />

                <div className="container-pagination">
                  <Pagination page={pagePageViewsData} limit={totalPagePageViewsData} callback={(page: number) => changePagePageViews(page)} />
                </div>
              </>
            </CardInfo>
          </div>

          <div id='interests-segment-to-buy' className='col-12'>
            <CardInfo
              headerTitle='Segmentos con intención de compra'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              height='400'
              tooltipText={TOOLTIPS_TEXT['segmentos-de-compra']}
              flexFlow='column'
            >
              <>
                <Table
                  rows={getBuySegementsRows()}
                  columns={segmentsColumns}
                  keyRows={segmentskeyRows}
                  handleOrder={() => { }}
                  showLoadingText={true}
                />

                <div className="container-pagination">
                  <Pagination page={pageSegmentBuyData} limit={totalPageSegmentBuyData} callback={(page: number) => changePageSegmentsBuy(page)} />
                </div>
              </>
            </CardInfo>
          </div>


        </div>
      </UikLayoutMain>

    </InterestsReportsContainer>
  )
}


export default withRouter(InterestsReports);

