import React, { useEffect, useState, useContext } from 'react'
import { withRouter } from 'react-router';


//types
import { iRankingReportsProps } from './Ranking-type';
import RankingReportsContainer from './Ranking-style';

// Components
import TopBar from '../../../components/TopBar/TopBar';
import CardInfo from '../../../components/CardInfo/CardInfo';

// External library (Look how to inyect)
import * as uik from '../../../utils/third-party/@uik';
import CardInfoSimpleTwo from '../../../components/CardInfoSimpleTwo/CardInfoSimpleTwo';
import { images } from '../../../assets/images';
import Table from '../../../components/Table/Table';
import { iRow } from '../../../models/general.model';
import CardInfoRanking from '../../../components/CardInfoRanking/CardInfoRanking';
import CardInfoSimpleThree from '../../../components/CardInfoSimpleThree/CardInfoSimpleThree';
import { Line } from 'react-chartjs-2';
import { ICardSelectOptions, ICardHeaderSelect, ICardSelectInput } from '../../../components/CardInfo/CardInfo-type';
import CardComparative from '../../../components/CardComparative/CardComparative';
import { TOOLTIPS_TEXT, DEFAULT_DATA_LIMIT, ANALYTICS_TOKEN, CHART_COLORS, SELECT_OPT_PERIODICITY } from '../../../constants/general';
import moment from 'moment';
import { getRankingTrafficTable, getRankingSessionTable, getRankingPageViewsnTable, getTrafficData, getPageViewData, getRankingGlobalTable, getRankingByTownId } from '../../../services/reports.services';
import GeneralContext from '../../../context/general.context';
import SearchBox from '../../../components/searchBox/SearchBox';
import { getCustomers, getCurrentRanking } from '../../../services/customer.services';
import Pagination from '../../../components/Pagination/Pagination';
import { numberFormat } from '../../../utils/text';
import { formatDateToHHMMSS } from '../../../utils/time';

const RankingReports: React.FC<iRankingReportsProps> = ({ history }) => {
  const general = useContext(GeneralContext);










  ////////////////////////////////////////////////////////////////
  /////////////// TO SHOW LOADER LOADER FIRST PAGE ///////////////
  ////////////////////////////////////////////////////////////////
  const [chartWasLoaded1, setChartWasLoaded1] = useState(false);
  const [chartWasLoaded2, setChartWasLoaded2] = useState(false);
  const [chartWasLoaded3, setChartWasLoaded3] = useState(false);
  const [chartWasLoaded4, setChartWasLoaded4] = useState(false);
  const [chartWasLoaded5, setChartWasLoaded5] = useState(false);


  const resetLoaderFistrPage = () => {
    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3 && chartWasLoaded4 && chartWasLoaded5) {
      setChartWasLoaded1(false);
      setChartWasLoaded2(false);
      setChartWasLoaded3(false);
      setChartWasLoaded4(false);
      setChartWasLoaded5(false);
  
      general.setLoading(true);
    }
  }
 

  useEffect( () => {
    general.setLoading(true);
  }, []);

  useEffect( () => {
    
    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3 && chartWasLoaded4 && chartWasLoaded5) 
      general.setLoading(false);
  }, [
    chartWasLoaded1,
    chartWasLoaded2,
    chartWasLoaded3,
    chartWasLoaded4,
    chartWasLoaded5
  ]);
  ////////////////////////////////////////////////////////////////
  /////////////// END TO SHOW LOADER FIRST PAGE //////////////////
  ////////////////////////////////////////////////////////////////  














  ////////////////////////////////////////////////////////////////
  ///////////// RANKING GOOGLE ANALI //TABLE /////////////////////
  ////////////////////////////////////////////////////////////////

  // Select options
  // const getSectionSelectOpt = () => {
  //   return [
  //     { label: 'Global', value: '' },
  //     { label: 'Comunidad', value: 'comunidad' },
  //     { label: 'Provincia', value: 'provincia' }
  //   ];
  // }

  const [rankingTraffic, setRankingTraffic] = useState<rankingTrafficDataTable[]>([]);
  const [rankingAvgSession, setRankingAvgSession] = useState<rankingTrafficDataTable[]>([]);
  const [rankingPageViews, setRankingPageViews] = useState<rankingTrafficDataTable[]>([]);
  const [rankingGlobal, setRankingGlobal] = useState<rankingTrafficDataTable[]>([]);
  
  const [currentSelectValue, setCurrentSelectValue] = useState('provincia');

  const getRowsTraffic = () => {
    if (rankingTraffic) {
      let resp: any[] = [];
      rankingTraffic.forEach((value: rankingTrafficDataTable) => {
        let temp = {
          position: {
            value: value.position ? value.position : '--',
            type: 'text'
          },
          population: {
            value: value.town,
            type: 'text'
          }
        }

        resp.push(temp);
      })
      return resp;
    }

    return [];
  }

  const getRowsAvgSession = () => {
    if (rankingAvgSession) {
      let resp: any[] = [];
      rankingAvgSession.forEach((value: rankingTrafficDataTable) => {
        let temp = {
          position: {
            value: value.position ? value.position : '--',
            type: 'text'
          },
          population: {
            value: value.town,
            type: 'text'
          }
        }

        resp.push(temp);
      })
      return resp;
    }

    return [];
  }

  const getRowsPageViews = () => {
    if (rankingPageViews) {
      let resp: any[] = [];
      rankingPageViews.forEach((value: rankingTrafficDataTable) => {
        let temp = {
          position: {
            value: value.position ? value.position : '--',
            type: 'text'
          },
          population: {
            value: value.town,
            type: 'text'
          }
        }

        resp.push(temp);
      })
      return resp;
    }

    return [];
  }

  const getRowsGlobal = () => {
    if (rankingGlobal) {
      let resp: any[] = [];
      rankingGlobal.forEach((value: rankingTrafficDataTable) => {
        let temp = {
          position: {
            value: value.position ? value.position : '--',
            type: 'text'
          },
          population: {
            value: value.town,
            type: 'text'
          }
        }

        resp.push(temp);
      })
      return resp;
    }

    return [];
  }

  const columnsTables = ['Posición', 'Población'];
  const keyRowsTables = ['position', 'population'];

  useEffect(() => {
    if (general.currentCustomer.town.name)
      resetPopulation();
  }, [general.currentCustomer, currentSelectValue])

  ////////////////////////////////////////////////////////////////
  /////////// END RANKING GOOGLE ANALI TABLE /////////////////////
  ////////////////////////////////////////////////////////////////  















  ////////////////////////////////////////////////////////////////
  ////////////////// RANKING GOOGLE ANALI CARDS //////////////////
  ////////////////////////////////////////////////////////////////
  interface IRankingUnitData {
    count: number | null;
    id: number;
    position: number | null;
    status: string | null;
  }

  interface IRankingData {
    global: IRankingUnitData;
    pageview: IRankingUnitData;
    session: IRankingUnitData;
    traffic: IRankingUnitData;
  }

  interface IRankingCurrentRanking {
    ranking_community: IRankingData;
    ranking_province: IRankingData;
    ranking_town: IRankingData;
  }

  const initCurrentRankingDefaultValue = (): IRankingData => {
    return {
      global: { id: 0, position: null, status: null, count: null },
      pageview: { id: 0, position: null, status: null, count: null },
      session: { id: 0, position: null, status: null, count: null },
      traffic: { id: 0, position: null, status: null, count: null }
    }
  }

  const [rankingCommunity, setRankingCommunity] = useState<IRankingData>(initCurrentRankingDefaultValue());
  const [rankingProvince, setRankingProvince] = useState<IRankingData>(initCurrentRankingDefaultValue());
  const [rankingTown, setRankingTown] = useState<IRankingData>(initCurrentRankingDefaultValue());

  const [currentRankingSelected, setCurrentRankingSelected] = useState<IRankingData>(initCurrentRankingDefaultValue());

  const setCardRankingValue = () => {
    
    if (currentSelectValue == '') {
      setCurrentRankingSelected(rankingTown);
    }

    if (currentSelectValue == 'comunidad') {
      setCurrentRankingSelected(rankingCommunity);
    }

    if (currentSelectValue == 'provincia') {
      setCurrentRankingSelected(rankingProvince);
    }
  }

  // Get up or down image
  const getStatus = (ranking: IRankingUnitData | null): string => {
    if (!ranking)
      return '';

    if (ranking.position == 1)
      return '';

    const status = ranking.status;
    if (status === 'up') {
      return images.IconTrendingUpSmall;
    } else if (status === 'down') {
      return images.IconTrendingDownSmall;
    }
    return '';
  }

  //Load ranking table data
  const loadCustomRankingData = async () => {

    const res: IRankingCurrentRanking = await getCurrentRanking({
      loader: true,
      //setLoading: general.setLoading
      setLoading: !setChartWasLoaded1 ? () => {} : general.setLoading,
    });
    
    setRankingCommunity(res.ranking_community);
    setRankingProvince(res.ranking_province);
    setRankingTown(res.ranking_town);
    setChartWasLoaded1(true);

  }

  useEffect(() => {
    if (general.currentCustomer.town.name)
      loadCustomRankingData();
  }, [general.currentCustomer, currentSelectValue])

  useEffect(() => {
    if (rankingCommunity && rankingProvince && rankingTown)
      setCardRankingValue();
  }, [
    rankingCommunity,
    rankingProvince,
    rankingTown,
    currentSelectValue
  ])

  ////////////////////////////////////////////////////////////////
  ////////////////// END  GOOGLE ANALI CARDS /////////////////////
  ////////////////////////////////////////////////////////////////  












  ////////////////////////////////////////////////////////////////
  //////////////// RANKING TRAFFIC TABLE DATA ////////////////////
  //////////////////////////////////////////////////////////////// 
  interface rankingTrafficDataTable {
    id: number;
    town: string;
    position: number;
    status: string;
    count: number;
  }

  interface rankingTrafficTable {
    results: rankingTrafficDataTable[];
    page_total: number;
    pages: number;
    total: number;
  }

  const [pageTraffic, setPageTraffic] = useState<number>(1);
  const [totalPageTraffic, setTotalPageTraffic] = useState<number>(1);
  const [pageSession, setPageSession] = useState<number>(1);
  const [totalPageSession, setTotalPageSession] = useState<number>(1);
  const [pagePageViews, setPagePageViews] = useState<number>(1);
  const [totalPagePageviews, setTotalPagePageviews] = useState<number>(1);
  const [pageGlobal, setPageGlobal] = useState<number>(1);
  const [totalPageGlobal, setTotalPageGlobal] = useState<number>(1);  

  const [reloadTraffic, setReloadTraffic] = useState(false);
  const [reloadSesion, setReloadSession] = useState(false);
  const [reloadPageviews, setReloadPageViews] = useState(false);
  const [reloadGlobal, setReloadGlobal] = useState(false);


  const changePageTraffic = (page: number) => {
    setPageTraffic(page);
    setReloadTraffic(true);
  }
  const changePageSession = (page: number) => {
    setPageSession(page);
    setReloadSession(true);
  }
  const changePagePageViews = (page: number) => {
    setPagePageViews(page);
    setReloadPageViews(true);
  }

  const changePageGlobal = (page: number) => {
    setPageGlobal(page);
    setReloadGlobal(true);
  }


  const getCurrentRankingCode = (currentValue: string) => {
    if (currentValue == 'comunidad')
      return general.currentCustomer.town.communityCode;
    if (currentValue == 'provincia')
      return general.currentCustomer.town.provinceCode;
    return '';
  }

  const loadRankingTrafficTableData = async () => {
    
    const params: string[] = [];
    const offset = DEFAULT_DATA_LIMIT * (pageTraffic - 1);
    params.push("ranking=" + currentSelectValue);
    params.push("code=" + getCurrentRankingCode(currentSelectValue));
    params.push("offset=" + String(offset));

    const res: rankingTrafficTable = await getRankingTrafficTable({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded2 ? () => {} : general.setLoading,
      params: params
    });
    
    setRankingTraffic(res.results);
    setTotalPageTraffic(res.pages);
    setChartWasLoaded2(true);
  }

  const loadRankingSessionTableData = async () => {
    
    const params: string[] = [];
    const offset = DEFAULT_DATA_LIMIT * (pageSession - 1);
    params.push("ranking=" + currentSelectValue);
    params.push("code=" + getCurrentRankingCode(currentSelectValue));
    params.push("offset=" + String(offset));

    const res: rankingTrafficTable = await getRankingSessionTable({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded3 ? () => {} : general.setLoading,
      params: params
    });

    setRankingAvgSession(res.results);
    setTotalPageSession(res.pages);

    setChartWasLoaded3(true);
  }

  const loadRankingPageviewsTableData = async () => {
    
    const params: string[] = [];
    const offset = DEFAULT_DATA_LIMIT * (pagePageViews - 1);
    params.push("ranking=" + currentSelectValue);
    params.push("code=" + getCurrentRankingCode(currentSelectValue));
    params.push("offset=" + String(offset));

    const res: rankingTrafficTable = await getRankingPageViewsnTable({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded4 ? () => {} : general.setLoading,
      params: params
    });

    setRankingPageViews(res.results);
    setTotalPagePageviews(res.pages);

    setChartWasLoaded4(true);
  }

  const loadRankingGlobalTableData = async () => {
    
    const params: string[] = [];
    const offset = DEFAULT_DATA_LIMIT * (pageGlobal - 1);
    params.push("ranking=" + currentSelectValue);
    params.push("code=" + getCurrentRankingCode(currentSelectValue));
    params.push("offset=" + String(offset));

    const res: rankingTrafficTable = await getRankingGlobalTable({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded5 ? () => {} : general.setLoading,
      params: params
    });

    setRankingGlobal(res.results);
    setTotalPageGlobal(res.pages);

    setChartWasLoaded5(true);
  }


  
  useEffect(() => {
    
    if (general.currentCustomer.town.name) {
      if (reloadTraffic) {
        loadRankingTrafficTableData();        
      }
      if (reloadSesion) {
        loadRankingSessionTableData();        
      }
      if (reloadPageviews) {
        loadRankingPageviewsTableData();        
      }
      if (reloadGlobal) {
        loadRankingGlobalTableData()        
      }

      setReloadTraffic(false);
      setReloadSession(false);
      setReloadPageViews(false);
      setReloadGlobal(false);
    }
  }, [general.currentCustomer, reloadTraffic, reloadSesion, reloadPageviews, reloadGlobal])

  useEffect(() => {
    setPageTraffic(1);
    setPageSession(1);
    setPagePageViews(1);
    setPageGlobal(1)

    setReloadTraffic(true);
    setReloadSession(true);
    setReloadPageViews(true);
    setReloadGlobal(true);
  }, [currentSelectValue])

  ////////////////////////////////////////////////////////////////
  /////////////// END RANKING TRAFFIC TABLE DATA /////////////////
  //////////////////////////////////////////////////////////////// 



















































  ////////////////////////////////////////////////////////////////
  ////////////////////// COMPARE CARD ////////////////////////////
  ////////////////////////////////////////////////////////////////
  //This is for compare populations
  const [inputButtonPopulation, setInputButtonPopulation] = useState({value: '', label: ''});

  const [otherPopulation, setOtherPopulation] = useState('');
  const [inputPopulation, setInputPopulation] = useState({value: '', label: ''});
  const [otherPopulationRanking, setOtherPopulationRanking] = useState<IRankingCurrentRanking>();

  const setPopulationToCompare = () => {
    setOtherPopulation(inputPopulation.label);
    getOtherTownRanking();
  }

  const resetPopulation = () => {
    setOtherPopulation('');
    setInputPopulation({value: '', label: ''});

    resetLoaderSecondPage(false);
  }

  const setInputDataFromButton = (data: any) => {
    setInputButtonPopulation(data);
  }

  const setInputData = () => {
    setInputPopulation(inputButtonPopulation);
    resetLoaderSecondPage(true);
  }


  let searchWaiter: any = null;
  const [rankingCompareOpt, setRankingCompareOpt] = useState([]);

  const getClientsItems = async (filter?: string) => {
    if (searchWaiter) {
      clearInterval(searchWaiter);
    }
    searchWaiter = setTimeout(async () => {
      const params = [];

      if (filter) {
        params.push(`filter=${filter}`);
      }

      const res: any = await getCustomers({
        params,
        loader: true,
        setLoading: () => {}
      });
      const customers = res.results ? res.results.map((i: any) => ({ label: i.town ? i.town.name : i.username, value: i.id })) : [];

      setRankingCompareOpt(customers);
    }, 750);
  }

  const getOtherTownRanking = async () => {
    try {
      
      const res: IRankingCurrentRanking = await getRankingByTownId({
        id: inputPopulation.value,
        loader: true,
        //setLoading: general.setLoading
        setLoading: setChartSecondWasLoaded1 ? () => {} : general.setLoading,
      });
     
      setOtherPopulationRanking(res);
      setChartSecondWasLoaded1(true);

    } catch (e) {
      console.error(e);
    }
  }

  useEffect( () => {
    if (inputPopulation.value)
      setPopulationToCompare();
  }, [inputPopulation])
  ////////////////////////////////////////////////////////////////
  ////////////////////// END COMPARE CARD ////////////////////////
  ////////////////////////////////////////////////////////////////








  ////////////////////////////////////////////////////////////////
  /////////////// TO SHOW LOADER LOADER SECOND PAGE //////////////
  ////////////////////////////////////////////////////////////////
  const [chartSecondWasLoaded1, setChartSecondWasLoaded1] = useState(false);
  const [chartSecondWasLoaded2, setChartSecondWasLoaded2] = useState(false);
  const [chartSecondWasLoaded3, setChartSecondWasLoaded3] = useState(false);


  const resetLoaderSecondPage = (setLoading: boolean) => {
    if (chartSecondWasLoaded1 && chartSecondWasLoaded2) {
      setChartSecondWasLoaded1(false);
      setChartSecondWasLoaded2(false);
      setChartSecondWasLoaded3(false);
  
      general.setLoading(false);
    }
  }

  useEffect( () => {
    if (inputPopulation.value)
      general.setLoading(true);
  }, [inputPopulation]);

  useEffect( () => {
    if (chartSecondWasLoaded1 && chartSecondWasLoaded2 && chartSecondWasLoaded3) 
      general.setLoading(false);
  }, [
    chartSecondWasLoaded1,
    chartSecondWasLoaded2, 
    chartSecondWasLoaded3
  ]);
  ////////////////////////////////////////////////////////////////
  /////////////// END TO SHOW LOADER SECOND PAGE /////////////////
  ////////////////////////////////////////////////////////////////  





















  ////////////////////////////////////////////////////////////////
  ////////////// COMPARE TRAFFIC AND SESSION /////////////////////
  ////////////////////////////////////////////////////////////////
  interface IMultiChartLimitDates {
    start_date: string;
    end_date: string;
    type: string;
  }

  interface IMultiChartTownDates {
    avg_session: string;
    date: string;
    page_views: string;
    users: string;

    [key: string]: string;
  }

  interface IMultiChartTownTotals {
    avg_session: string;
    page_views: string;
    users: string;

    [key: string]: string;
  }

  interface IMultiChartTown {
    dates: IMultiChartTownDates[];
    total: IMultiChartTownTotals;
  }

  interface IMultiChartOject {
    [key: string]: IMultiChartTown;
  }

  interface IMultiChart {
    [key: number]: IMultiChartOject
  }

  const initMultiChartDefaultValue = (): IMultiChart => {
    return [
      {
        [general.currentCustomer.town.name]: {
          dates: [],
          total: {
            avg_session: "--",
            page_views: "--",
            users: "--"
          }
        }
      }
    ]
  }

  const initMultiChartDefaultLimits = (): IMultiChartLimitDates => {
    return {
      start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      type: ''
    }
  }

  const [multiChartData, setMultiChartData] = useState<IMultiChart>(initMultiChartDefaultValue);
  const [multiChartLimitDates, setMultiChartLimitDates] = useState<IMultiChartLimitDates>(initMultiChartDefaultLimits);

  // Users
  const getMultiChartSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value.users)) : [];

    return resp;
  }

  const getMultiChartOtherSelectedData = () => {
    if (!multiChartData[1])
      return;
    const resp = multiChartData[1][otherPopulation] ? multiChartData[1][otherPopulation].dates.map((value: IMultiChartTownDates) => Number(value.users)) : [];

    return resp;
  }

  // Sessions
  const getMultiChartSessionSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value.avg_session)) : [];

    return resp;
  }

  const getMultiChartOtherSessionSelectedData = () => {
    if (!multiChartData[1])
      return;
    const resp = multiChartData[1][otherPopulation] ? multiChartData[1][otherPopulation].dates.map((value: IMultiChartTownDates) => Number(value.avg_session)) : [];

    return resp;
  }

  // page views
  const getMultiChartPageViewsSelectedData = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => Number(value.page_views)) : [];

    return resp;
  }

  const getMultiChartPageViewsOtherSelectedData = () => {
    if (!multiChartData[1])
      return;
    const resp = multiChartData[1][otherPopulation] ? multiChartData[1][otherPopulation].dates.map((value: IMultiChartTownDates) => Number(value.page_views)) : [];

    return resp;
  }

  // Charts Labels
  const getMultiChartSelectedDate = () => {
    if (!multiChartData[0])
      return;
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].dates.map((value: IMultiChartTownDates) => value.date) : [];

    return resp;
  }

  // For data sets
  const getMultiChartSelectedSets = () => {
    return (
      [
        {
          label: 'Mi pueblo',
          fill: false,
          lineTension: 0.1,
          backgroundColor: CHART_COLORS['total-users'],
          borderColor: CHART_COLORS['total-users'],
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: CHART_COLORS['total-users'],
          pointBackgroundColor: CHART_COLORS['total-users'],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: CHART_COLORS['total-users'],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          yAxisID: 'first-y-axis',

          data: getMultiChartSelectedData(),
        },
        {
          label: { otherPopulation },
          fill: false,
          lineTension: 0.1,
          backgroundColor: CHART_COLORS['new-users'],
          borderColor: CHART_COLORS['new-users'],
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: CHART_COLORS['new-users'],
          pointBackgroundColor: CHART_COLORS['new-users'],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: CHART_COLORS['new-users'],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          yAxisID: 'first-y-axis',

          data: getMultiChartOtherSelectedData(),
        }
      ]
    )
  }

  const getMultiChartSessionSelectedSets = () => {
    return (
      [
        {
          label: 'Mi pueblo',
          fill: false,
          lineTension: 0.1,
          backgroundColor: CHART_COLORS['total-users'],
          borderColor: CHART_COLORS['total-users'],
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: CHART_COLORS['total-users'],
          pointBackgroundColor: CHART_COLORS['total-users'],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: CHART_COLORS['total-users'],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          yAxisID: 'first-y-axis',

          data: getMultiChartSessionSelectedData(),
        },
        {
          label: { otherPopulation },
          fill: false,
          lineTension: 0.1,
          backgroundColor: CHART_COLORS['new-users'],
          borderColor: CHART_COLORS['new-users'],
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: CHART_COLORS['new-users'],
          pointBackgroundColor: CHART_COLORS['new-users'],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: CHART_COLORS['new-users'],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          yAxisID: 'first-y-axis',

          data: getMultiChartOtherSessionSelectedData(),
        }
      ]
    )
  }

  const getMultiChartPageViewsSelectedSets = () => {
    return (
      [
        {
          label: 'Mi pueblo',
          fill: false,
          lineTension: 0.1,
          backgroundColor: CHART_COLORS['total-users'],
          borderColor: CHART_COLORS['total-users'],
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: CHART_COLORS['total-users'],
          pointBackgroundColor: CHART_COLORS['total-users'],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: CHART_COLORS['total-users'],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          yAxisID: 'first-y-axis',

          data: getMultiChartPageViewsSelectedData(),
        },
        {
          label: { otherPopulation },
          fill: false,
          lineTension: 0.1,
          backgroundColor: CHART_COLORS['new-users'],
          borderColor: CHART_COLORS['new-users'],
          borderWidth: 2,
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: CHART_COLORS['new-users'],
          pointBackgroundColor: CHART_COLORS['new-users'],
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: CHART_COLORS['new-users'],
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
          yAxisID: 'first-y-axis',

          data: getMultiChartPageViewsOtherSelectedData(),
        }
      ]
    )
  }

  // Users comparative chart
  const dataLine = {
    labels: getMultiChartSelectedDate(),
    datasets: getMultiChartSelectedSets(),
  }

  const optionsLine = {
    scales: {
      yAxes: [{
        id: 'first-y-axis',
        type: 'linear',
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          callback: (label: any) => {

            return numberFormat(label);
          }
        },
        gridLines: {
          drawBorder: false
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },


    legend: {
      display: false,
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },


    tooltips: {
      label: 'reportRankingTrafficMultiLineChart',
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          return numberFormat(tooltipItem.value);
        }
      },
    },


    pointDot: false
  }

  // Users comparative time in page
  const dataLineSession = {
    labels: getMultiChartSelectedDate(),
    datasets: getMultiChartSessionSelectedSets(),
  }

  const optionsLineSession = {
    scales: {
      yAxes: [{
        id: 'first-y-axis',
        type: 'linear',
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          callback: (label: any) => {
            return formatDateToHHMMSS(label);
          }
        },
        gridLines: {
          drawBorder: false
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },


    legend: {
      display: false,
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },


    tooltips: {
      label: 'reportRankingTrafficSessionMultiLineChart',
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          return formatDateToHHMMSS(tooltipItem.value);
        }
      },
    },


    pointDot: false
  }

  // Users comparative page views
  const dataLinePageViews = {
    labels: getMultiChartSelectedDate(),
    datasets: getMultiChartPageViewsSelectedSets(),
  }

  const optionsLinePageViews = {
    scales: {
      yAxes: [{
        id: 'first-y-axis',
        type: 'linear',
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          callback: (label: any) => {

            return numberFormat(label);
          }
        },
        gridLines: {
          drawBorder: false
        }
      }],
      xAxes: [{
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },


    legend: {
      display: false,
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },


    tooltips: {
      label: 'reportRankingTrafficMultiLineChart',
      callbacks: {
        label: (tooltipItem: any, data: any) => {
          return numberFormat(tooltipItem.value);
        }
      },
    },


    pointDot: false
  }

  //Charts headers options
  const lineChartHeaderSelectOptions: ICardSelectOptions = {
    defaultValue: '',
    values: [
      {
        label: SELECT_OPT_PERIODICITY['daily'],
        value: ''
      },
      {
        label: SELECT_OPT_PERIODICITY['weekly'],
        value: 'week'
      },
      {
        label: SELECT_OPT_PERIODICITY['monthly'],
        value: 'month'
      }
    ]
  }

  const lineChartCardHeaderOptions: ICardHeaderSelect = {
    options: lineChartHeaderSelectOptions,
    leftText: '',
    callback: (data: ICardSelectInput) => {
      formatAndSetNewTime(data);
    }
  }

  const formatAndSetNewTime = (data: ICardSelectInput) => {
    let resp;
    
    switch (data.value) {
      case 'week':
        resp = {
          start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'week'
        }
        break;
      case 'month':
        resp = {
          start_date: moment().subtract(1, 'years').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'month'
        }
        break;
      default:
        resp = {
          start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: ''
        }
        break;
    }

    setMultiChartLimitDates(resp);
  }

  //Load first chart multiple data
  const loadTrafficData = async () => {
    
    const params = [
      'towns=' + general.currentCustomer.town.name + ',' + otherPopulation,
      'startDate=' + multiChartLimitDates.start_date,
      'endDate=' + multiChartLimitDates.end_date,
      'type=' + multiChartLimitDates.type
    ];

    const res = await getTrafficData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: setChartSecondWasLoaded2 ? () => {} : general.setLoading,
      params: params
    });
    

    setMultiChartData(res);
    setChartSecondWasLoaded2(true);
  }

  // WE SHOULD MERGUE THIS TWO (12)
  const getCurrentTownTrafficUserTotal = () => {
    if (!multiChartData[0])
      return '0';
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].total.users : '0';

    return numberFormat(Number(resp));
  }
  // (22)
  const getOtherTownTrafficUserTotal = () => {
    if (!multiChartData[1])
      return '0';
    const resp = multiChartData[1][otherPopulation] ? multiChartData[1][otherPopulation].total.users : '0';

    return numberFormat(Number(resp));
  }
  // (12)
  const getCurrentTownTrafficSessionUserTotal = () => {
    if (!multiChartData[0])
      return '0';
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].total.avg_session : '0';

    return formatDateToHHMMSS(resp);
  }
  // (22)
  const getOtherTownTrafficSessionUserTotal = () => {
    if (!multiChartData[1])
      return '0';
    const resp = multiChartData[1][otherPopulation] ? multiChartData[1][otherPopulation].total.avg_session : '0';

    return formatDateToHHMMSS(resp);
  }
  // (3)
  const getCurrentTownPageViewsUserTotal = () => {
    if (!multiChartData[0])
      return '0';
    const resp = multiChartData[0][general.currentCustomer.town.name] ? multiChartData[0][general.currentCustomer.town.name].total.page_views : '0';

    return numberFormat(Number(resp));
  }
  // (3)
  const getOtherTownPageViewsUserTotal = () => {
    if (!multiChartData[1])
      return '0';
    const resp = multiChartData[1][otherPopulation] ? multiChartData[1][otherPopulation].total.page_views : '0';

    return numberFormat(Number(resp));
  }  
  // END WE SHOULD MERGUE THIS TWO


  useEffect(() => {
    if (general.currentCustomer.town.name && otherPopulation)
      loadTrafficData();
  }, [
    // For multichart data loading
    general.currentCustomer,
    multiChartLimitDates,
    otherPopulation
  ])
  ////////////////////////////////////////////////////////////////
  ////////////// END COMPARE TRAFFIC AND SESSION /////////////////  
  ////////////////////////////////////////////////////////////////



























  ////////////////////////////////////////////////////////////////
  /////////////////////// TABLES PAGE VIEW ///////////////////////
  ////////////////////////////////////////////////////////////////
  interface IPageViewData {
    entrance_rate: string;
    path: string;
    views: string;
    view_percentage: string;
  }

  interface IPageViewPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface IPageViewResp {
    data: IPageViewData[];
    pagination: IPageViewPagination;
  }


  interface IPageViewOject {
    [key: string]: IPageViewResp;
  }

  interface IPageView {
    [key: number]: IPageViewOject
  }

  const initPageViewDefaultValue = (): IPageView => {
    return (
      [{
        [general.currentCustomer.town.name]: {
          data: [],
          pagination: {
            next_page_token: '0',
            total: 1,
            total_pages: 1
          }
        }
      }]
    )
  }

  const [pageViewsData, setPageViewsData] = useState<IPageView>(initPageViewDefaultValue());

  // for pagination
  const [pagePageViewsData, setPagePageViewsData] = useState<number>(1);
  const [reloadItemsPageViews, setReloadItemsPageViews] = useState<boolean>(true);


  const getPageViewsDate = (): iRow[] => {
    if (!pageViewsData[0])
      return [];
    const currentTownData = pageViewsData[0][general.currentCustomer.town.name] as any;

    let pageRows: iRow[] = [];

    pageRows.push({
      links: {
        value: 'Links',
        type: 'text',
        extraClass: 'line-td text'
      },
      clicks: {
        value: 'Número de Clicks',
        type: 'text',
        extraClass: 'line-td text'
      }
    })

    if (currentTownData && currentTownData.events) {
      if (currentTownData.events.length == 0) {
        pageRows.push({
          links: {
            value: '--',
            type: 'text',
            extraClass: 'line-td text'
          },
          clicks: {
            value: '--',
            type: 'text',
            extraClass: 'line-td text'
          }
        })
      } else
      currentTownData.events.map((value: any, index: number) => {
        // const views_per = Number(value.view_percentage);
        pageRows.push({
          links: {
            value: value.link,
            type: 'text',
            extraClass: 'line-td text'
          },
          clicks: {
            value: numberFormat(Number(value.clicks)),
            type: 'text',
            extraClass: 'line-td text'
          }
        })
      });
    } else {
      pageRows.push({
        links: {
          value: '--',
          type: 'text',
          extraClass: 'line-td text'
        },
        clicks: {
          value: '--',
          type: 'text',
          extraClass: 'line-td text'
        }
      })
    }

    return pageRows;
  }

  const getPageViewsOtherDate = (): iRow[] => {
    if (!pageViewsData[1])
      return [];
    const otherTownData = pageViewsData[1][otherPopulation] as any;

    let pageRows: iRow[] = [];

    pageRows.push({
      links: {
        value: 'Links',
        type: 'text',
        extraClass: 'line-td text'
      },
      clicks: {
        value: 'Número de Clicks',
        type: 'text',
        extraClass: 'line-td text'
      }
    })

    if (otherTownData && otherTownData.events) {
      if (otherTownData.events.length == 0) {
        pageRows.push({
          links: {
            value: '--',
            type: 'text',
            extraClass: 'line-td text'
          },
          clicks: {
            value: '--',
            type: 'text',
            extraClass: 'line-td text'
          }
        })
      } else
      otherTownData.events.map((value: any, index: number) => {
        //const views_per = Number(value.view_percentage);

        pageRows.push({
          links: {
            value: value.link,
            type: 'text',
            extraClass: 'line-td text'
          },
          clicks: {
            value: numberFormat(Number(value.clicks)),
            type: 'text',
            extraClass: 'line-td text'
          },
        })
      });
    } else {
      pageRows.push({
        links: {
          value: '--',
          type: 'text',
          extraClass: 'line-td text'
        },
        clicks: {
          value: '--',
          type: 'text',
          extraClass: 'line-td text'
        }
      })
    }

    return pageRows;
  }

  const loadPageViewData = async () => {
    
    let nexTokenNumber = (pagePageViewsData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

    const params = [
      'towns=' + general.currentCustomer.town.name + ',' + otherPopulation,
      'startDate=2019-11-01',
      'endDate=' + moment().format('YYYY-MM-DD'),
      'nextPage=' + nexTokenNumber
    ];

    const res: IPageView = await getPageViewData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: setChartSecondWasLoaded3 ? () => {} : general.setLoading,
      params: params
    });

    setPageViewsData(res);
    setChartSecondWasLoaded3(true);
    //setTotalPagePageViewsData(res[0][general.currentCustomer.town.name].pagination.total_pages);
  }
  console.log('es aqui donde no entra luego');

  const columnsFirstInfo = ['Mi pueblo (' + general.currentCustomer.town.name + ')', ''];
  const columnsSecondInfo = [otherPopulation, ''];
  const keyRowsInfo = ['links', 'clicks'];

  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsPageViews && otherPopulation) {
      loadPageViewData();
      setReloadItemsPageViews(false);
    }

  }, [general.currentCustomer, reloadItemsPageViews, otherPopulation])

  ////////////////////////////////////////////////////////////////
  ///////////////////// END TABLES PAGE VIEW /////////////////////
  ////////////////////////////////////////////////////////////////

























  ////////////////////////////////////////////// 
  /////////// Multiselect top header /////////// 
  //////////////////////////////////////////////

  const [filterSelect, setFilterSelect] = useState<string>('provincia');
  // This is a function to load different data
  const loadDataToFilterSelect = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const tabActiveElem = document.querySelectorAll('.single-select-tab.active');
    const currentTarget = event.currentTarget;

    if (tabActiveElem.length > 0)
      tabActiveElem[0].classList.remove('active');

    if (currentTarget.parentElement)
      currentTarget.parentElement.classList.add('active');

    //Load other data here
    setFilterSelect(currentTarget.getAttribute('data-to-select') as string);
    setCurrentSelectValue(currentTarget.getAttribute('data-to-select') as string);
    // Reset and set loader
    resetLoaderFistrPage();
  }

  ////////////////////////////////////////////// 
  /////////// Multiselect top header /////////// 
  //////////////////////////////////////////////









  // Get third party components
  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikLayoutMain,
    UikInput,
    UikButton,
    UikSelect,
    UikTabContainer,
    UikTabItem
  } = uik;

  return (
    <RankingReportsContainer className='page'>
      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportRanking} alt="Icono de Ranking" />
          </div>
          <UikTopBarTitle>
            Ranking presencia digital
          </UikTopBarTitle>

          {
            (otherPopulation === '') ?
              (
                // <UikSelect
                //   className={"select absolute"}
                //   onChange={(evt: any) => { setCurrentSelectValue(evt.value) }}
                //   defaultValue={['provincia']}
                //   options={getSectionSelectOpt()}
                //   placeholder={'Seleccione perioccidad'}
                // />
                <UikTabContainer className='ranking-tab-container'>
                  <UikTabItem className='single-select-tab'>
                    <div data-to-select='' onClick={loadDataToFilterSelect}>
                      <UikButton
                        type="submit"
                        success={filterSelect == '' ? true : false}
                        style={{ width: 'auto' }}
                        onClick={()=>{}}
                      >
                        Global
                      </UikButton>
                    </div>
                  </UikTabItem>
                  <UikTabItem className='single-select-tab'>
                    <div data-to-select='comunidad' onClick={loadDataToFilterSelect}>
                      <UikButton
                        type="submit"
                        style={{ width: 'auto' }}
                        success={filterSelect == 'comunidad' ? true : false}
                        onClick={()=>{}}
                      >
                        Comunidad
                      </UikButton>
                    </div>
                  </UikTabItem>

                  <UikTabItem className='single-select-tab' active>
                    <div data-to-select='provincia' onClick={loadDataToFilterSelect}>
                      <UikButton
                        type="submit"
                        success={filterSelect == 'provincia' ? true : false}
                        style={{ width: 'auto' }}
                        onClick={()=>{}}
                      >
                        Provincia
                      </UikButton>
                    </div>
                  </UikTabItem>
              </UikTabContainer>                
              )
            :
              (
                <div></div>
              )
          }

        </UikTopBarSection>
      </TopBar>




      <UikLayoutMain className={'wrapper content'}>

        <div className='grid'>

          <div className='col-8 col-lg-rr-12'>
            <CardInfo
              headerTitle='Comparador'
              addSpace={true}
              backgroundColor='#EAECEE'
              hardStroke={true}
              tooltipText={TOOLTIPS_TEXT['comparador-ranking']}
            >
              <div className='grid'>
                <div className='col-12'>
                  <p>
                    Selecciona el municipio que desees para realizar una comparativa respecto a la presencia digital de cada uno de ellos.
                  </p>
                </div>
                <div className='col-10'>
                  <SearchBox
                    labelText={''}
                    onChange={(value: any) => getClientsItems(value)}
                    optionsText={rankingCompareOpt}
                    optionKey={"value"}
                    optionValue={"label"}
                    onSelect={(value: any) => setInputDataFromButton(value)}
                    // onSelect={(value: any) => setInputData(value)}
                    placeholder="Nombre de municipio"
                  />
                </div>
                <div className='col-2 col-lg-4'>
                  <UikButton
                    type="submit"
                    success
                    style={{ width: 'auto' }}
                    onClick={setInputData}
                  >
                    Comparar
                  </UikButton>
                </div>
              </div>
            </CardInfo>
          </div>

          <div id='ranking-card-result' className='col-4 col-lg-rr-12'>

            {
              (otherPopulation === '') ?



                <div id='white-ranking-card'>
                  <CardInfoRanking
                    height={'215'}
                    dateText={moment().format('DD MMMM YYYY')}
                    value={String(currentRankingSelected.global.count) == '0' ? '--' : String(currentRankingSelected.global.position)}
                    smallValue=''
                    footText='Ranking España fascinante'
                    image={''}
                  // image={images.IconTrendingDown}
                  />
                </div>


                :




                <div>
                  <CardInfoSimpleThree
                    footerText='Volver a comparar'
                    extraData={otherPopulationRanking}
                    value={otherPopulation}
                    handleAction={resetPopulation}
                    height={'203'}
                  />
                </div>




            }

          </div>

          {
            (otherPopulation === '') ?
              <div className='col-12 grid inner-grid-ranking'>

                <div id='small-card-1' className='col-4'>
                  <CardInfoSimpleTwo
                    dateText={moment().format('DD MMMM YYYY')}
                    value={String(currentRankingSelected.traffic.count) == '0' ? '--' : String(currentRankingSelected.traffic.position)}
                    smallValue='Posición'
                    footText='Usuarios'
                    image={getStatus(currentRankingSelected.traffic)}
                  />
                </div>
                <div id='small-card-2' className='col-4'>
                  <CardInfoSimpleTwo
                    dateText={moment().format('DD MMMM YYYY')}
                    value={String(currentRankingSelected.session.count) == '0' ? '--' : String(currentRankingSelected.session.position)}
                    smallValue='Posición'
                    footText='Tiémpo en página'
                    image={getStatus(currentRankingSelected.session)}
                  />
                </div>
                <div id='small-card-3' className='col-4'>
                  <CardInfoSimpleTwo
                    dateText={moment().format('DD MMMM YYYY')}
                    value={String(currentRankingSelected.pageview.count) == '0' ? '--' : String(currentRankingSelected.pageview.position)}
                    smallValue='Posición'
                    footText='Páginas vistas'
                    image={getStatus(currentRankingSelected.pageview)}
                  />
                </div>


                <div id='table-ranking-0' className='col-12'>
                  <CardInfo
                    height='546'
                    flexFlow='column'
                    headerTitle='Ranking Global'
                  >
                    <>
                      <Table
                        rows={getRowsGlobal()}
                        columns={columnsTables}
                        keyRows={keyRowsTables}
                        handleOrder={() => { }}
                      />
                      <div className="container-pagination">
                        <Pagination page={pageGlobal} limit={totalPageGlobal} callback={(page: number) => changePageGlobal(page)} />
                      </div>
                    </>
                  </CardInfo>
                </div>
                

                <div id='table-ranking-1' className='col-4'>
                  <CardInfo
                    height='546'
                    flexFlow='column'
                    headerTitle='Usarios'
                  >
                    <>
                      <Table
                        rows={getRowsTraffic()}
                        columns={columnsTables}
                        keyRows={keyRowsTables}
                        handleOrder={() => { }}
                      />
                      <div className="container-pagination">
                        <Pagination page={pageTraffic} limit={totalPageTraffic} callback={(page: number) => changePageTraffic(page)} />
                      </div>
                    </>
                  </CardInfo>
                </div>
                <div id='table-ranking-2' className='col-4'>
                  <CardInfo
                    height='546'
                    flexFlow='column'
                    headerTitle='Tiempo en página'
                  >
                    <>
                      <Table
                        rows={getRowsAvgSession()}
                        columns={columnsTables}
                        keyRows={keyRowsTables}
                        handleOrder={() => { }}
                      />
                      <div className="container-pagination">
                        <Pagination page={pageSession} limit={totalPageSession} callback={(page: number) => changePageSession(page)} />
                      </div>
                    </>
                  </CardInfo>
                </div>
                <div id='table-ranking-3' className='col-4'>
                  <CardInfo
                    height='546'
                    flexFlow='column'
                    headerTitle='Páginas vistas'
                  >
                    <>
                      <Table
                        rows={getRowsPageViews()}
                        columns={columnsTables}
                        keyRows={keyRowsTables}
                        handleOrder={() => { }}
                      />
                      <div className="container-pagination">
                        <Pagination page={pagePageViews} limit={totalPagePageviews} callback={(page: number) => changePagePageViews(page)} />
                      </div>
                    </>
                  </CardInfo>
                </div>

              </div>





              :





              <div className='col-12 grid'>
                <div id='ranking-visit-lings' className='col-12'>
                  <div id='ranking-line-chart' className='col-9'>
                    <CardInfo
                      headerTitle='Usuarios'
                      headerSelect={lineChartCardHeaderOptions}
                      expand={true}
                      contentClass='card-content-line-ranking-chart'
                      tooltipText={TOOLTIPS_TEXT['trafico-de-usaurios-2']}
                    >
                      <>
                        <div className='comparative-charts-cards'>
                          <CardComparative
                            text={general.currentCustomer.town.name}
                            value={getCurrentTownTrafficUserTotal()}
                            backgroundColor='#C0F4EA'
                          />
                          <CardComparative
                            text={otherPopulation}
                            value={getOtherTownTrafficUserTotal()}
                            backgroundColor='#FAE8BD'
                          />
                        </div>
                        <div className='comparative-chart-line-container'>
                          <Line height={80} data={dataLine} options={optionsLine}></Line>
                        </div>
                      </>
                    </CardInfo>
                  </div>
                  <div id='ranking-line-chart' className='col-9'>
                    <CardInfo
                      headerTitle='Tiempo en página'
                      headerSelect={lineChartCardHeaderOptions}
                      expand={true}
                      contentClass='card-content-line-ranking-chart'
                      tooltipText={TOOLTIPS_TEXT['tiempo-en-pagina']}
                    >
                      <>
                        <div className='comparative-charts-cards'>
                          <CardComparative
                            text={general.currentCustomer.town.name}
                            value={getCurrentTownTrafficSessionUserTotal()}
                            backgroundColor='#C0F4EA'
                          />
                          <CardComparative
                            text={otherPopulation}
                            value={getOtherTownTrafficSessionUserTotal()}
                            backgroundColor='#FAE8BD'
                          />
                        </div>
                        <div className='comparative-chart-line-container'>
                          <Line height={80} data={dataLineSession} options={optionsLineSession}></Line>
                        </div>
                      </>
                    </CardInfo>
                  </div>


                  <div id='ranking-line-chart' className='col-9'>
                    <CardInfo
                      headerTitle='Páginas vistas'
                      headerSelect={lineChartCardHeaderOptions}
                      expand={true}
                      contentClass='card-content-line-ranking-chart'
                      tooltipText={TOOLTIPS_TEXT['links-visitados']}
                    >
                      <>
                        <div className='comparative-charts-cards'>
                          <CardComparative
                            text={general.currentCustomer.town.name}
                            value={getCurrentTownPageViewsUserTotal()}
                            backgroundColor='#C0F4EA'
                          />
                          <CardComparative
                            text={otherPopulation}
                            value={getOtherTownPageViewsUserTotal()}
                            backgroundColor='#FAE8BD'
                          />
                        </div>
                        <div className='comparative-chart-line-container'>
                          <Line height={80} data={dataLinePageViews} options={optionsLinePageViews}></Line>
                        </div>
                        {/* <div className='multi-tables-container'>
                          <Table
                            rows={getPageViewsDate()}
                            columns={columnsFirstInfo}
                            keyRows={keyRowsInfo}
                            handleOrder={() => { }}
                          />

                          <Table
                            rows={getPageViewsOtherDate()}
                            columns={columnsSecondInfo}
                            keyRows={keyRowsInfo}
                            handleOrder={() => { }}
                          />
                        </div> */}
                      </>                    
                    </CardInfo>
                  
                  </div>



                  <CardInfo
                    headerTitle='Url que han sido clickeadas'
                    headerSelect={lineChartCardHeaderOptions}
                    contentClass='card-content-line-ranking-chart'
                    tooltipText={TOOLTIPS_TEXT['url-been-clicked']}
                  >
                    <>
                      <div className='multi-tables-container'>
                        <Table
                          rows={getPageViewsDate()}
                          columns={columnsFirstInfo}
                          keyRows={keyRowsInfo}
                          handleOrder={() => { }}
                        />

                        <Table
                          rows={getPageViewsOtherDate()}
                          columns={columnsSecondInfo}
                          keyRows={keyRowsInfo}
                          handleOrder={() => { }}
                        />
                      </div>
                    </>                    
                  </CardInfo>
                </div>
              </div>
          }
        </div>
      </UikLayoutMain>

    </RankingReportsContainer>
  );
}


export default withRouter(RankingReports);