import React from 'react';

// Type
import { IMissingModalInfo } from './ModalMissingInfo-type';

// Style
import ModalInfoSimpleContainer from './ModalMissingInfo-style';
import { NavLink } from 'react-router-dom';
import { images } from '../../assets/images';



const ModalMissingInfo: React.FC<IMissingModalInfo> = (props: IMissingModalInfo) => {
  const { title, description, customer } = props;
  
  const isEmptyString = (word: string) => {
    if (word && word != '')
      return false
    
    return true;
  }

  const isEmptyArray = (word: string) => {
    if (word && word.length != 0)
      return false
    
    return true;
  }
  
  return (
    <ModalInfoSimpleContainer>

      <div className='missing-info-container'>
        <h3>
          {title}
        </h3>

        <p>
          Entra en <NavLink to='/settings/extraInfo'>ajustes</NavLink>, completa la información de tu perfil y la información de interés sobre tu municipio. 
        </p>

        <ul>
          <li>Tú historia <img src={isEmptyString(customer.history) ? images.IconWarningYellow: images.IconCheck} alt='status icon' /></li>
          <li>Lugares para visitar <img src={isEmptyArray(customer.places) ? images.IconWarningYellow: images.IconCheck} alt='status icon' /></li>
          <li>Información Práctica <img src={isEmptyString(customer.description) ? images.IconWarningYellow: images.IconCheck} alt='status icon' /></li>
        </ul>

        <p>
          Cualquier duda contáctanos en <a href="mailto:linx@espanafascinante.com"> linx@espanafascinante.com</a>
        </p>
        {/* <img src={images.IconCloseGreen} /> */}
      </div>

    </ModalInfoSimpleContainer>
  )

}

export default ModalMissingInfo