import styled from 'styled-components/macro';


const RadioContainer = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;

  & > * {
    margin-bottom: 10px;
  }

`

export default RadioContainer;