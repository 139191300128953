import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';

import { getSubscriptions } from '../../services/subscription.services';

import GeneralContext from '../../context/general.context'

import TopBar from '../../components/TopBar/TopBar';
import CardBig from '../../components/CardBig/CardBig';

import * as uik from '../../utils/third-party/@uik';

import { iSubscriptions } from '../../models/subscriptions.models';
import CardRate from '../../components/CardRate/CardRate';
import { currencyFormat, numberFormat } from '../../utils/text';
import { images } from '../../assets/images';
import { editCustomer, editCustomerSubscription } from '../../services/customer.services';
import { iModal } from '../../models/general.model';
import InfoModal from '../../components/InfoModal/InfoModal';

import SubscriptionsContainer from './Subscriptions-style';

export interface iSubscriptionsProps extends RouteComponentProps { }

const Subscriptions: React.FC<iSubscriptionsProps> = ({ history }) => {

  const [items, setItems] = useState<iSubscriptions[]>([]);
  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });
  
  const general = useContext(GeneralContext);
  
  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const items = await getSubscriptions({
      loader: true,
      setLoading: general.setLoading,
      params: [
        'frequency=mensual',
        'orderBy=id',
        'order=asc'
      ]
    });
    setItems(items);
  }

  function openModalUpdateSubscription (this:any) {
    setModal({
      code: '',
      text: 'Está a punto de solicitar un cambio en su subscripcion, ¿Desea continuar?',
      button: 'Enviar solicitud',
      handleAccept: requestUpdateSubscription.bind(this.id),
      show: true,
      alert: '',
      title: 'Solicitud de Modificación',
      className: 'success'
    });
  }

  async function requestUpdateSubscription (this: any) {

    try {
      await editCustomerSubscription({
        data: {'subscriptionId': this},
        id: localStorage.getItem('idUser'),
        loader: true,
        setLoading: general.setLoading,
      });
      setModal({
        code: 'ok',
        text: 'Se han solicitado los cambios exitosamente',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        className: 'success-icon'
      });
    } catch (err) {
      setModal({
        code: '',
        text: 'Ha ocurrido un error. Intente nuevamente.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'error'
      });
    }
  }  

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: ''
    })
  }  

  const { UikTopBarSection, UikTopBarTitle, UikLayoutMain,
    UikContentItem, UikDivider } = uik;

  return (
    <SubscriptionsContainer className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconEuro} alt="Icono de Tarifas" />
          </div>
          <UikTopBarTitle>
            Tarifas
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"main-container wrapper"}>
        <UikContentItem>
          <CardBig
            data={items}
            title="Calcula tu tarifa"
            textLeft="Calcula tu tarifa"
            textRight="Según tus características, la tarifa que mejor se adapta a ti es:"
            handleClick={() => {}}
          />
          <UikDivider margin />
          <div className="grid mt-20">
            {items.map((item: iSubscriptions, index: number) => { 
              let isSubsActive = false;
              if (general.currentCustomer.subscription)
                isSubsActive = general.currentCustomer.subscription.id == item.id;


              return (
                <div className="col-3 col-lg-6" key={`rate-${index}`}>
                  <CardRate
                    title={item.name}
                    text={`Perfecta para pueblos entre ${numberFormat(item.minPoblation)} y ${numberFormat(item.maxPoblation)} habitantes`}
                    price={<p>{currencyFormat(item.priceMonthly || 0)}€ <span>/mes</span></p>}
                    active={isSubsActive}
                    data={item.tags.map((tag: any) => ({ value: tag, active: true}))}
                    // buttonText={"Actualizar"}
                    handleClick={openModalUpdateSubscription.bind(item)}
                  />
                </div>
              )

            })}
          </div>
          <p style={{marginTop: '30px'}}>* Todas las tarifas pueden ser abonadas anualmente.</p>
          <InfoModal
            className={modal.className}
            onClickAccept={modal.handleAccept}
            mainText={modal.text}
            titleText={modal.title}
            alertText={modal.alert}
            buttonText={modal.button}
            active={modal.show}
            close={() => closeModal()}
          />
          
        </UikContentItem>

      </UikLayoutMain>
    </SubscriptionsContainer>
  );
}




export default withRouter(Subscriptions);

