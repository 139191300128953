import IconTrash from "../images/icon-trash.svg";
import IconDoubleFill from "../images/icon-double-fill.svg";
import IconWarningYellow from "../images/icon-warning-yellow.svg";
import IconInfoBlue from "../images/icon-info-blue.svg";
import IconCloseGrey from "../images/icon-close-grey.svg";
import IconCloseGreen from "../images/icon-close-green.svg";
import IconCheck from "../images/icon-check.svg";
import IconDownload from "../images/icon-download.svg";
import IconMask from "../images/icon-mask.svg";
import IconUp from "../images/icon-up.svg";
import IconDown from "../images/icon-down.svg";
import Arrow from "../images/arrow.svg";
import picture from "../images/image-default-profile.svg";
import World from "../images/world.svg";
import IconInfoGray from "../images/icon-info-gray.svg";
import IconTrendingUp from "../images/icon-trending-up.svg";
import IconTrendingDown from "../images/icon-trending-down.svg";
import IconTrendingUpSmall from "../images/icon-trending-up-small.svg";
import IconTrendingDownSmall from "../images/icon-trending-down-small.svg";
import IconTrendingEqualSmall from "../images/icon-trending-equl-small.svg";
import IconProfileSettings from "../images/icon-profile-settings.svg";
import IconCross from "../images/icon-cross.svg";
import IconExclamation from "../images/icon-exclamation.svg";
import IconMoney from "../images/icon-money.svg";
import IconGreenCheckCircle from "../images/icon-check-circle.svg";
import IconEuro from "../images/icon-euro.svg";
import IconReportRanking from "../images/icon-ranking.svg";
import IconReportInterests from "../images/icon-intereses.svg";
import IconReportTraffic from "../images/icon-traffic.svg";
import IconReportGeneral from "../images/icon-general.svg";
import IconReportBilling from "../images/icon-facturacion.svg";
import IconReportHistorial from "../images/icon-report-historial.svg";
import IconReportDemographic from "../images/icon-demografico.svg";
import IconCardReportDecoartive from "../images/icon-card-report-decorative.svg";
import IconWorldLine from "../images/icon-world-line.svg";


import IconNavigationSubscription from '../images/icon-ef-tarifas.svg';
import IconNavigationBilling from '../images/icon-ef-facturacion.svg';
import IconNavigationReports from '../images/icon-ef-informes.svg';
import IconNavigationHome from '../images/icon-ef-inicio.svg';

import iconDeleteTrash from "../images/icon-delete-trash.svg";


import IconArrowLeft from "../images/icon-chevron-left.svg";
import IconArrowRight from "../images/icon-chevron-right.svg";




import ImageSettingHeroblock from "../images/image-settings-heroblock.png";
import ImageNotFound from "../images/not-found-background.svg";
import ImagePatrocinante from "../images/image-patrocinante-01.jpg";







export const images = {
  picture,
  IconTrash,
  IconDoubleFill,
  IconWarningYellow,
  IconInfoBlue,
  IconCloseGrey,
  IconCloseGreen,
  IconDownload,
  IconCheck,
  IconMask,
  IconUp,
  Arrow,
  World,
  IconDown,
  IconInfoGray,
  IconTrendingUp,
  IconTrendingDown,
  IconTrendingUpSmall,
  IconTrendingDownSmall,
  IconTrendingEqualSmall,
  ImageSettingHeroblock,
  IconProfileSettings,
  IconExclamation,
  ImageNotFound,
  IconCross,
  IconMoney,
  IconGreenCheckCircle,
  IconEuro,
  IconReportRanking,
  IconReportInterests,
  IconReportTraffic,
  IconReportGeneral,
  IconReportBilling,
  IconReportHistorial,
  IconReportDemographic,
  IconArrowLeft,
  IconArrowRight,
  iconDeleteTrash,
  IconNavigationSubscription,
  IconNavigationBilling,
  IconNavigationReports,
  IconNavigationHome,
  IconCardReportDecoartive,
  ImagePatrocinante,
  IconWorldLine
};
