import styled from "styled-components";

export const CardContainer = styled.div`
  position: relative;
  color: black;
  background: #EDE4C5;
  width: 100%;
  min-height: 158px;
  border-radius: 4px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  display: block;
  overflow: auto;

  h2 {
    font-weight: 600;
    font-size: 28px;
    line-height: 22px;
    margin-right: 30px;
    
  }

  small {
    text-decoration: underline;
    font-size: 14px;
    color: #248489;
    cursor: pointer;
  }
  small.extra-text {
    font-size: 14px;
    color: #242424;
    text-decoration: none;
    max-width: 400px;
    display: block;
    cursor: default; 
  }

  .card-header {
    margin-bottom: 22px;
    p {
      padding: 30px 0px 0px 30px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 28px;
      color: #3E3F42;
    }
  }
  .card-divider {
    margin-right: 30px;
    margin-left: 30px;
    width: auto;
    background: #8A8A8A;
  }
  .card-body {
    
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 0;
    margin-top: 40px;
    margin-bottom: 40px;
    padding: 0px 166px 0px 54px;
    /* height: 100%;  */
    width:100%;
    

    .card-left {
      left: 0;
      width: 279px;
      p {
        font-size: 32px;
        line-height: 22px;
        color: #248489;
        font-weight: 600;
      }
    }
    .card-right {
      right: 0;
      p {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        
        color: #248489;
        margin-bottom: 16px;
        
      }

      .uik-input__input {
        border-color: #248489;
      }

      button {
        width: 120px;
      }
    }
    .card-inline {  
      display: flex;
      flex-flow: row wrap;
      align-items: center;

      &.result {
        margin-top: 10px;
      }

      input {
        vertical-align: middle;
        margin: 0px 15px 0px 0;
        width: 240px;
      }
    }
  }  


  @media screen and (max-width: 1340px) {
    .card-body {
      padding: 0px 100px 0px 54px;
      /* height: 100%;  */
      width:100%;      
    }
  }

  @media screen and (max-width: 1280px) {
    .card-body {
      padding: 0px 26px 0px 34px;
      /* height: 100%;  */
      width:100%;      
    }
  }

  @media screen and (max-width: 1175px) {
    min-height: 178px;

    .card-body {
      flex-flow: column;
      align-items: flex-start;

      .card-left p {
        margin-bottom: 20px;
      }
    }
  }

`;
