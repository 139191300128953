import { post, get, put, deleteReq, putFormData } from "./general.services";

export const createSubscription = async (args: any) => {
  try {
    const res = await post({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: "subscriptions"
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const pictureSubscription = async (args: any) => {
  try {
    const res = await putFormData({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `subscriptions/${args.id}/image`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const editSubscription = async (args: any) => {

  try {
    const res = await put({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `subscriptions/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}

export const getSubscriptions = async (args: any) => {
  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `subscriptions${args.params ? '?' + args.params.join("&") : ''}`,
    });

    return res.data;
  }
  catch (err) {
    console.log(err);
    return [];
  }
}

export const getOneSubscription = async (args: any) => {

  try {
    const res = await get({
      loader: true,
      setLoading: args.setLoading,
      data: args.data,
      endpoint: `subscriptions/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    return {};
  }
}

export const deleteSubscription = async (args: any) => {

  try {
    const res = await deleteReq({
      loader: true,
      setLoading: args.setLoading,
      endpoint: `subscriptions/${args.id}`
    });

    return res.data;
  }
  catch (err) {
    throw err;
  }
}