import styled from 'styled-components/macro';
import * as uik from '../../utils/third-party/@uik';

const { UikContainerVertical } = uik;

const ReportsContainer = styled(UikContainerVertical)`
  position: relative;  
  
  .card-content-line-chart,
  .card-content-bar-chart {
    position: relative;
  }

  .card-content-line-chart.general {
    top: 40px;
    padding: 30px;
  }

  .card-content-line-ranking-chart {
    margin-top: 40px;
  }

  .card-content-bar-chart {
    top: 55px;
  
    &.demographic {
      top: 0px;
    }
  }

  .justifyc-content-flex {
    justify-content: center;
  }

  .progress-bar {
    width: 100%;
    height: 5px;
    background-color: #2CDBBA;
    position: relative;

    &.active {

    }
    & > p {
      position: absolute;
      top: 10px;
      right: 0px;
      font-size: 12px;
      color: #2CDBBA;
    }
  }

  .container-pagination {
    margin-top: 20px;
    align-self: flex-end;
  }

  
  .middle-screen {
    height: 50%;
    margin: auto;
    top: 0px;
    bottom: 0px;
  }

`

export default ReportsContainer;