import styled from 'styled-components';
// import { colors } from '../../assets/colors';

export const InputFileContainer = styled.div`
  width: 100%;
  &.dark-blue-label {
    .input-file-message-label {
      color: #1948a0;
      padding-left: 0;
    }
  }

  &.error {
    .input-box-main-field {
      color: #FF4100;
      border-bottom: 1.5px solid #FF4100;
    }
  }

  .input-file-message-drag {
    width: 100%;
    /* display: flex; */
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    margin-bottom: 12px;

    input {
      position: absolute;
      width: 100%;
      opacity: 0;
      top: 0;
      left: 0;
      height: 100%;
      z-index: 2;
    }
    &__dashed {
      width: 100%;
      border-radius: 5px;
      overflow: hidden;
      position: relative;
      text-align: center;
      padding: 10px;
      margin-bottom: 20px;

      &::before {
        content: '';
        position: absolute;
        border: 4px dashed #bcc5d7;
        top: -3px;
        bottom: -3px;
        left: -3px;
        right: -3px;
      }
    }
    &__container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    &__text {
      pointer-events: none;
      max-width: 200px;
      color: black;
      z-index: 2;
      span {
        font-weight: 500;
      }
    }
  }

  .input-file-message-disclaimer {
    margin-bottom: 32px;
    color: black;
  }
  .input-file-message-bottom {
    display: flex;
    justify-content: space-between;
    &__left {
      display: flex;
      .input-file-message-button {
        margin-right: 20px;
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }
  .input-file-message-button {
    width: 140px;
  }

  .input-file-message-label {
    color: #80A7C5;
    text-transform: uppercase;
  }

  .input-file-message-counter {
    margin-left: 32px;
    display: flex;
    align-items: center;
    line-height: normal;
    font-size: 16px;
    text-align: right;
    color: #004f8b;

    &__icon {
      margin-bottom: 3px;
    }
  }

  .input-file-message-select  {
    margin-bottom: 40px;
  }

  .input-file-message-label  {
    color: #80A7C5;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }

  &.required {
    .input-file-message-label  {
      padding-left: 12px;
      position: relative;
    }
  }

  .input-box-main-field {
    position: relative;
    border: 1px solid #EAEDF3;
    border-radius: 4px;
    display: block;
    width: 100%;
    background: white;
    padding: 7px 15px;
    transition: .2s all;
    outline: none;
    font-size: 0.875rem;
    height: 38px;
    margin-top: 0.5rem;

    p {
      cursor: inherit;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    &:hover {
      background: #f0fafd;
    }

    &:focus {
      background: #ffffff;
      box-shadow: 0px 2px 17px rgba(0, 0, 0, 0.05);
      outline: none;
      border-bottom: none;
    }
  }

  .input-box-main {
    position: relative;
  }

  .input-box-icon {
    position: absolute;
    top: 13px;
    right: 6px;
    width: 14px;
    z-index: 10;
    img {
      max-width: 12px;
      cursor: pointer;
    }
  }
  .input-box-error {
    color: #FF4100;
  }
`;


