import React, { useEffect, useState, useContext, ReactElement } from 'react'
import { withRouter } from 'react-router';
import ReactDOM from 'react-dom';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

// Types
import { iTrafficReportsProps } from './Demographic-type';

// Components
import TopBar from '../../../components/TopBar/TopBar';
import CardInfo from '../../../components/CardInfo/CardInfo';

// Styles
import DemographicReportsContainer from './Demographic-style';

// External library (Look how to inyect)
import * as uik from '../../../utils/third-party/@uik';
import Table from '../../../components/Table/Table';
import { Pie, Bar } from 'react-chartjs-2';
import { iRow } from '../../../models/general.model';
import { ICardHeaderSelect, ICardSelectOptions, ICardFooterRadio, ICardSelectInput } from '../../../components/CardInfo/CardInfo-type';
import { getWorldMapData, getAgeChartData, getGenderChartData, getLanguagesChartData } from '../../../services/reports.services';
import { ICountriesBackResp, ICountryInfo } from '../../../services/data-test/demographic';
import { images } from '../../../assets/images';
import MapBarIndicator from '../../../components/MapBarIndicator/MapBarIndicator';
import { TOOLTIPS_TEXT, ANALYTICS_TOKEN, CHART_COLORS, SELECT_PERIODICITY, SELECT_OPT_PERIODICITY } from '../../../constants/general';
import GeneralContext from '../../../context/general.context';
import { string } from 'prop-types';
import { numberFormat } from '../../../utils/text';
import Pagination from '../../../components/Pagination/Pagination';


const Snap = require('snapsvg-cjs');

const DemographicReports: React.FC<iTrafficReportsProps> = ({ history }) => {
  const general = useContext(GeneralContext);









  ////////////////////////////////////////////////////////////////
  ////////////////////// TO SHOW LOADER //////////////////////////
  ////////////////////////////////////////////////////////////////
  const [chartWasLoaded1, setChartWasLoaded1] = useState(false);
  const [chartWasLoaded2, setChartWasLoaded2] = useState(false);
  const [chartWasLoaded3, setChartWasLoaded3] = useState(false);
  const [chartWasLoaded4, setChartWasLoaded4] = useState(false);

  useEffect(() => {
    general.setLoading(true);
  }, []);

  useEffect(() => {

    if (chartWasLoaded1 && chartWasLoaded2 && chartWasLoaded3 && chartWasLoaded4)
      general.setLoading(false);
  }, [
    chartWasLoaded1,
    chartWasLoaded2,
    chartWasLoaded3,
    chartWasLoaded4
  ]);
  ////////////////////////////////////////////////////////////////
  //////////////////// END TO SHOW LOADER ////////////////////////
  ////////////////////////////////////////////////////////////////  


















  ////////////////////////////////////////////////////////////////
  ///////////////////////// WOLRD MAP ////////////////////////////
  ////////////////////////////////////////////////////////////////
  const [worldMap, setWorldMap] = useState();
  const [tableWorldMap, setTableWorldMap] = useState();
  const [showWorldTable, setShowWorldTable] = useState(true);


  // for pagination
  const [pageWorldMapData, setPageWorldMapData] = useState<number>(1);
  const [totalPageWorldMapData, setTotalPageWorldMapData] = useState<number>(1);
  const [reloadItemsWorldMap, setReloadItemsWorldMap] = useState<boolean>(true);

  const changePageWolrdMapTable = (page: number) => {
    setPageWorldMapData(page);
    setReloadItemsWorldMap(true);
  }

  const populateMapData = (countries: ICountryInfo[]) => {

    const fillMapCountry = (countrie: ICountryInfo) => {
      const countryElem = document.getElementById(countrie.country);

      if (!countryElem) return;


      let percentage = countrie.users.percentage / 100;

      if (percentage < 0.1)
        percentage = 0.1

      countryElem.style.fill = CHART_COLORS['total-users']; // Move to const
      countryElem.style.fillOpacity = String(percentage);
      //countryElem.style.fill = getColor(countrie.users.percentage / 100);
    }

    const generateTooltip = (countrie: ICountryInfo) => {

      const tooltip: ReactElement = (
        <ReactTooltip id='Brazil-B' className='whiteClass margin-left' effect='solid'>
          <span>Usuarios: 1000</span>
        </ReactTooltip>
      )

      if (countrie.country == 'Brazil')
        ReactDOM.render(tooltip, document.getElementById(countrie.country));

    }

    countries.map((countrie: ICountryInfo, index: number) => {
      fillMapCountry(countrie);
      //generateTooltip(countrie);        
    })

  }

  const mapCardRadioOptions: ICardFooterRadio = {
    label: 'Mapa',
    name: 'map-select',
    selected: true,
    callback: (ev: any) => { setShowWorldTable(ev); setPageWorldMapData(1); setReloadItemsWorldMap(true); }
  }

  const getWolrdTableRows = (): iRow[] => {
    let rowsWorld: iRow[] = [];
    if (tableWorldMap)
      tableWorldMap.map((value: ICountryInfo) => {
        rowsWorld.push({
          country: {
            type: 'text',
            value: value.country
          },
          users: {
            type: 'text',
            value: numberFormat(value.users.count)
          }
        })
      })


    return rowsWorld;

  }
  const columnsWorldTable = ['País', 'Número de Usuarios'];
  const keyRowsWorldTable = ['country', 'users'];


  const initWorldMapData = async () => {
    try {
      const mapContainer = Snap('#map-container');


      if (mapContainer)
        await Snap.load(images.World, (snap: Snap.Element) => {
          setWorldMap(snap);
          mapContainer.append(snap);
        });


      const params = [
        'towns=' + general.currentCustomer.town.name,
        'startDate=2019-11-01',
        'endDate=' + moment().format('YYYY-MM-DD')
      ];

      // This is for change number of elements when get, bewteen world map and table 
      if (showWorldTable)
        params.push('totalPages=300');
      else {
        let nexTokenNumber = (pageWorldMapData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

        params.push('nextPage=' + nexTokenNumber);
      }

      const res = await getWorldMapData({
        loader: true,
        //setLoading: general.setLoading,
        setLoading: !setChartWasLoaded1 ? () => { } : general.setLoading,
        params: params
      });

      const data: ICountriesBackResp = res[0][general.currentCustomer.town.name];

      setTotalPageWorldMapData(data.pagination.total_pages);
      setTableWorldMap(data.countries);
      populateMapData(data.countries);

      setChartWasLoaded1(true);

    } catch (e) {

    }

  }


  useEffect(
    () => {
      if (general.currentCustomer.town.name && reloadItemsWorldMap) {
        initWorldMapData();
        setReloadItemsWorldMap(false);
      }
    }, [general.currentCustomer, showWorldTable, reloadItemsWorldMap]
  )
  ////////////////////////////////////////////////////////////////
  /////////////////////// END WOLRD MAP //////////////////////////
  ////////////////////////////////////////////////////////////////

































  ////////////////////////////////////////////////////////////////
  ///////////////////////// AGE CHART ////////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IAgeDataChartRange {
    range: string;
    users: string;
  }

  interface IAgeDataChartData {
    ranges: IAgeDataChartRange[];
  }

  interface IAgeDataChartObject {
    [key: string]: IAgeDataChartData;
  }

  interface IAgeDataChart {
    [key: number]: IAgeDataChartObject
  }

  const initAgeDataDefaultValue = (): IAgeDataChart => {
    return [
      {
        [general.currentCustomer.town.name]: {
          ranges: []
        }
      }
    ]
  }

  const [ labelCards, setLabelCards] = useState(SELECT_PERIODICITY['week']);

  const [ageDataChart, seAgeDataChart] = useState<IAgeDataChart>(initAgeDataDefaultValue());

  const formatAgeChartData = (data: IAgeDataChart) => {
    if (!data[0])
      return [];

    const formatedData = data[0][general.currentCustomer.town.name] ? data[0][general.currentCustomer.town.name].ranges.map((value: IAgeDataChartRange) => Number(value.users)) : [];

    return formatedData;
  }

  const getLabelsAgeChartData = (data: IAgeDataChart): string[] => {
    if (!data[0])
      return [];

    const formatedData = data[0][general.currentCustomer.town.name] ? data[0][general.currentCustomer.town.name].ranges.map((value: IAgeDataChartRange) => value.range) : [];

    return formatedData;
  }

  const dataBar = () => ({
    labels: getLabelsAgeChartData(ageDataChart),
    datasets: [{
      // label: "Población 01",
      backgroundColor: CHART_COLORS['total-users'],
      pointBorderColor: CHART_COLORS['total-users'],
      borderColor: CHART_COLORS['total-users'],
      data: formatAgeChartData(ageDataChart)
    }]
  });

  const optionsBars = {
    barValueSpacing: 20,
    scales: {
      yAxes: [{
        id: 'first-y-axis',
        type: 'linear',
        ticks: {
          beginAtZero: true,
          maxTicksLimit: 4,
          min: 0,
          callback: (label: any) => {
            return numberFormat(label);
          }
        },
        gridLines: {
          drawBorder: false
        }
      }],
      xAxes: [{
        barThickness: 60,
        gridLines: {
          display: false,
          drawBorder: false
        }
      }]
    },
    ticks: {

    },
    legend: {
      display: false,
      position: 'bottom',
      labels: {
        usePointStyle: true,
        boxWidth: 3,
        padding: 30,
        fontSize: 14
      }
    },

    tooltips: {
      label: 'reportsDemographicAgeChart',
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          return numberFormat(tooltipItem.value);
        }
      },
    },

    pointDot: false
  }

  //Load chart data
  const loadAgeChartData = async () => {
    const params = [
      'towns=' + general.currentCustomer.town.name,
      'startDate=' + multiChartLimitDates.start_date,
      'endDate=' + multiChartLimitDates.end_date,
      'type=' + multiChartLimitDates.type
    ];

    const res = await getAgeChartData({
      loader: true,
      //setLoading: general.setLoading,
      setLoading: !setChartWasLoaded2 ? () => { } : general.setLoading,
      params: params
    });

    seAgeDataChart(res);
    setChartWasLoaded2(true);
  }

  // Filter Select
  interface IMultiChartLimitDates {
    start_date: string;
    end_date: string;
    type: string;
  }

  const initMultiChartDefaultLimits = (): IMultiChartLimitDates => {
    return {
      start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      type: 'week'
    }
  }

  const [multiChartLimitDates, setMultiChartLimitDates] = useState<IMultiChartLimitDates>(initMultiChartDefaultLimits);

  const lineChartHeaderSelectOptions: ICardSelectOptions = {
    defaultValue: 'week',
    values: [
      {
        label: SELECT_OPT_PERIODICITY['daily'],
        value: ''
      },
      {
        label: SELECT_OPT_PERIODICITY['weekly'],
        value: 'week'
      },
      {
        label: SELECT_OPT_PERIODICITY['monthly'],
        value: 'month'
      }
    ]
  }

  const lineChartCardHeaderOptions: ICardHeaderSelect = {
    options: lineChartHeaderSelectOptions,
    leftText: 'Periodicidad',
    callback: (data: ICardSelectInput) => {
      formatAndSetNewTime(data);
    }
  }

  const formatAndSetNewTime = (data: ICardSelectInput) => {
    let resp;
    switch (data.value) {
      case 'week':
        resp = {
          start_date: moment().subtract(1, 'months').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'week'
        }
        break;
      case 'month':
        resp = {
          start_date: moment().subtract(1, 'years').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: 'month'
        }
        break;
      default:
        resp = {
          start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
          end_date: moment().format('YYYY-MM-DD'),
          type: ''
        }
        break;
    }
    setLabelCards(SELECT_PERIODICITY[data.value as string]);
    setMultiChartLimitDates(resp);
  }


  useEffect(() => {
    if (general.currentCustomer.town.name)
      loadAgeChartData();
  }, [general.currentCustomer, multiChartLimitDates])
  ////////////////////////////////////////////////////////////////
  /////////////////////// END AGE CHART //////////////////////////
  ////////////////////////////////////////////////////////////////

























  ////////////////////////////////////////////////////////////////
  ////////////////////////// GENDER CHART ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface IGenderChartDataHuman {
    count: string;
    percentage: string;
  }

  interface IGenderChartData {
    female: IGenderChartDataHuman,
    male: IGenderChartDataHuman,
    total: string;
  }

  interface IGenderDataChartObject {
    [key: string]: IGenderChartData;
  }

  interface IGenderDataChart {
    [key: number]: IGenderDataChartObject
  }

  const initGenderDataDefaultValue = (): IGenderDataChart => {
    return [
      {
        [general.currentCustomer.town.name]: {
          female: {
            count: '',
            percentage: ''
          },
          male: {
            count: '',
            percentage: ''
          },
          total: ''
        }
      }
    ]
  }


  const [genderDataChart, seGenderDataChart] = useState<IGenderDataChart>(initGenderDataDefaultValue());

  const formatGenderChartData = (data: IGenderDataChart): number[] => {
    let formatedData: number[] = [];
    if (!data[0])
      return formatedData;

    const currentTownName = data[0][general.currentCustomer.town.name];

    if (currentTownName && (currentTownName.female || currentTownName.male)) {
      formatedData[0] = Number(currentTownName.male ? currentTownName.male.count : 0);
      formatedData[1] = Number(currentTownName.female ? currentTownName.female.count : 0);
    } else {
      formatedData = [0, 0]
    }


    return formatedData;
  }

  const dataPie = () => ({
    labels: [
      'Hombre',
      'Mujer'
    ],
    datasets: [{
      data: formatGenderChartData(genderDataChart),
      backgroundColor: [
        CHART_COLORS['total-users'],
        CHART_COLORS['new-users']
      ],
      hoverBackgroundColor: [
        CHART_COLORS['total-users'],
        CHART_COLORS['new-users']
      ]
    }]
  });

  const optionsPie = {
    scales: {
      yAxes: [{
        angleLines: {
          display: false
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }],
      xAxes: [{
        angleLines: {
          display: false
        },
        gridLines: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        }
      }]
    },
    legend: {
      position: 'bottom',
      boxWidth: 5,
      labels: {
        usePointStyle: true,
        boxWidth: 6,
        padding: 30,
        fontSize: 14
      }
    },

    tooltips: {
      label: 'reportsDemographicGenderChart',
      callbacks: {
        label: function (tooltipItem: any, data: any) {
          return numberFormat(data.datasets[0].data[tooltipItem.index]);
        }
      },
    },


    pointDot: false
  }

  //Load chart data
  const loadGenderChartData = async () => {
    try {
      const params = [
        'towns=' + general.currentCustomer.town.name,
        'startDate=2019-11-01',
        'endDate=' + moment().format('YYYY-MM-DD')
      ];

      const res = await getGenderChartData({
        loader: true,
        //setLoading: general.setLoading,
        setLoading: !setChartWasLoaded3 ? () => { } : general.setLoading,
        params: params
      });

      seGenderDataChart(res);
      setChartWasLoaded3(true);


    } catch (e) {
      seGenderDataChart([]);

    }
  }


  useEffect(() => {
    if (general.currentCustomer.town.name)
      loadGenderChartData();
  }, [general.currentCustomer])
  ////////////////////////////////////////////////////////////////
  /////////////////////// END GENDER CHART ///////////////////////
  ////////////////////////////////////////////////////////////////

































  ////////////////////////////////////////////////////////////////
  //////////////////////// LANGUAGE CHART ////////////////////////
  ////////////////////////////////////////////////////////////////
  interface ILanguageChartDataHuman {
    count: string;
    language: string;
  }

  interface ICategoriesChartPagination {
    next_page_token: string;
    total: number;
    total_pages: number;
  }

  interface ILanguageChartData {
    languages: ILanguageChartDataHuman[];
    pagination: ICategoriesChartPagination;
  }

  interface ILanguageDataChartObject {
    [key: string]: ILanguageChartData;
  }

  interface ILanguageDataChart {
    [key: number]: ILanguageDataChartObject
  }

  const initLanguageDataDefaultValue = (): ILanguageDataChart => {
    return [
      {
        [general.currentCustomer.town.name]: {
          languages: [],
          pagination: {
            next_page_token: "",
            total: 0,
            total_pages: 0
          }
        }
      }
    ]
  }

  const [languageTableData, setLanguageTableData] = useState<ILanguageDataChart>(initLanguageDataDefaultValue());

  // for pagination
  const [pageLanguageTableData, setPageLanguageTableData] = useState<number>(1);
  const [totalPageLanguageTableData, setTotalPageLanguageTableData] = useState<number>(1);
  const [reloadItemsLanguageTable, setReloadItemsLanguageTable] = useState<boolean>(true);

  const changePageLanguageTable = (page: number) => {
    setPageLanguageTableData(page);
    setReloadItemsLanguageTable(true);
  }


  const getLanguageRows = (): iRow[] => {

    if (!languageTableData[0])
      return [];

    if (!languageTableData[0][general.currentCustomer.town.name])
      return [];

    const currentTownData = languageTableData[0][general.currentCustomer.town.name];
    let categoriesRow: iRow[] = [];

    currentTownData.languages.map((value: ILanguageChartDataHuman) => {
      categoriesRow.push({
        language: {
          value: value.language,
          type: 'text'
        },
        views: {
          value: numberFormat(Number(value.count)),
          type: 'text'
        }
      })
    })

    return categoriesRow;
  }

  const columns = ['Idioma', 'Visitas'];

  const keyRows = ['language', 'views'];

  //Load chart data
  const loadLanguageTableData = async () => {
    try {
      let nexTokenNumber = (pageLanguageTableData - 1) * ANALYTICS_TOKEN; // 8 porque siempre analitycs devolverá eso, no hay tiempo para pensarlo dínamico

      const params = [
        'towns=' + general.currentCustomer.town.name,
        'startDate=2019-11-01',
        'endDate=' + moment().format('YYYY-MM-DD'),
        'nextPage=' + nexTokenNumber
      ];

      const res = await getLanguagesChartData({
        loader: true,
        //setLoading: general.setLoading,
        setLoading: !setChartWasLoaded4 ? () => { } : general.setLoading,
        params: params
      });

      setLanguageTableData(res);
      setTotalPageLanguageTableData(res[0][general.currentCustomer.town.name].pagination.total_pages);

      setChartWasLoaded4(true);

    } catch (e) {

    }
  }


  useEffect(() => {
    if (general.currentCustomer.town.name && reloadItemsLanguageTable) {
      loadLanguageTableData();
      setReloadItemsLanguageTable(false);
    }
  }, [general.currentCustomer, reloadItemsLanguageTable])

  useEffect(() => {
    if (general.currentCustomer.town.name)
      getLanguageRows();
  }, [languageTableData])

  ////////////////////////////////////////////////////////////////
  ///////////////////// END LANGUAGE CHART ///////////////////////
  ////////////////////////////////////////////////////////////////

















  // Detect if show or not "No data" screen on cardInfo
  const detectIfAgeChartHaveData = (): boolean => {
    if (ageDataChart[0] && ageDataChart[0][general.currentCustomer.town.name])
      return ageDataChart[0][general.currentCustomer.town.name].ranges.length == 0 ? true : false

    return false;
  }


  const detectIfGenderChartHaveData = (): boolean => {
    if (genderDataChart[0] && genderDataChart[0][general.currentCustomer.town.name])
      return !genderDataChart[0][general.currentCustomer.town.name].total ? true : false

    return false;
  }
  // End detect








  


  // Get third party components
  const {
    UikTopBarSection,
    UikTopBarTitle,
    UikLayoutMain,
  } = uik;

  return (
    <DemographicReportsContainer className='page'>

      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportDemographic} alt="Icono de Datos demográficos" />
          </div>
          <UikTopBarTitle>
            Datos demográficos
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>

      <UikLayoutMain className={'wrapper content'}>

        <div className='grid demographic-grid'>

          <div id='report-line-chart' className='col-9 col-lg-12'>
            <CardInfo
              headerTitle='Edad'
              height='350'
              headerSelect={lineChartCardHeaderOptions}
              expand={true}
              statusHeaderText={labelCards}
              contentClass='card-content-bar-chart demographic'
              tooltipText={TOOLTIPS_TEXT['grafico-edad']}
              showDisableScreen={detectIfAgeChartHaveData()}
              showDisableScreenText={'No hay datos suficientes para cargar la gráfica.'}
              disableScreenClass={'middle-screen'}

            >
              <Bar height={70} data={dataBar()} options={optionsBars}></Bar>
            </CardInfo>
          </div>

          <div id='report-circle-chart' className='col-3 col-lg-6'>
            <CardInfo
              headerTitle='Sexo'
              height='350'
              tooltipText={TOOLTIPS_TEXT['grafico-sexo']}
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              contentClass={'justifyc-content-flex'}
              showDisableScreen={detectIfGenderChartHaveData()}
              showDisableScreenText={'No hay datos suficientes para cargar la gráfica.'}
              disableScreenClass={'middle-screen'}
            >
              <div id='report-circle-chart-content'>
                <Pie width={250} height={250} data={dataPie} options={optionsPie}></Pie>
              </div>
            </CardInfo>
          </div>



          <div id='worldmap-card-info' className='col-9 col-lg-12'>
            <CardInfo
              headerTitle='Desde dónde te visitan'
              // headerSelect={mapCardHeaderOptions}
              // footerSelect={mapCardFooterOptions}
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              footerRadio={mapCardRadioOptions}
              height='546'
              expand={true}
              contentClass={'justifyc-content-flex'}
              tooltipText={TOOLTIPS_TEXT['desde-donde-te-visitan']}
              flexFlow='column'
            >
              {
                (!showWorldTable) ?
                  (
                    <>
                      <Table
                        rows={getWolrdTableRows()}
                        columns={columnsWorldTable}
                        keyRows={keyRowsWorldTable}
                        handleOrder={() => { }}
                      />
                      <div className="container-pagination">
                        <Pagination page={pageWorldMapData} limit={totalPageWorldMapData} callback={(page: number) => changePageWolrdMapTable(page)} />
                      </div>
                    </>
                  )
                  :
                  (
                    <>
                      <div id='map-container'></div>
                      <div id='map-indicator-bar-container'>
                        <MapBarIndicator color='#124526' />
                      </div>
                    </>
                  )
              }

            </CardInfo>
          </div>
          <div id='report-language-table' className='col-3 col-lg-6'>
            <CardInfo
              headerTitle='Idioma'
              statusHeaderText={'Datos históricos, actualizados a día de hoy *'}
              height='546'
              tooltipText={TOOLTIPS_TEXT['grafico-idioma']}
              flexFlow='column'
            >
              <>
                <Table
                  rows={getLanguageRows()}
                  columns={columns}
                  keyRows={keyRows}
                  handleOrder={() => { }}
                />
                <div className="container-pagination">
                  <Pagination page={pageLanguageTableData} limit={totalPageLanguageTableData} callback={(page: number) => changePageLanguageTable(page)} />
                </div>
              </>
            </CardInfo>
          </div>

        </div>
      </UikLayoutMain>

    </DemographicReportsContainer>
  );
}


export default withRouter(DemographicReports);