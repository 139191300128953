import React from 'react';
import * as uik from '../../utils/third-party/@uik';

// Type 
import {IRadioAlx}  from './Radio-type';

// Style
import RadioContainer from './Radio-style';

const RadioAlx: React.FC<IRadioAlx> = ({
  label, onChange, errorMessage, value,
  options, placeholder, position, className }) => {


  const { UikRadio } = uik;

  return (
    <RadioContainer className={className}>

      {
        options.map((value: any, index: number) => {
          return <UikRadio
            key={index + '-' + value.value}
            defaultChecked={index === 2}
            label={value.label}
            name={value.name}
            value={value.value}
            onChange={(ev: any) => {onChange(ev)}}
          />
        })
        
      }

    </RadioContainer >
  );
}

export default RadioAlx;