import React, { useState, useContext, SyntheticEvent, useEffect } from 'react'
import * as uik from '../../utils/third-party/@uik';
import { inputValue, ValueForm } from '../../models/general.model';
import { isValidEmail, isValidPassword, isValidDocumentID, isValidPhone_ESP, isValidPhone, isValidNumber, isValidCard } from '../../utils/validations';
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import Select from '../Select/Select';
import { isValidIban } from '../../utils/iban';
import InputFile from '../InputFile/InputFile';
import CardInfo from '../CardInfo/CardInfo';
import FormCustomContainer from './Form-style';
import { images } from '../../assets/images';
import Textarea from '../Textarea/Textarea';
import IconComponent from '../Icon/Icon';


interface iFormProps {
  dataForm: inputValue;
  handleAccept: Function;
  buttonText: string;
  titleForm?: string;
  docType?: 'NIF' | 'NIE' | 'DNI' | 'PASP' | 'NIFP';
  keyDocType?: string;
  reloadForm?: boolean;
  setReloadForm?: Function;
  handleBack?: Function;
  tooltipText?: string;
  extraAction?: {
    text: string;
    handleAction?: Function; 
  }

  showDisableScreen?: boolean;
  showDisableScreenText?: string;
  disableScreenClass?: string;
}

interface AttachedFile {
  name: string;
  size: string;
  creation_date: string;
  error: boolean;
  data: any;
}

const Form: React.FC<iFormProps> = ({ dataForm, buttonText, handleAccept, tooltipText,
  titleForm, docType, keyDocType, reloadForm, setReloadForm, handleBack, extraAction, showDisableScreen,
  showDisableScreenText, disableScreenClass }) => {

  const [data, setData] = useState<any>(dataForm);
  const [showPassword, setShowPassword] = useState<any>({});

  useEffect(() => {

    if (reloadForm) {
      setData({ ...dataForm })
      setReloadForm && setReloadForm(false);
    }
  }, [reloadForm]);

  const { UikButton, UikInput,
     UikWidgetContent, Uikon } = uik;

  const validateForm = (): void => {
    let isOk = true;

    if (showDisableScreen) {
      isOk = false;
      return;
    }
      

    Object.keys(data).forEach((key: string) => {
      if (data[key].required && data[key].value === '') {
        data[key].error = true;
        data[key].errorCode = 'required';
        isOk = false;
      } else if (data[key].type === 'email' && !isValidEmail(data[key].value)) {
        data[key].errorCode = 'invalid-email';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'doc-number' && !isValidDocumentID(data[key].value, keyDocType ? data[keyDocType].value : (docType || 'NIF'))) {
        data[key].errorCode = 'invalid-format';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type.indexOf('password') > -1 && !isValidPassword(data[key].value)) {
        data[key].errorCode = 'invalid-password';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'confirm-password' && data[key].value !== data['password'].value) {
        data[key].errorCode = 'repeat-password';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'phone-spain' && !isValidPhone_ESP(data[key].value)) {
        data[key].errorCode = 'invalid-format';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'mobile-phone' && data[key].value !== '' && !isValidPhone(data[key].value)) {
        data[key].errorCode = 'invalid-format';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'number' && !isValidNumber(data[key].value)) {
        data[key].errorCode = 'number-error';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'iban' && !isValidIban(data[key].value)) {
        data[key].errorCode = 'invalid-iban';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'card' && !isValidCard(data[key].value)) {
        data[key].errorCode = 'invalid-card';
        data[key].error = true;
        isOk = false;
      } else if (data[key].type === 'array' &&
        data[key].required && data[key].value.filter((v: string) => v !== '').length === 0) {
        data[key].errorCode = 'invalid-array';
        data[key].error = true;
        isOk = false;
      } else {
        data[key].errorCode = '';
        data[key].error = false;
      }
    })

    setData({ ...data });
    if (isOk) {
      handleAccept(data);
    }
  }

  const handleChange = (value: any, key: string, index?: number, customSetData?: any) => {
    if (index !== undefined) {
      data[key].value[index] = value;
    } else {
      data[key].value = value;
    }
    setData({ ...data });

    if (customSetData) {
      customSetData(data);
    }

  }

  const handleShowPassword = (key: string) => {
    showPassword[key] = !showPassword[key];
    setShowPassword({ ...showPassword });
  }

  const setAddValue = (key: string) => {
    data[key].value.push('');
    setData({ ...data });
  }
  const setRemoveValue = (key: string, index: number) => {
    data[key].value.splice(index, 1);

    setData({ ...data });
  }

  const renderInputs = (key: string, input: ValueForm) => {
    //TODO: input date and file 
    switch (input.render) {
      case 'input':
        return (
          <div key={`input-${key}`} className={input.className}>
            <UikInput
              id={input.id ? input.id : ''}
              className={input.id ? input.id : ''}
              label={input.label}
              placeholder={input.placeholder}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              disabled={input.disabled}
              onChange={(ev: any) => handleChange(ev.target.value, key, undefined, input.handleChange)}
            />
          </div>);
      case 'textarea':
        return (
          <div key={`input-${key}`} className={input.className}>
            <Textarea
              id={input.id ? input.id : ''}
              label={input.label}
              className={input.id ? input.id : ''}
              placeholder={input.placeholder}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              rows={input.rows}
              maxLength={input.maxLength}
              onChange={(value: string) => handleChange(value, key,  undefined, input.handleChange)}
            />
          </div>);       
      case 'array':
        return (
          <div key={`input-${key}`} className={input.className}>
            <div className='add-new-item'>
              <UikButton
                onClick={() => setAddValue(key)}
              >
                Agregar nuevo
              </UikButton>
            </div>
            
            {input.value.map((v: string, index: number) => (
              <div key={`input-${key}-${index}`} className={`flex-container ${input.className}`.trim()}>
                <UikInput
                  id={input.id ? input.id+index: ''}
                  label={input.label}
                  className={input.id ? input.id : ''}
                  placeholder={input.placeholder}
                  errorMessage={ERROR_MESSAGES[input.errorCode]}
                  value={v}
                  onChange={(ev: any) => handleChange(ev.target.value, key, index, input.handleChange)}

                />
                <div className='delete-single-item'>
                  
                  <div className='delete-single-icon' onClick={() => setRemoveValue(key, index)}>
                    <img src={images.iconDeleteTrash} alt='Delete single icon'></img>
                  </div>
                  
                </div>                

              </div>
            ))}

            {
              (input.value.length == 0) ? 
                (              
                  <div key={`input-${key}-${0}`} className={`flex-container ${input.className}`.trim()}>
                    <UikInput
                      id={'a-'+0}
                      label={input.label}
                      placeholder={input.placeholder}
                      className={input.id ? input.id : ''}
                      errorMessage={ERROR_MESSAGES[input.errorCode]}
                      value={''}
                      onChange={(ev: any) => handleChange(ev.target.value, key, 0, input.handleChange)}

                    />
                    <div className='delete-single-item'>
                      
                      <div className='delete-single-icon' onClick={() => setRemoveValue(key, 0)}>
                        <img src={images.iconDeleteTrash} alt='Delete single icon'></img>
                      </div>
                      
                    </div>                

                  </div>
                )
              : 
                (<></>)
            }
          </div>
        )
      case 'select':
        return (
          <div key={`input-${key}`} className={input.className}>
            <Select
              position="bottomRight"
              className={input.error ? 'error' : ''}
              label={input.label || ''}
              options={input.options || []}
              placeholder={input.placeholder || ''}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value.value ? [input.value] : ''}
              onChange={(value: any) => handleChange(value, key)}
            />
          </div>);
      case 'password':
        return (
          <div key={`input-${key}`} className={input.className}>
            <UikInput
              label={input.label}
              placeholder={input.placeholder}
              type={showPassword[key] ? "text" : "password"}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              onChange={(ev: any) => handleChange(ev.target.value, key)}
              icon={(
                <IconComponent
                  name="IconEyeGray"
                  onClick={() => handleShowPassword(key)}
                  className={[showPassword[key] ? 'active-icon' : 'inactive-icon', 'pointer'].join(' ')}
                />
              )}
              iconPosition="right"
            />
          </div>);
      case 'file':
        return (
          <div key={`input-${key}`} className={input.className}>
            <InputFile
              label={input.label}
              placeholder={input.placeholder || ''}
              required={input.required}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value.name}
              onChange={(files: AttachedFile[]) => handleChange(files[0], "file")}
              accept={input.accept || ["application/pdf", "image/*"]}
            />
            {input.picture && (
              <img src={input.picture} alt="picture" />
            )}
          </div>
        )
      default:
        return (
          <div key={`input-${key}`} className={input.className}>
            <UikInput
              label={input.label}
              placeholder={input.placeholder}
              errorMessage={ERROR_MESSAGES[input.errorCode]}
              value={input.value}
              onChange={(ev: any) => handleChange(ev.target.value, key)}
            />
          </div>);
    }
  }

  return (
    <FormCustomContainer>
      <CardInfo
        headerTitle={titleForm}
        addSpace={true}
        tooltipText={tooltipText}
        showDisableScreen={showDisableScreen}
        showDisableScreenText={showDisableScreenText}
        disableScreenClass={disableScreenClass}
      >
        <form onSubmit={(ev: any) => ev.preventDefault()}>
          <UikWidgetContent>
            <div className="grid">
              {Object.keys(dataForm).length > 0 && (
                Object.keys(dataForm).map((key: string) => (
                  renderInputs(key, dataForm[key])
                ))
              )}
            </div>

          </UikWidgetContent>
          <UikWidgetContent>
            <div className="button-container">
              <UikButton
                type="submit"
                success
                onClick={validateForm}
              >
                {buttonText}
              </UikButton>
            </div>
            {handleBack && (
              <div className="button-container">
                <UikButton
                  className="margin-right"
                  onClick={handleBack}
                >
                  Atrás
                  </UikButton>
              </div>
            )}
            {extraAction && (
              <div className="button-container float-right">
                <UikButton
                  className="margin-right"
                  onClick={extraAction.handleAction}
                >
                  {extraAction.text}
                  </UikButton>
              </div>
            )}            
          </UikWidgetContent>
        </form>
      </CardInfo>
    </FormCustomContainer>
  );
}

export default Form;
