import React, { useEffect } from 'react'
import { withRouter, Route } from 'react-router';
import ReportsContainer from './Reports-style';

//types
import { iReportsProps } from './Reports-type';

//Child Components
import GeneralReports from './general/General';
import DemographicReports from './demographic/Demographic';
import RankingReports from './ranking/Ranking';
import TrafficReports from './traffic/Traffic';
import InterestsReports from './interests/Interests';
import HistoryReports from './history/History';


const Reports: React.FC<iReportsProps> = ({ history, location }) => {
  
  const toggleReportsMenu = (currentLocation: string) => {
    if ( (currentLocation.match(/^\/reports$/) || currentLocation.match(/^\/$/)) && (localStorage.getItem('idUser') !== null) )
      history.push('/reports/general');
  }

  history.listen((location) => {
    toggleReportsMenu(location.pathname)
  });

  useEffect(() => {
    toggleReportsMenu(location.pathname);
  },[]);

  return (
    <ReportsContainer className='page'>
      <Route path="/reports/general" component={GeneralReports} />
      <Route path="/reports/traffic" component={TrafficReports} />
      <Route path="/reports/demographic" component={DemographicReports} />
      <Route path="/reports/interests" component={InterestsReports} />
      <Route path="/reports/ranking" component={RankingReports} />
      <Route path="/reports/historial" component={HistoryReports} />
    </ReportsContainer>
  );
}




export default withRouter(Reports);

