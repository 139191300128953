import styled from "styled-components";

export const InfoModalContainer = styled.div`
  display: none;
  color: black;
  position: fixed;
  z-index: 9999;
  background: #ffffffcc;
  left: 0;
  width: 100%;
  top: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .main-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: 0.3s all ease-in-out;
  }

  .info-modal-icon-container {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;

    & > img  {
      width: 60px;
    }
  }

  &.active {
    display: flex;
  }

  &.error {
    color: rgba(222, 75, 75, 1);

    .info-modal-icon-bg {
      background: rgba(222, 75, 75, 0.3);
    }

    .main-button {
      /* background: rgba(222,75,75, 1); */
      color: #de4b4b;
      border: 1px solid #de4b4b;
    }
  }

  &.success {
    .main-button {
      background: rgba(36, 132, 137, 1);
      color: white;
    }

    color: rgba(36, 132, 137, 1);

    .info-modal-icon-bg {
      background: rgba(36, 132, 137, 0.3);
    }
  }

  &.warning {
    color: rgba(238, 178, 34, 1);

    .info-modal-icon-bg {
      background: rgba(238, 178, 34, 0.3);
    }

    .main-button {
      /* background: rgba(238,178,34, 1); */
      color: #eeb222;
      border: 1px solid #eeb222;
    }
  }

  .info-modal-container {
    background: #ffffff;
    border-radius: 3px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
    padding: 22px 22px 40px;
    max-width: 620px;
    max-height: 90vh;
    overflow-y: scroll;
  }

  .info-modal-header {
    display: flex;
    justify-content: space-between;
    .info-modal-header-title {
      p {
        color: #3e3f42;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
      }
    }
    .info-modal-close {
      text-align: right;

      img {
        max-width: 12px;
        cursor: pointer;
      }
    }
  }

  .uik-divider {
    &__horizontal {
      margin-top: 22px;
      margin-bottom: 32px;
    }
  }

  .info-modal-body {
    .info-modal-alert {
      padding: 8px 0px 8px 12px;
      margin-bottom: 22px;
      background: #ca9b9b;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
      }
    }
    .info-modal-text {
      margin-bottom: 32px;
      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
      }
    }
  }

  .info-modal-button {
    margin-top: 34px;
    button {
      width: 100%;
      height: 38px;
    }
  }

  @supports (-ms-ime-align: auto) {
    background: rgba(0, 0, 0, .3);
  }
  
}


`;
