import React, { useContext, useEffect, useState } from 'react'

import * as uik from '../../utils/third-party/@uik';

import GeneralContext from '../../context/general.context'
import { RouteComponentProps, withRouter, Route } from 'react-router';

// Style
import SettingsContainer from './Settings-style';
import CardCircleInfo from '../../components/CardCircleInfo/CardCircleInfo';
import SimpleSideMenu from '../../components/SimpleSideMenu/SimpleSideMenu';
import { ISimpleSideMenuOption } from '../../components/SimpleSideMenu/SimpleSideMenu-type';

import SettingsBasicInfo from './Basic-info/Basic-info';
import SettingsExtraInfo from './Extra-info/Extra-info';
import InfoModal from '../../components/InfoModal/InfoModal';
import { iModal } from '../../models/general.model';
import { uploadImageCustomer, getCurrentCustomer, editCustomer } from '../../services/customer.services';
import { images } from '../../assets/images';
import moment from 'moment';
import { useExtraInfo } from './hooks/extra-info/Extra-info-hook';
import { element } from 'prop-types';

interface RouteParams {
  id: string
}

interface iSettingsProps extends RouteComponentProps<RouteParams> { }

const Settings: React.FC<iSettingsProps> = ({ history, location }) => {
  const general = useContext(GeneralContext);

  const [showSaveAllButton, setShowSaveAllButton] = useState(false);
  const {practicalInfo} = useExtraInfo();

  const toggleReportsMenu = (currentLocation: string) => {
    if (currentLocation.match(/^\/settings$/))
      history.push('/settings/basicInfo');

    if (currentLocation.match(/^\/settings\/extraInfo$/)) 
      setShowSaveAllButton(true)
    else
      setShowSaveAllButton(false)
  }

  history.listen((location) => {
    toggleReportsMenu(location.pathname)
  });

  useEffect(() => {
    toggleReportsMenu(location.pathname);
  },[]);
  
  const cardCircleFooterText = {
    firstText: 'Tamaño mínimo',
    lastText: '200 x 200'
  };

  const sideMenuOpts: ISimpleSideMenuOption[] = [{
    name: 'Perfil',
    value: 'perfil',
    to: 'basicInfo'
  },{
    name: 'Información de interés',
    value: 'important-info',
    to: 'extraInfo'
  }];

  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    handleClose: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });

  function closeModal(this: any) {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      handleClose: () => {},
      text: '',
      show: false,
      title: '',
      alert: '',
      className: ''
    })

    if (this)
      document.location.reload();
  }  


  const handleImageUpload = (file: File) => {

    setModal({
      handleClose: closeModal,
      code: '',
      button: 'Sí, subir imagen',
      handleAccept: uploadImageProfile.bind(file),
      text: 'Estás a punto de cambiar tu imagen de perfil, ¿Estás seguro de que quieres continuar?',
      show: true,
      title: 'Cambio de imagen de perfil',
      alert: '',
      className: 'success'
    });
  }
  
  async function uploadImageProfile(this: any) {
    const data = new FormData();
    data.set('file', this);

    try {
      await uploadImageCustomer({
        id: localStorage.getItem('idUser'),
        loader: true, 
        data: data,
        setLoading: general.setLoading       
      });

      setModal({
        code: 'ok',
        text: 'Se ha subido su nueva imagen exitosamente. Se recargará la página.',
        show: true,
        button: '',
        handleAccept: () => {},
        handleClose: reloadPage,
        title: 'Info',
        alert: '',
        className: 'success'
      });
      
      refreshCurrentCustomer();      
      
    } catch (err) {
      console.log("Error al subir imagen ", err);
      setModal({
        handleClose: closeModal,
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'warning'
      });
    }
  }

  const getLastUpdate = () => {
    if (general.currentCustomer && general.currentCustomer.updatedAt)
      return moment(general.currentCustomer.updatedAt).format('DD/MM/YYYY');
    
    return '--';
  }

  const saveAllExtraInfoChanges = async () => {
    
    const practicalInfo = document.getElementById('practical-info-extra-perfil') as HTMLInputElement;
    const history = document.getElementById('history-extra-perfil') as HTMLInputElement;
    const places = document.getElementsByClassName('places-extra-perfil') as HTMLCollectionOf<HTMLInputElement>;
    const placesArray = [].slice.call(places);
    const placesValues = placesArray.map( (elem: HTMLInputElement) => {if (elem.value != '') return elem.value} );

    const data = {
      'history': history.value,
      'description': practicalInfo.value,
      'places': placesValues
    }

    try {
      await editCustomer({
        id: localStorage.getItem('idUser'), 
        data: data, 
        loader: true, 
        setLoading: general.setLoading 
      });
      
      setModal({
        handleClose: closeModal.bind(true),
        code: 'ok',
        text: 'Se ha grabado toda la información correctamente',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Info',
        alert: '',
        className: ''
      });
      await refreshCurrentCustomer();
    } catch (err) {
      setModal({
        handleClose: closeModal,
        code: (err.response && err.response.status) ? err.response.status : 'err-local',
        text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
        show: true,
        button: '',
        handleAccept: () => { },
        title: 'Error',
        alert: '',
        className: 'warning'
      });

    }
  }

  const reloadPage = () => {
    document.location.reload() ;
  }

  const refreshCurrentCustomer = () => {
    getCurrentCustomer(true);
  }

  const getProfileImage = () => {
    if (!general.currentCustomer.profile || !general.currentCustomer.profile.src) 
      return images.picture

    return general.currentCustomer.profile.src.split('?')[0];
  }
  
  const { UikLayoutMain, UikWidgetContainer, UikButton } = uik;


  return (
    <SettingsContainer className={"page"}>
      <div id='settings-heroblock'></div>

      <UikLayoutMain className={"wrapper"}>
        <UikWidgetContainer className="grid">

          <div className="col-3 col-lg-4 card-circle-info">
            <CardCircleInfo size='220px'
              handleFileImage={handleImageUpload}
              footerText={cardCircleFooterText}
              actionText='Editar'
              image={getProfileImage()}
            />

            <div id='settings-aside-menu'>
              <SimpleSideMenu
                options={sideMenuOpts}
              />

              {

                (showSaveAllButton) ? 
                  (
                    <UikButton
                      type="submit"
                      success
                      onClick={saveAllExtraInfoChanges}
                    >
                      Guardar todos los cambios
                    </UikButton>
                  )
                :
                  (
                    <></>
                  )
              }

              <div className='last-change-container'>
                Ultima modificación: {getLastUpdate()}
              </div>


            </div>
          </div>

          <div id="forms-container" className="col-9 col-lg-8">
            <Route path="/settings/basicInfo" component={SettingsBasicInfo} />
            <Route path="/settings/extraInfo" component={SettingsExtraInfo} />
            
          </div>
      
          <InfoModal
            className={modal.className}
            onClickAccept={modal.handleAccept}
            mainText={modal.text}
            titleText={modal.title}
            alertText={modal.alert}
            buttonText={modal.button}
            active={modal.show}
            close={modal.handleClose}
          />

        </UikWidgetContainer>
      </UikLayoutMain>


    </SettingsContainer>
  );
}

export default withRouter(Settings);

