import React, { useState, useContext, SyntheticEvent } from 'react'

import * as uik from '../../utils/third-party/@uik';

import { iDataForgot } from '../../models/login.model';
import { isValidEmail } from '../../utils/validations';
import { forgotPassword } from '../../services/auth.services';

import GeneralContext from '../../context/general.context'
import { ERROR_MESSAGES } from '../../constants/errorMessages';
import { withRouter, RouteComponentProps } from 'react-router';
import { NavLink } from 'react-router-dom';
import InfoModal from '../../components/InfoModal/InfoModal';
import { images } from '../../assets/images';
import { iModal } from '../../models/general.model';

export interface iForgotPasswordProps extends RouteComponentProps { }

const ForgotPassword: React.FC<iForgotPasswordProps> = ({ history }) => {

  const [data, setData] = useState<iDataForgot>({
    username: {
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'email'
    },
  });
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    handleClose: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });
  const general = useContext(GeneralContext)


  const validateForm = (): boolean => {
    let isOk = true;

    Object.keys(data).forEach((key: string) => {
      if (data[key].required && data[key].value === '') {
        data[key].error = true;
        data[key].errorCode = 'required';
        isOk = false;
      } else if (data[key].type === 'email' && !isValidEmail(data[key].value)) {
        data[key].errorCode = 'invalid-email';
        data[key].error = true;
        isOk = false;
      } else {
        data[key].errorCode = '';
        data[key].error = false;
      }
    })

    setData({ ...data });
    return isOk;
  }

  const redirectToLogin = () => {
    history.push("/");
  }

  
  const handleForgotPassword = async () => {
    const isOk = validateForm();
    if (isOk) {
      const dataSend = {
        username: data.username.value,
        type: 'customer'
      }
      try {
        const res = await forgotPassword({
          loader: true,
          setLoading: general.setLoading,
          data: dataSend,
        })
        
        setModal({
          code: 'ok',
          text: 'Se ha enviado un email a tu correo.',
          show: true,
          button: '',
          handleAccept: () => {},
          handleClose: redirectToLogin,
          title: 'Info',
          alert: '',
          inputs: [],
          className: 'success-icon'
        });
      } catch (err) {
        data.username.error = true;
        data.username.errorCode = 'login-error';
        setData({ ...data });
        setModal({
          code: 'login-error',
          text: (err.response && err.response.description) ? err.response.description : 'Error inesperado, inténtelo más tarde.',
          show: true,
          button: '',
          handleAccept: () => { },
          title: 'Error',
          alert: '',
          className: 'warning'
        });
      }
    }
  }

  const handleChange = (e: SyntheticEvent) => {

    const target = e.target as HTMLInputElement;
    data[target.name].value = target.value;
    setData({ ...data });
  }

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      handleClose: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: ''
    })
  }

  const { UikWidgetContent,
    UikWidget,
    UikInput,
    UikFormInputGroup,
    UikHeadline,
    UikHeadlineDesc,
    UikDivider,
    UikButton,
    Uikon
  } = uik;

  return (
    <div className="pageWrapper">
      <UikWidget
        className="widgetWrapper"
      >
        <div className="content">
          <UikWidgetContent className="left">
            <UikHeadline>
              ¿Olvidaste la contraseña?
          </UikHeadline>
            <UikHeadlineDesc>
              Indica tu cuenta para que te enviemos un correo para modificar tu contraseña
          </UikHeadlineDesc>
            <form className="login" onSubmit={(e: SyntheticEvent) => e.preventDefault()}>
              <UikFormInputGroup>
                <UikInput
                  label="Correo"
                  name="username"
                  placeholder="your@email.com"
                  errorMessage={ERROR_MESSAGES[data.username.errorCode]}
                  value={data.username.value}
                  onChange={handleChange}
                />
              </UikFormInputGroup>
              <UikDivider margin />
              <UikButton
                className="btnAction"
                type="submit"
                // Component={NavLink}
                success
                onClick={handleForgotPassword}
              // to="/buildings"
              >
                Enviar
          </UikButton>
            </form>
            <div className="login-footer">
              <NavLink to="/">
                Iniciar sesión
            </NavLink>
            </div>
          </UikWidgetContent>
          <div className="right">
            <div className='text-image-container'>
              <img src={images.IconWorldLine} alt="Global network"/>
              <h3 className="headline">
                Panel de Gestión
              </h3>
            </div>
            <p className="desc">
              España Fascinante
            </p>
          </div>
        </div>
      </UikWidget>
      <p>
        Patrocinadores
      </p>
      <div className='sponsors-container'>
        <img src={images.ImagePatrocinante} alt='Lynx Patrocinante'></img>
      </div>

      <InfoModal
        className={modal.className}
        onClickAccept={modal.handleAccept}
        mainText={modal.text}
        titleText={modal.title}
        alertText={modal.alert}
        buttonText={modal.button}
        active={modal.show}
        close={() => modal.handleClose ? modal.handleClose() : closeModal()}
      />
    </div>
  )
}

export default withRouter(ForgotPassword);

