import React, { useState, useContext } from 'react';

//Types
import { UseExtraInfoType } from './Extra-info-hook-type';
import { iSettingsGeneric, iSettingsUser } from '../../../../models/users.models';
import { editCustomer, getCurrentCustomer } from '../../../../services/customer.services';
import GeneralContext from '../../../../context/general.context';

const useExtraInfo: UseExtraInfoType = () => {
  const general = useContext(GeneralContext);
  
  const handleChange = (data: any) => {
    setPracticalInfo({ ...data }); 
    //console.log(data, practicalInfo, setPracticalInfo);
  }

  const [practicalInfo, setPracticalInfo] = useState<iSettingsGeneric>({
    description: {
      id: 'practical-info-extra-perfil',
      value: '',
      error: false,
      errorCode: '',
      required: true,
      type: 'text',
      placeholder: 'Aparcamiento, fiestas, ferias, eventos, habitantes',
      render: 'textarea',
      className: 'col-12',
      handleChange: handleChange,
      rows: 5,
      maxLength: 25000
    }
  });

  const refreshCurrentCustomer = async () => {
    const currentCustomer = await getCurrentCustomer(true);
    general.setCurrentCustomer(currentCustomer);
  }

  const handlePractInfoDescp = async (dataForm: iSettingsUser) => {
    setPracticalInfo({ ...dataForm });
    const data = {
      description: dataForm.description.value
    }

    try {
      await editCustomer({
        id: localStorage.getItem('idUser'), 
        data: data, 
        loader: true, 
        setLoading: general.setLoading 
      });

      refreshCurrentCustomer();
    } catch (err) {
      throw err;
    }
  }

  return {
    practicalInfo,
    setPracticalInfo,
    handlePractInfoDescp,
    refreshCurrentCustomer
  }
}

export { useExtraInfo }