import React, { useState } from 'react'

import * as uik from '../../utils/third-party/@uik';

import SingleButton from '../SingleButton/single-button';
import { ThContainer, TableContainer } from './Table-style';
import { iRow, iItem } from '../../models/general.model';


interface iOrder {
  key: string;
  order: "ASC" | "DESC" | "";
}

interface iTableProps {
  rows: iRow[];
  columns: string[];
  columnsSpan?: number[];
  keyRows: string[];
  handleOrder: Function;
  showLoadingText?: Boolean;
}

const Table: React.FC<iTableProps> = ({ rows, columns, keyRows, handleOrder, columnsSpan, showLoadingText }) => {

  const [order, setOrder] = useState<iOrder>({ key: '', order: '' });
  const { Uikon } = uik;

  // const handleClickColum = (index: number) => {
  //   if (order.key === keyRows[index]) {
  //     if (order.order === '') {
  //       order.order = 'ASC';
  //     } else if (order.order === 'ASC') {
  //       order.order = 'DESC';
  //     } else if (order.order === 'DESC') {
  //       order.order = '';
  //     }
  //   } else {
  //     order.order = 'ASC';
  //     order.key = keyRows[index];
  //   }
  //   setOrder({ ...order });
  //   handleOrder(order);
  // }
  const handleClickColum = (index: number) => {
  }

  const renderValue = (item: iItem) => {

    switch (item.type) {
      case 'text':
        return item.value;
      case 'buttons':
        if (item.notShowMenu) return (<div></div>);
        return (
          <SingleButton
            className="small secondary"
            drop={true}
            icon={<Uikon>more</Uikon>}
          >
            {item.actions &&
              item.actions.map((action: any, key: number) => (
                <div key={`action-${key}`} className="single-button-drop__item" onClick={() => action.onClick()}>
                  {action.icon && (
                    <div className="single-button-drop__item__icon">
                      <SingleButton className="secondary" icon={action.icon} />
                    </div>
                  )}
                  <div className="single-button-drop__item__text">
                    <p>{action.label}</p>
                  </div>
                </div>
              ))}
          </SingleButton>
        )
      default:
        return item.value;
    }
  }

  return (
    <TableContainer>
      <thead>
        <tr>
          {columns.map((c: string, index: number) => (
            <ThContainer
              colSpan={columnsSpan ? columnsSpan[index] : 1} 
              className={`${(order.key === keyRows[index] && order.order !== '') ? `filter ${order.order === 'ASC' ? 'active' : ''}` : ''}`}
              key={`column-${c}-${index}`}
              onClick={() => handleClickColum(index)}>
              {c}
            </ThContainer>
          ))}
        </tr>
      </thead>
      <tbody>
        {
        rows.length !== 0 ?   
          (
            rows.map((row: iRow, index: number) =>{ 

            return (
              <tr key={`row-${row}-${index}`}>
                {keyRows.map((key: string, indexKey: number) => {
                  if (!row[key]) {
                    return (<td key={`row-${index}-${indexKey}`}></td>)
                  }
                  return (
                    <td
                      className={row[key].extraClass}
                      key={`row-${index}-${indexKey}`}
                      onClick={row[key].onClick && row[key].onClick}
                    >
                      {renderValue(row[key])}
                    </td>
                  )
                } 
                )}
              </tr>
            )
          })
          )
          :
          (
            <tr>
              {keyRows.map((key: string, index: number) => {
                return (<td key={`row-${index}`}>--</td>)
              } 
              )}
            </tr>
          )
        
        }

        
      </tbody>
    </TableContainer >
  )
}
export default Table;
