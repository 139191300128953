import React, { useState, useContext, useEffect } from 'react'
import { withRouter, RouteComponentProps } from 'react-router';
import ReactTooltip from 'react-tooltip';

import { getBills, getOneBillsDownload } from '../../../services/bills.services';

import GeneralContext from '../../../context/general.context'

import TopBar from '../../../components/TopBar/TopBar';
import Table from '../../../components/Table/Table';
import InfoModal from '../../../components/InfoModal/InfoModal';

import * as uik from '../../../utils/third-party/@uik';

import { iDelete, iModal } from '../../../models/general.model';

import HistoryReportsContainer from './History-style';
import CardInfo from '../../../components/CardInfo/CardInfo';
import Pagination from '../../../components/Pagination/Pagination';
import { iHistoryReports } from '../../../models/reports.models';

import { images } from '../../../assets/images';
import {TOTAL_ITEMS, TOOLTIPS_TEXT} from '../../../constants/general';
import { getCustomerReports } from '../../../services/reports.services';


export interface iHistoryReportsProps extends RouteComponentProps { }

const HistoryReports: React.FC<iHistoryReportsProps> = ({ history }) => {

  const general = useContext(GeneralContext);

  const [items, setItems] = useState<iHistoryReports[]>([]);
  const [page, setPage] = useState<number>(1);
  const [totalPage, setTotalPage] = useState<number>(1);

  const [reloadItems, setReloadItems] = useState<boolean>(true);
  const [order, setOrder] = useState<any>({ order: '', key: '' });
  const [modal, setModal] = useState<iModal>({
    code: '',
    text: '',
    button: '',
    handleAccept: () => { },
    show: false,
    alert: '',
    title: '',
    className: ''
  });


  useEffect(() => {
    if (reloadItems) {
      getItems();
      setReloadItems(false);
    }
  }, [reloadItems]);

  const getItems = async () => {
    const params: string[] = [];
    const offset = TOTAL_ITEMS * (page - 1);
    
    // Set page to get 
    params.push('limit='+TOTAL_ITEMS);
    params.push('offset='+offset);
    
    const items = await getCustomerReports({id: localStorage.getItem('idUser'), params: params, loader: true, setLoading: general.setLoading });
    
    setTotalPage(items.pages);
    setItems(items.results);
  }

  const handleOrder = (order: any) => {
    setOrder({ ...order });
    setReloadItems(true);
  }
  
  const handleStatus = async (item: any, state: string) => {
    try {
      const data = {
        status: state
      }

      
    } catch (err) {
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Cambiar estado factura',
        text: 'Intente cambiar estado de la factura más tarde.',
        className: 'error'
      });
    }
  }

  const handleDownloadItem = async (item: any) => {
    try {

      const anchor: HTMLAnchorElement = document.createElement('a');
      anchor.href = `${item.link}`;
      anchor.target = '_blank';
      anchor.download = `${item.customerUser}-factura.pdf`;//'facturas.pdf'; //file.name
      anchor.click();
      closeModal();
      anchor.remove();

    } catch (err) {
      
      setModal({
        code: 'error',
        button: '',
        handleAccept: () => { },
        alert: 'Error inesperado.',
        show: true,
        title: 'Descargar factura',
        text: 'Intente descargar la factura más tarde.',
        className: 'error'
      });
    }
  }

 

  const getRows = () => {
    let rows: any = [];
    
     items && items.map && items.map((item: any) => {

      rows.push({
        date: {
          value: item.date,
          type: 'text'
        },
        name: {
          value: 'Informe-' + item.date,
          type: 'text'
        },
        pdf: {
          value: (
            <div className="icon-custom-container transparent">
              <li className="oi icon-download"></li>,
            </div>
          ),
          type: 'icon',
          onClick: () => {
            setModal({
              code: item.id,
              button: 'Aceptar',
              handleAccept: () => handleDownloadItem(item),
              text: 'Se va a descargar el informe seleccionado',
              show: true,
              title: 'Descargar Informe',
              alert: '',
              className: 'success'
            });
          }
        }
      });
    })

    return rows;
  }

  const columns = ['Fecha', 'Nombre', 'PDF'];
  const keyRows = ['date', 'name', 'pdf'];

  const closeModal = () => {
    setModal({
      code: '',
      button: '',
      handleAccept: () => { },
      text: '',
      show: false,
      title: '',
      alert: '',
      className: ''
    })
  }

  const changePage = (page: number) => {
    setPage(page);
    setReloadItems(true);
  }

  const { UikTopBarSection, UikTopBarTitle,
    UikLayoutMain, UikWidget, Uikon } = uik;


  return (
    <HistoryReportsContainer className={"page"}>
      <TopBar>
        <UikTopBarSection>
          <div className="uik-top-bar-image__wrapper">
            <img src={images.IconReportHistorial} alt="Icono de historial" />
          </div>
          <UikTopBarTitle>
            Historial
          </UikTopBarTitle>
        </UikTopBarSection>
      </TopBar>
      <UikLayoutMain className={"wrapper"}>
        <UikWidget margin>
          <CardInfo
            headerTitle='Historial de informes'
            addSpace={true}
            flexFlow='column'
            tooltipText={TOOLTIPS_TEXT['historial-informes']}
          >
            <>
              <Table
                rows={getRows()}
                columns={columns}
                keyRows={keyRows}
                handleOrder={(order: any) => handleOrder(order)}
              />
              <div className="container-pagination">
                <Pagination page={page} limit={totalPage} callback={(page: number) => changePage(page)} />
              </div>
            </>
          </CardInfo>
        </UikWidget>
      </UikLayoutMain>

      <InfoModal
        className={modal.className}
        titleText={modal.title}
        alertText={modal.alert}
        mainText={modal.text}
        buttonText={modal.button}
        active={modal.show}
        onClickAccept={() => modal.handleAccept()}
        close={() => closeModal()}
      />
      
    </HistoryReportsContainer>
  );
}


export default withRouter(HistoryReports);

