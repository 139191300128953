/* eslint-disable max-len */
import React, { useContext, useState, useEffect } from 'react'
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom'
import classnames from 'classnames'
import GeneralContext from './../../context/general.context';

import * as uik from '../../utils/third-party/@uik';
import { NavigationContainer } from './Navigation-style';
import { images } from '../../assets/images';
import { noop } from '@babel/types';


interface iNavigationProps extends RouteComponentProps {
  className?: string
}

const Navigation: React.FC<iNavigationProps> = ({ className, history, location }) => {
  const { UikNavSection,
    UikNavPanel,
    UikContainerVertical,
    UikDivider,
    UikNavLinkTwo,
    UikScrollArea,
    UikNavUser,
    UikNavLinkTwoContainer,
    UikNavLinkSecondary,
    UikNavLink } = uik;

  const general = useContext(GeneralContext);
  let profileImg;
  if (general.currentCustomer.profile)
    profileImg = !general.currentCustomer.profile.src ? images.picture : general.currentCustomer.profile.src.split('?')[0];
  else
    profileImg = images.picture ;

  const logout = async () => {
    general.setIsLogged(false);
    await localStorage.clear();
    history.push("/");
    window.location.reload();
  }

  const handleHelp = () => {
    window.open("https://espaafascinante.typeform.com/to/pUZ6h5JG");
  }

  // Hook to show or hide reports secondary menu
  const [showReportsMenu, setShowReportsMenu] = useState(false);
  
  const toggleReportsMenu = (currentLocation: string) => {
    if (currentLocation.match(/^\/reports/))
      setShowReportsMenu(true);
    else
      setShowReportsMenu(false);
  }

  history.listen((location) => {
    toggleReportsMenu(location.pathname)
  });

  useEffect( () => {
    // First init
    toggleReportsMenu(location.pathname);
  }, []);
  
  // End show reports secondary menu

  const menuLinks: any[] = [
    // {
    //   rightIcon: (
    //     <p></p>
    //   ),
    //   text: 'Inicio',
    //   to: '/',
    //   action: () => {},
    //   icon: (
    //     <Uikon>
    //       calendar
    //     </Uikon>
    //   ),
    //   exact: true
    // },
    {
      secondary: (
        <div id='reports-secondary-menu' className={(showReportsMenu) ? 'show' : ''}>
          <UikNavLinkSecondary Component={NavLink} to='/reports/general' activeClassName='active'>
            Visión general
          </UikNavLinkSecondary>
          <UikNavLinkSecondary Component={NavLink} to='/reports/traffic' activeClassName='active'>
            Tráfico
          </UikNavLinkSecondary>
          <UikNavLinkSecondary Component={NavLink} to='/reports/demographic' activeClassName='active'>
            Datos demográficos
          </UikNavLinkSecondary>
          <UikNavLinkSecondary Component={NavLink} to='/reports/interests' activeClassName='active'>
            Intereses
          </UikNavLinkSecondary>
          <UikNavLinkSecondary Component={NavLink} to='/reports/ranking' activeClassName='active'>
            Ranking
          </UikNavLinkSecondary>
          <UikNavLinkSecondary Component={NavLink} to='/reports/historial' activeClassName='active'>
            Historial
          </UikNavLinkSecondary>
        </div>
      ),
      rightIcon: (
        <img src={images.Arrow} alt="open-dropdown-menu" />
      ),
      text: 'Informes',
      action: () => {setShowReportsMenu(!showReportsMenu)},
      to: '/reports',
      icon: (
        <img src={images.IconNavigationReports} alt="navigation reports icon" />
      )
    },    
    {
      rightIcon: (
        <p></p>
      ),
      text: 'Facturación',
      to: '/billing',
      action: () => {},
      icon: (
        <img src={images.IconNavigationBilling} alt="navigation billing icon" />
      ),
    },
    {
      rightIcon: (
        <p></p>
      ),
      text: 'Tarifas',
      to: '/subscriptions',
      action: () => {},
      icon: (
        <img src={images.IconNavigationSubscription} alt="navigation subscription icon" />
      ),
    },

    // {
    //   rightIcon: (
    //     <p></p>
    //   ),
    //   text: 'Configuración',
    //   to: '/settings',
    //   action: () => {},
    //   icon: (
    //     <Uikon>
    //       settings
    //     </Uikon>
    //   ),
    // },
  ]

  const goToSettings = () => {
    history.push('/settings');
  }

  return (
    <NavigationContainer>
      <UikNavPanel className={classnames("navigation-wrapper", className)}>
        <UikContainerVertical>

          <UikScrollArea>

            <UikNavUser
              onClick={goToSettings}
              imgUrl={profileImg}
              // textTop="Art Director"
            >
              <div id='profile-gear-option'>
                <img src={images.IconProfileSettings} alt='Ir a opciones' />
              </div>
            </UikNavUser>
              <div id='profile-image-caption'>
                <h4>
                  {general.currentCustomer.town.name}
                </h4>
                <p>
                  {general.currentCustomer.town.province} 
                </p>
              </div>
            <UikDivider />

            <UikNavLinkTwoContainer >
              {
                menuLinks.map(({ text, icon, to, secondary,rightIcon, action, ...rest }, index: number) => (
                  <div key={index}>
                    {
                      (secondary) ? 
                        <>
                          <UikNavLinkTwo
                            icon={icon}
                            rightEl={rightIcon}
                            onClick={action}
                            {...rest}
                          >
                            {text}
                          </UikNavLinkTwo>

                          {secondary}

                        </>
                      :
                        (
                          <>
                            <UikNavLinkTwo
                              Component={NavLink}
                              icon={icon}
                              to={`${to}`}
                              rightEl={rightIcon}
                              onClick={action}
                              {...rest}
                            >
                              {text}
                            </UikNavLinkTwo>
                          </>
                        )
                    }
                  </div>
                ))
              }
            </UikNavLinkTwoContainer>

            <UikNavSection>
              <UikNavLink id='create-report' className='green' onClick={() => noop()}>
                {/* + <span className='underline'>Crear Informe</span> */}
              </UikNavLink>
              <UikNavLink onClick={() => handleHelp()}>
                <span className='underline'>Ayuda</span>
              </UikNavLink>
              <UikNavLink onClick={() => logout()}>
                <span className='underline'>Cerrar Sesión</span>
              </UikNavLink>
            </UikNavSection>

          </UikScrollArea>
        </UikContainerVertical>
      </UikNavPanel>
    </NavigationContainer>
  )
}

export default withRouter(Navigation);