import React from 'react';
import { NavLink } from 'react-router-dom';

import * as uik from '../../utils/third-party/@uik';
import { SelectContainer } from './select-style';


interface iLayoutProps {
  label: string;
  onChange: Function;
  errorMessage: string;
  value: any;
  options: any[]
  placeholder: string;
  position?: string;
  className?: string;
}

const Select: React.FC<iLayoutProps> = ({
  label, onChange, errorMessage, value,
  options, placeholder, position, className }) => {


  const { UikSelect } = uik;

  return (
    <SelectContainer className={className}>
      <UikSelect
        className={'full-width'}
        label={label}
        onChange={onChange}
        errorMessage={errorMessage}
        value={value}
        options={options}
        placeholder={placeholder}
        position={position}
      />
      <p className="errorMessage">{errorMessage}</p>
    </SelectContainer >
  );
}

export default Select;
