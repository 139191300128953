import styled from 'styled-components';

import * as uik from '../../utils/third-party/@uik';

const {UikContainerVertical} = uik;

const BillingContainer = styled(UikContainerVertical)`
  
  #delete-user-account {
    margin: 15px;
  }

  .billing-padding {
    & > img {
      padding-left: 20px;
    }

    & > .oi {
      padding-left: 50px;
    }
  }

  .small.secondary {
    float: right;
    margin-right: 10px;
  }

  .container-pagination {
    margin-top: 20px;
    align-self: flex-end;
  }

`;

export default BillingContainer;