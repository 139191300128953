import styled from 'styled-components';

const SimpleSideMenuContainer = styled.div`
  position: relative;
  padding: 25px 30px;
  background-color: white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.04);
  border: 1px solid #EAEDF3;
  border-radius: 4px;


  .simple-sidemenu-option {
    & > div {
      padding: 10px 20px;
      cursor:pointer;
      border-radius: 4px;
      font-weight: bold;
    }

    &.active > div  {
      background-color: #248489;
  
      p {
        color: white;
      }
    }
  }

  @media screen and (max-width: 1100px) {
    padding: 25px 15px;
  }


`

export default SimpleSideMenuContainer;