import styled from "styled-components";

export const TextareaContainer = styled.div`
  position: relative;


  .textarea-topbar {
    p{
      font-weight: 500;
      font-size: .75rem;
      color: #9EA0A5;
      line-height: 1.125rem;
      text-transform: uppercase;
    }
  }

  .textarea-topbar-icon {
    position: relative;
    z-index: 9;
    display: inherit;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .textarea-main-field {
    position: relative;
    margin-bottom: 4px;
    overflow: hidden;
  }

  textarea {
    background: transparent;
    resize: none;
    overflow-y: scroll;
    border: 0px;
    outline: 0px;
    min-height: 120px;
    line-height: 27px;
    font-size: 0.875rem;
    width: 100%;
    border: 1px solid #EAEDF3;
    border-radius: 5px;
    padding: 19px 16px 6px;
    transition: all 0.2s ease-in-out;
    
    &::placeholder  {
      color: #959595;
      line-height: 27px;
      font-size: 0.875rem;
    }

    &:focus {
      border: 1px solid #1665D8;
    }

    &:hover {
      &::placeholder  {
      }
    }

    &:disabled {
      background: white;

      &:focus {
      }
    }
  }

  .textarea-main {
    position: relative;
  }

  .textarea-icon {
    position: absolute;
    top: 13px;
    right: 6px;
    width: 20px;
    z-index: 10;
    display: none;
    img {
      max-width: 18px;
      cursor: pointer;
    }
  }

  .textarea-tooltip {
    position: absolute;
    top: calc(100% + 12px);
    z-index: 29;
    transform: translateX(-50%);
    width: 260px;
    right: 0;
  }

  .textarea-error {
  }

  &.error {
    .textarea-topbar {
      left: 16px;
      top: 5px;
      color: #E6492D;
    }

    textarea {
      border: 1px solid #E6492D;
      &::placeholder {
      }
    }

    .textarea-error {
      p{
        color: #E6492D;
      }
    }
  }
  &.complete {
    .textarea-topbar {
      left: 16px;
      top: 5px;
      color: #2cdbba;
      font-size: 12px;
      line-height: 168%;
    }
    textarea  {
      color: #575757;
    }
  }
  &.focus {
    .textarea-topbar {
      left: 16px;
      top: 5px;
      color: #2cdbba;
      font-size: 12px;
      line-height: 168%;
    }
    textarea  {
      color: #575757;
    }
  }
`;

export default TextareaContainer;
