import React from 'react';

// Type
import { ICardInfoRanking } from './CardInfoRanking-type';

// Style
import CardInfoRankingContainer from './CardInfoRanking-style';



const CardInfoRanking: React.FC<ICardInfoRanking> = (props: ICardInfoRanking) => {
  const { dateText, value, footText, smallValue, image, height } = props;

  return (
    <CardInfoRankingContainer height={height}>
      <div className='card-ranking-header'>
        <h5>{footText}</h5>
      </div>

      <div className='card-ranking-value'>
        <h1>{value}</h1>
        <p>{smallValue}</p>
      </div>
      
      <div className='card-ranking-footer'>
        <p>{dateText}</p>
      </div>

      {
        (image) ?
          (
            <div className='card-ranking-backImage'>
              <img src={image} alt='Trending image'/>
            </div>
          )
        :
          (
            <></>
          )
      }
            
    </CardInfoRankingContainer>
  )
  
}

export default CardInfoRanking