export const MONTHS: any[] = [
  {label: 'Enero', value: '01'},
  {label: 'Febrero', value: '02'},
  {label: 'Marzo', value: '03'},
  {label: 'Abril', value: '04'},
  {label: 'Mayo', value: '05'},
  {label: 'Junio', value: '06'},
  {label: 'Julio', value: '07'},
  {label: 'Agosto', value: '08'},
  {label: 'Septiembre', value: '09'},
  {label: 'Octubre', value: '10'},
  {label: 'Noviembre', value: '11'},
  {label: 'Diciembre', value: '12'}
];

export const ANALYTICS_TOKEN = 8;
export const DEFAULT_DATA_LIMIT = 10;




interface ITERABLE_OBJECT {
  [key:string]: string
}

export const CHART_COLORS: ITERABLE_OBJECT = {
  'total-users': '#2CDBBA',
  'new-users': '#EEB222',
  'returned-users': '#B79713'
}

export const CHANNELS_TYPES_CHART: ITERABLE_OBJECT = {
  'Direct': 'Directo',
  'Organic Search': 'Búsqueda Orgánica',
  'Social': 'Social',
  'Email': 'Email',
  'Affiliates': 'Afiliados',
  'Referral': 'Remisión',
  'Paid Search': 'Busqueda paga',
  'Other Advertising': 'Otra públicidad',
  'Display': 'Monitor',
  'other': 'Otros',
  'unavailable': 'Otros' 
}

export const SELECT_PERIODICITY: ITERABLE_OBJECT = {
  '':'Datos diarios *',
  'week': 'Datos semanales *',
  'month': 'Datos mensuales *'
}

export const SELECT_OPT_PERIODICITY: ITERABLE_OBJECT = {
  'daily':' Diario',
  'weekly': 'Semanal',
  'monthly': 'Mensual'
}

export const TOOLTIPS_TEXT: any = {
  'trafico-de-usuarios': 'Información sobre el número total de usuarios, nuevos usuarios y usuarios recurrentes que acceden a la web de su municipio.',
  'desde-donde-te-visitan': 'Representación gráfica de los países y cantidad de usuarios que visitan tu municipio, mostrados en diferentes tonalidades de color según su afluencia.',
  'tipo-de-usuario': 'Porcentaje de usuarios nuevos y recurrentes en la web de su municipio.',
  'grafico-idioma': 'Número de visitas en la web de su municipio segmentada por el idioma de preferencia del navegador.',
  'analisis-de-cohorte': 'Es un conjunto de usuarios que comparten una característica común. Esta tabla, permite aislar y analizar el comportamiento de los grupos especificados.',
  'que-paginas-visitan': 'Información sobre las páginas visitadas de su municipio y el número de visitas totales y en porcentaje a dichas páginas.',
  'grafico-edad': 'Comparativa del número de usuarios totales segmentada por los diferentes rangos de edad.',
  'grafico-sexo': 'Proporción de usuarios de sexo femenino y masculino que acceden a la web.',
  'categorias-principales': 'Segmentos cuyos usuarios suelen tener interés en obtener información sobre la categoría especificada. Además se muestran los usuarios que han iniciado al menos una sesión durante el periodo especificado y el número de sesiones.',
  'canales-principales': 'Ordenación de las fuentes de tráfico, medios, campañas, etc. según nos interese agrupar el tráfico o fuentes de adquisición.',
  'paginas-de-busqueda': 'Listado de las páginas más visitadas/buscadas de su web en orden de popularidad y con porcentaje de búsqueda.',
  'que-intereses-tienen': 'Intereses de los usuarios que visitan la web de su municipio mostrados por números de usuario, números de sesiones y duración media de cada sesión.',
  'segmentos-de-compra': 'Usuarios con mayor predisposición a comprar productos o servicios en la categoría especificada, mostrados por  números de usuario, números de sesiones y duración media de cada sesión.',
  'comparador-ranking': 'Consulte y compare los datos más relevantes de otros municipios respecto al suyo.',
  'trafico-de-usaurios-2': 'Comparativa del número total de usuarios que acceden a las distintas páginas webs.',
  'tiempo-en-pagina': 'Comparativa del tiempo total que pasan los usuarios en las distintas página web.',
  'links-visitados': 'Comparativa del número de visitas en sus páginas.',
  'historial-informes': 'Listado de todos los informes generados hasta la fecha.',  
  'rango-sesiones-data': 'Cuántos usuarios han venido a tu página (Rango de sesiones), cuántas veces ha venido cada uno (sesiones) y cuántas páginas ha visto cada uno de ellos (páginas vistas)',
  'url-been-clicked': 'Falta texto',

  'informacion-basica': 'Modifica el correo con el que se accede a tu cuenta',
  'informacion-minicipio': 'Datos del municipio sobre el que trata la página',
  'informacion-contacto': 'Datos de la persona encargada de administrar Linx por la corporación municpal',
  'envio-informes': 'Cuentas de correo electrónico a las que se enviarán los informes mensuales',
  'descripcion-municipio': 'Breve descripción de los hitos más importantes del municipio, con foco en lo que más interesa que sea conocido',
  'lugares-de-interes': 'Pon en cada casilla monumentos y espacios de interés de tu municipio',
  'links-interes': ' Introducir links para insertar en el texto',
  'informacion-practica': 'Pon datos de interés para quienes visiten tu municipio',
  'subida-multiimagen': 'Fotografías sobre monumentos y espacios más representativos de tu municipio'

}

export const TOTAL_ITEMS: number = 10;