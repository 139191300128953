import styled from 'styled-components/macro';

const MultifileUploadContainer = styled.div`
  position: relative;


  .filepond--list {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .filepond--item {
    width: calc(25% - .5em);
  }

  .filepond--root .filepond--drop-label {
    display: flex;
    position: absolute;
    /* min-height: 250px; */
  }

  .multifile-label-text {
    font-size: 14px;
    cursor: pointer;
    
    .black-text {
      color: black;
      font-weight: bold;
    }

    .red-text {
      color: red;
    }


  }

  .grid {
    gap: 30px;
    grid-template-columns: repeat(auto-fill, 100px);
    justify-content: center;
  }

  .thumb-image-item {
    width: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #EAEDF3;
    background-color: #EAEDF3;
    border-radius: 5px;

    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1450px) {
    .filepond--item {
      width: calc(50% - .5em);
    }
  }

  @media screen and (max-width: 1250px) {
    .filepond--item {
      width: calc(100% - .5em);
    }    
  }

`

export default MultifileUploadContainer;