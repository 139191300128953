import React, { useState, useEffect, useContext } from 'react';

// Type
import { IMultifileUpload } from './MultifileUpload-type';

// Style
import MultifileUploadContainer from './MultifileUpload-style';


// Import React FilePond
import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import GeneralContext from '../../context/general.context';
import { getCurrentCustomer, deleteMultimediaCustomer } from '../../services/customer.services';

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
const pluginImagePreview = require("filepond-plugin-image-preview");


// Register the plugins
registerPlugin(pluginImagePreview);


const MultifileUpload: React.FC<IMultifileUpload> = (props: IMultifileUpload) => {
  const general = useContext(GeneralContext);
  const { backendUrl, endpointUrl, className, onWarning } = props;
  
  const [pondFile, setPondFile] = useState<any>();
  const [files, setFiles] = useState<any[]>(
    () => {
      return general.currentCustomer.multimedia.map( (image: string) => ({
        source: image,
        options: {
          type: 'local'
        }
      }))
    }
  );

  const [reloadFiles, setReloadFiles] = useState(true);
  const [showText, setShowText] = useState(true);

  const serverObject: any = {
    url: endpointUrl,
    load: (uniqueFileId: any, load: any, error: any) => {
      fetch(uniqueFileId)
          .then(res => res.blob())
          .then(load)
          .catch(error)
    },
    process: {
      url: backendUrl,
      method: 'PUT' as 'PUT',
      headers: {
        Authorization: localStorage.accessToken ? `Bearer ${localStorage.accessToken}` : ''
      },
      ondata: (form: any) => {
        // Here we extract the real file and send only it to Diego Webservice
        const realFile = form.getAll('file')[1];
        const formatedForm =  new FormData();
        formatedForm.set('file', realFile);

        return formatedForm;
      },
      onload: () => {
        getCurrentCustomer(true);
      }
    }
  }  

  const handleImageRemove = (filename: string) => {
    const splitedName = filename.split('/');
    const key = splitedName[splitedName.length - 1];
    
    let index = files.map(function(item) { return item.source; }).indexOf(filename);

    if (index > -1) {
      files.splice(index, 1);
      setFiles([...files]); 
    }


    const args = {
      key: key,
      setLoading: general.setLoading
    }

    deleteMultimediaCustomer(args)
      .then((res) => {
        getCurrentCustomer(true);
        return true;
      })
      .catch( (e) => {
        console.log("ERROR",e);
        return false;
      });
    return true;
  }
  

  useEffect( () => {
    const labelContainer = document.querySelector('.filepond--root .filepond--drop-label') as HTMLElement;

    if (files.length === 0) {
      setShowText(true);

      if (labelContainer)
        labelContainer.style.minHeight = '250px';

    }
    else {
      setShowText(false);

      if (labelContainer)
        labelContainer.style.minHeight = '100px';

    }


  },[files])

  useEffect( () => {
    setFiles(  
      general.currentCustomer.multimedia.map( (image: string) => ({
        source: image,
        options: {
            type: 'local'
        }
      }))
    )
  }, [general.currentCustomer])


  return (
    <MultifileUploadContainer className={className}>
        <FilePond
          ref={ref => (setPondFile(ref))}
          // files={files}
          name='file'
          allowMultiple={true}
          maxFiles={10 - files.length}
          labelIdle={files.length != 10 ? `<div class='multifile-label-text ${!showText ? 'hide': ''}'>
                        Arrastra o selecciona un máximo de ${10 - files.length} imágenes.<br>
                        Para añadir más imágenes o videos, enviarlos a <span class='black-text'>linx@espanafascinante.com</span><br>
                        <span class='red-text'>Máx. 5MP</span>
                      </div>` : `<div class='multifile-label-text ${!showText ? 'hide': ''}'>
                      Para añadir más imágenes o videos, enviarlos a <span class='black-text'>linx@espanafascinante.com</span><br>
                      <span class='red-text'>Máx. 5MP</span>
                    </div>`}
          server={serverObject}
          // beforeRemoveFile={handleImageRemove}
          oninit={() => {}}
          maxParallelUploads={1}
          acceptedFileTypes={['png', 'jpg', 'jpeg']}
          onupdatefiles={(function (this: any, fileItems: any) {
            
            // setFiles(fileItems);
          }).bind(files)}
          onwarning={(error, file, status) => {onWarning()}}
        />
        <div className='grid'>
          {files.map( (img: any, index:number) => (
            <div key={`multimedia-${index}`} className="thumb-image-item">
              <div className="image-close" onClick={() => handleImageRemove(img.source)}>
                <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.586 13l-2.293 2.293a1 1 0 0 0 1.414 1.414L13 14.414l2.293 2.293a1 1 0 0 0 1.414-1.414L14.414 13l2.293-2.293a1 1 0 0 0-1.414-1.414L13 11.586l-2.293-2.293a1 1 0 0 0-1.414 1.414L11.586 13z" fill="currentColor" fillRule="nonzero"></path>
                </svg>
              </div>
              <a target="_blank" rel="noopener noreferrer" href={img.source}>
                <img src={img.source} alt="multimedia" />
              </a>
            </div>
          ))}
        </div>
    </MultifileUploadContainer>
  )
  
}

export default MultifileUpload