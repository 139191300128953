export function currencyFormat(num: number): string {
  return num
    .toFixed(2) // always two decimal digits
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

export function numberFormat(num: number): string {
  if (!num)
    return '0';
  return num
    .toString()
    .replace('.', ',') // replace decimal point character with ,
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.'); // use . as a separator
}

export const generateRante = (start: number, end: number, length = end - start) =>
  Array.from({ length }, (_, i) => start + i);