import { ValueForm } from "./general.model";

export interface iCustomers {
  username: string;
  town: any;
  profile: any;
  contact: any;
  id: number;
  status: string;
  visits: string[];
  places: string[];
  reportMails: string[];
  nif: string;
  subscriptionFrequency: string;
  subscription: any;
  multimedia: [];
  updatedAt: string; 
  [key: string]: any;
}

export interface iCustomerForm {
  username: ValueForm;
  townName: ValueForm;
  townCode: ValueForm;
  townProvince: ValueForm;
  contactName: ValueForm;
  contactPostalCode: ValueForm;
  street: ValueForm;
  contactPhoneNumber: ValueForm;
  file: ValueForm;
  [key: string]: any;
}

interface ICustomerInitValue {
  (): iCustomers;
}

export const customerInitValue:ICustomerInitValue = () => ({
  username: '',
  town: {},
  profile: {},
  contact: {},
  nif: '',
  status: '',
  visits: [],
  places: [],
  multimedia: [],
  reportMails: [],
  subscriptionFrequency: '',
  subscription: {},
  updatedAt: '',
  id: 0
})