import React, { useState } from 'react';

// Styles
import AttachInputContainer from './AttachFile-style'

// Types
import {
  AttachInputFileProps,
  UseAttachInputFileType
} from './AttachFile-types'

const useFileInput: UseAttachInputFileType = (props: AttachInputFileProps) => {
  const [isFocus, setIsFocus] = useState(false) // Maybe pass a initState ?
  const fileInputRef: React.RefObject<HTMLInputElement> = React.createRef<
    HTMLInputElement
  >()

  /* Open File dialog
   *  Simulate click in hidden file input to open filesystem dialog
   */
  const handleOpenFileDialog = (): void => {
    if (props.disabled) return

    fileInputRef.current && fileInputRef.current.click()
  }

  /* On filed Added hanlder
   * Get files added, transform the in an array a then trigger onFilesAdded fn
   */
  const handleOnFilesAdded = (
    evt: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (props.disabled) return

    if (evt.currentTarget.files) {
      const files = filesToArray(evt.currentTarget.files, props.multi)

      // Workaround for typescript error (https://bit.ly/2lHr9fI)
      // Move to separate fn
      let notNullFiles: Array<File> = []
      files.forEach(elem => {
        // We remove the posible null files in files array
        if (elem) notNullFiles.push(elem)
      })

      props.onFilesAdded && props.onFilesAdded(notNullFiles)
    }
  }

  /*  On drag over canvas hanlder
   *
   */
  const handleOnDragOver = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault()
    if (props.disabled) return

    setIsFocus(true)
  }

  /* On drag leave canvas hanlder
   *
   */
  const handleOnDragLeave = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault()
    if (props.disabled) return

    setIsFocus(false)
  }

  /* On drag leave canvas hanlder
   *  Detect and add files to array
   */

  const handleOnDrop = (evt: React.DragEvent<HTMLDivElement>): void => {
    evt.preventDefault()
    if (props.disabled) return

    if (props.onFilesAdded) {
      const files = filesToArray(evt.dataTransfer.files, props.multi)

      // Workaround for typescript error (https://bit.ly/2lHr9fI)
      // Move to separate fn
      let notNullFiles: Array<File> = []
      files.forEach(elem => {
        // We remove the posible null files in files array
        if (elem) notNullFiles.push(elem)
      })

      props.onFilesAdded(notNullFiles)
    }

    setIsFocus(false)
  }

  // this should be move out or this,
  // Mery i do this later i promise, now we are out of time
  const filesToArray = (
    list: FileList,
    multi: boolean | undefined
  ): Array<File | null> => {
    const array = []

    let limit = multi ? list.length : 1 // return all files, o just the first added

    for (var i = 0; i < limit; i++) {
      array.push(list.item(i))
    }

    return array
  }

  return {
    isFocus,
    fileInputRef,
    handleOpenFileDialog,
    handleOnFilesAdded,
    handleOnDragOver,
    handleOnDragLeave,
    handleOnDrop
  }
}

const AttachFileInput: React.FC<AttachInputFileProps> = (
  props: AttachInputFileProps
) => {
  const action = useFileInput(props)
  const attachInputClasses =
    'attach-input-file ' +
    (props.type + ' ') +
    (props.disabled ? 'disabled ' : '')

  return (
    <AttachInputContainer
      className={` ${props.className} ${attachInputClasses} `}
    >
      <label htmlFor={props.name}>{props.textTitle}</label>
      <div
        id="canvas"
        onDragOver={action.handleOnDragOver}
        onDragLeave={action.handleOnDragLeave}
        onDrop={action.handleOnDrop}
        onClick={action.handleOpenFileDialog}
        tabIndex={0}
        style={{
          cursor: props.disabled ? 'default' : 'pointer'
        }}
      >
        <input
          name={props.name}
          ref={action.fileInputRef}
          className="hidden-file-ipnut"
          type="file"
          multiple={props.multi ? props.multi : false}
          onChange={action.handleOnFilesAdded}
        />

        <div className="icon-img-container">
          {/*TODO: Look a better way to do this. now there's no time **/}
          {/* <img
            src={
              props.type === 'normal'
                ? icons.cameraGreyHover
                : icons.cameraGreyError
            }
            alt="camera"
          /> */}
          <span className="icon-text">{props.textIcon}</span>
        </div>
      </div>
      <small>{props.textHelp}</small>
    </AttachInputContainer>
  )
}

export default AttachFileInput
